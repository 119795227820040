import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
import { Observable } from 'rxjs';
@Injectable()
export class RdvService {
  constructor(private http: HttpClient) {

  }
  //get all rdvs by medecin
  getRdvsByMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/medecin/' + medecinId, { headers: headers })
  }
  // get all rendez vous d'un medecin count 
  getAllRdvsCountMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/medecin/' + medecinId + '/count', { headers: headers })
  }

  // get all rendez vous passes d'un medecin count 
  getAllRdvsPassesCountMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/medecin/' + medecinId + '/count/passes', { headers: headers })
  }

  // get all rendez vous poragmmmes d'un medecin count 
  getAllRdvsProgrammesCountMedecin(medecinId) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/medecin/' + medecinId + '/count/deja/programme', { headers: headers })
  }

  //get all rdvs by medecin
  getRdvsById(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/' + id, { headers: headers })

  }
  getRdv() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/:id', { headers: headers })

  }

  addRdv(newRdv) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.rdvs), (newRdv), { headers: headers })

  }

  deleteRdv(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete(_getURL(API_CONFIG.rdvs) + '/:id', { headers: headers })

  }

  editRdv(rdv) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.rdvs) + '/' + rdv._id, (rdv), { headers: headers })

  }
  accepteRDV(rdv) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(_getURL(API_CONFIG.rdvs) + '/' + rdv._id, (rdv), { headers: headers })

  }

  getAllRdvsByDate(dt, id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(_getURL(API_CONFIG.rdvs) + '/today/' + dt + '/' + id, { headers: headers })

  }
  addRdvByDoctor(newRdv) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.rdvs) + '/addRdv/medecin', (newRdv), { headers: headers })

  }
  saveRDVeVENT(newRdv) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(_getURL(API_CONFIG.rdvs) + '/addRdv/notify/patient', (newRdv), { headers: headers })

  }
  // ----added new
  getRdvsToday(date: string, medecinId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(`${_getURL(API_CONFIG.rdvs)}/today/${date}/${medecinId}`, { headers: headers })
  }
}
