import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { AuthService } from '../../services/auth/auth.service';
import { ImageService } from '../../services/image/image.service';
import { BackofficeComponent } from './backoffice.component';
import { AccueilComponent } from './accueil/accueil.component';
import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { RdvService } from 'src/app/services/rdv/rdv.service';
import { CertificatService } from 'src/app/services/certificat/certificat.service';
import { OrdonnanceService } from 'src/app/services/ordonnance/ordonnance.service';
import { MedicamentsService } from 'src/app/services/medicaments/medicaments.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { CalendarComponentt } from 'src/app/dashboard/backoffice/calendar/calendar/calendar.component';
import { TrouverMedecinsComponent } from './trouverMedecins/trouver-medecins/trouver-medecins.component';
import { PatientComponent } from './patient/patient/patient.component';
import { ListPatientsComponent } from './patient/list-patients/list-patients.component';
import { UpdatePatientComponent } from './patient/update-patient/update-patient.component';
import { ListconsultationComponent } from './consultation/listconsultation/listconsultation.component';
import { AjoutDocumentPatientComponent } from './consultation/document/ajout-document-patient/ajout-document-patient.component';
import { UpdateDocumentPatientComponent } from './consultation/document/update-document-patient/update-document-patient.component';
import { OphtalmoAuthGuard } from 'src/app/guards/auth-ophtalmo.guard';
import { InterrogatoireComponent } from './examen/interrogatoire/interrogatoire.component';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { EventService } from 'src/app/services/event/event.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { SecretaireService } from 'src/app/services/profils/secretaire/secretaire.service';
import { AnomalieService } from 'src/app/services/anomalie/anomalie.service';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuardOnlyMedecin } from 'src/app/guards/auth-only-medecin.guard';
import { SecretaireComponent } from './secretaire/secretaire.component';
import { SalleAttComponent } from './salle-att/salle-att.component';
import { MotifService } from 'src/app/services/motif/motif.service';

const routes: Routes = [
  {
    path: 'medecin', component: BackofficeComponent,
    children: [
      { path: 'recherche', component: TrouverMedecinsComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'patients-liste', component: ListPatientsComponent, canActivate: [AuthGuard] },
      { path: 'nouveauPatient', component: PatientComponent, canActivate: [AuthGuard] },
      { path: 'patient/:id', component: UpdatePatientComponent, canActivate: [AuthGuard] },
      { path: 'consultation-liste/:id', component: ListconsultationComponent, canActivate: [AuthGuard] },
      { path: 'ajout/nouveaux/documents/:id', component: AjoutDocumentPatientComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'document/patient/modifier/:id/:idDocument', component: UpdateDocumentPatientComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'accueil', component: AccueilComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'calendar', component: CalendarComponentt, canActivate: [AuthGuard] },
      { path: 'ajout/interrogatoire/:id/:idExamen', component: InterrogatoireComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'profil', component: ProfileComponent, canActivate: [AuthGuardOnlyMedecin] },
      { path: 'salle-attente', component: SalleAttComponent, canActivate: [AuthGuard] },
      { path: 'secretaires', component: SecretaireComponent, canActivate: [AuthGuardOnlyMedecin] },
      //{path:'messagerie',component:MessagerieComponent,canActivate:[AuthGuardOnlyMedecin]},

    ]

  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [ExamenService, MotifService,AnomalieService, ImageService, AuthService, RdvService, ConsultationService, SharedDataService, AuthGuard, AuthGuardOnlyMedecin,
    CertificatService, OrdonnanceService, MedicamentsService, DocumentService, OphtalmoAuthGuard, EventService, NotificationService, PatientService, SecretaireService, MedecinService],
  exports: [RouterModule]
})
export class BackofficeRoutingModule { }
