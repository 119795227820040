import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MedecinService } from '../../../services/profils/medecin/medecin.service';
import { tri } from '../../pays/pays';
import { villes } from 'src/app/dashboard/pays/villes';
import { codepostale } from 'src/app/dashboard/pays/villes';
import { _getURL } from 'config/API_CONFIG';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  medecin: any;
  listePostal = [];
  listefiltrecode = ['2001', '2002', '2020', '2022', '2027', '2032', '2035', '2036', '2037', '2041', '2056', '2057', '2058', '2061', '2073', '2080', '2081', '2083', '2088', '2091', '2094', '2095']
  listefiltreville = [];
  listeVilles: any[] = [];
  // prise de photo
  isActiveTakePhoto = false;
  isActiveDownloadPhoto = true
  filesToUploaded: any[] = [];
  filesToUploadedLogo: any[] = [];
  @ViewChild("video")
  public video: ElementRef;
  @ViewChild("canvas")
  public canvas: ElementRef;
  public captures: any[] = [];
  isCameraAccessGranted: any;
  isMicrophoneAccessGranted: any;
  authorized = true;
  pathImage: any;
  pathImageLogo: any;
  oldPassword: any;
  confirmationPassword: any;
  newPassword: any;
  // prise de photo
  versionFr = true;
  versionAr = false;
  constructor(private medecinService: MedecinService, public iziToast: Ng2IzitoastService, private authService: AuthService, private router: Router,) { }

  ngOnInit(): void {
    this.listeVilles = villes.filter(x => x.pays == "Tunisie");
    this.listeVilles.forEach(index =>
      this.listefiltreville = index.ville);
    this.authService.getProfileMedecin().subscribe((profile: any) => {
      this.medecin = profile.medecin;
      if (!this.medecin) {
        this.router.navigate(['/medecin/accueil']);
        return false;
      }
      if (this.medecin.image) {
        this.pathImage = _getURL("") + "data/files/medecin/" + this.medecin.image
      }
      if (this.medecin.logo) {
        this.pathImageLogo = _getURL("") + "data/files/cabinet/" + this.medecin.logo
      }
      if (this.medecin && this.medecin.adresseCabinet && this.medecin.adresseCabinet.ville) {
        this.listePostal = codepostale.filter(x => x.ville == this.medecin.adresseCabinet.ville);
        this.listePostal.forEach(index => this.listefiltrecode = index.code);
      }
    },
      err => {
        this.router.navigate(['/medecin/accueil']);
        return false;
      });

  }

  updateInfoMedecin() {
    if (!this.medecin) {
      this.iziToast.show({
        message: "Aucun médecin trouvé",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    if (!this.medecin.email || !this.validateEmail(this.medecin.email)) {
      this.iziToast.show({
        message: "Merci de vérifier votre email",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    if (this.medecin.tel && this.medecin.tel != "+216" && !this.validatePhoneNumber(this.medecin.tel)) {
      this.iziToast.show({
        message: "Merci de vérifier votre N° mobile",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    if (this.medecin.telFixe && this.medecin.telFixe != "+216" && !this.validatePhoneNumber(this.medecin.telFixe)) {
      this.iziToast.show({
        message: "Merci de vérifier votre N° fixe",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    if (!this.medecin.adresseCabinet || (this.medecin.adresseCabinet && (!this.medecin.adresseCabinet.ville || this.medecin.adresseCabinet.ville == null || this.medecin.adresseCabinet.ville == undefined || !this.medecin.adresseCabinet.codePostal || this.medecin.adresseCabinet.codePostal == null || this.medecin.adresseCabinet.codePostal == undefined || this.medecin.adresseCabinet.codePostal.length > 7 || this.medecin.adresseCabinet.ville.length > 15 || !this.medecin.adresseCabinet.adresse || this.medecin.adresseCabinet.adresse.length > 100))) {
      this.iziToast.show({
        message: "Merci de vérifier les informations de l'adresse de cabinet",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }

    if ((this.medecin.facebook && this.validateSocialMediaLink(this.medecin.facebook) === "Invalid" && this.medecin.facebook.length > 100) || (this.medecin.twitter && this.validateSocialMediaLink(this.medecin.twitter) === "Invalid" && this.medecin.twitter.length > 100) || (this.medecin.instgram && this.validateSocialMediaLink(this.medecin.instagram) === "Invalid" && this.medecin.instagram.length > 100) || (this.medecin.linkedin && this.validateSocialMediaLink(this.medecin.linkedin) === "Invalid" && this.medecin.linkedin.length > 100)) {
      this.iziToast.show({
        message: "Merci de vérifier les liens des réseaux sociaux",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    if ((this.medecin.nomAr && this.medecin.nomAr.length > 25) || (this.medecin.prenomAr && this.medecin.prenomAr.length > 25) || (this.medecin.specialiteAr && this.medecin.specialiteAr.length > 100) || (this.medecin.adresseAr && this.medecin.adresseAr.length > 100)) {
      this.iziToast.show({
        message: "Merci de vérifier les informations en arabe",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false
    }
    var medtoupdate = {
      "_id": this.medecin._id,
      "email": this.medecin.email,
      "tel": this.medecin.tel,
      "telFixe": this.medecin.telFixe,
      "facebook": this.medecin.facebook,
      "twitter": this.medecin.twitter,
      "instagram": this.medecin.instagram,
      "linkedin": this.medecin.linkedin,
      "colorTemplates": this.medecin.colorTemplates,
      "adresseCabinet": this.medecin.adresseCabinet,
      "nomAr": this.medecin.nomAr,
      "prenomAr": this.medecin.prenomAr,
      "specialiteAr": this.medecin.specialiteAr,
      "adresseAr": this.medecin.adresseAr,
      "doubleFactorsAuthenticate": this.medecin.doubleFactorsAuthenticate,
      "codeCNAM":this.medecin.codeCNAM
    }

    this.medecinService.editProfil(medtoupdate).subscribe((data: any) => {
      if (this.filesToUploadedLogo && this.filesToUploadedLogo.length > 0) {
        let formData = new FormData();
        for (var i = 0; i < this.filesToUploadedLogo.length; i++) {
          formData.append("file", this.filesToUploadedLogo[i], this.filesToUploadedLogo[i].name);

        }
        this.medecinService.updateLogo(formData).subscribe((_patient: any) => {
          this.filesToUploadedLogo = []
          while (this.filesToUploadedLogo.length > 0) {
            this.filesToUploadedLogo.pop()
          }

        });
      }
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
    });
  }

  validateEmail(email) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  validatePhoneNumber(phoneNumber) {
    // Regular expression to match the format +216XXXXXXXX or +216 XXXXXXXX
    var regex = /^\+216(\s*)\d{8}$/;
    return regex.test(phoneNumber.toString().replace(/\s/g, ''));
  }
  validateSocialMediaLink(url) {
    // Regular expressions for Facebook, Instagram, LinkedIn, and Twitter profiles
    var facebookRegex = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9.]{1,}$/;
    var instagramRegex = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9_]{1,}$/;
    var linkedinRegex = /^(https?:\/\/)?(www\.)?linkedin.com\/(in|company)\/[a-zA-Z0-9-_.]{1,}$/;
    var twitterRegex = /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9_]{1,}$/;

    // Check if the URL matches any of the regular expressions
    if (facebookRegex.test(url)) {
      return "Facebook";
    } else if (instagramRegex.test(url)) {
      return "Instagram";
    } else if (linkedinRegex.test(url)) {
      return "LinkedIn";
    } else if (twitterRegex.test(url)) {
      return "Twitter";
    } else {
      return "Invalid";
    }
  }
  onSelectville(selectedville) {
    this.listePostal = codepostale.filter(x => x.ville == selectedville);
    this.listePostal.forEach(index => this.listefiltrecode = index.code);
    this.medecin.adresseCabinet.codePostal = undefined
  }
  isActiveDownloadPhotoFunction() {
    this.filesToUploaded = []
    while (this.filesToUploaded.length > 0) {
      this.filesToUploaded.pop()
    }
    this.isActiveDownloadPhoto = true;
    this.isActiveTakePhoto = false;
  }
  async isActiveTakePhotoFunction() {
    this.filesToUploaded = []
    while (this.filesToUploaded.length > 0) {
      this.filesToUploaded.pop()
    }
    this.isActiveDownloadPhoto = false;
    this.isActiveTakePhoto = true;
    this.authorized = true;
    try {
      const cameraResult = await navigator.permissions.query({ name: 'camera' });
      // The state property may be 'denied', 'prompt' and 'granted'
      this.isCameraAccessGranted = cameraResult.state !== 'denied';
      // const microphoneResult = await navigator.permissions.query({ name: 'microphone' });
      // this.isMicrophoneAccessGranted = false;
    } catch (e) {
      console.error('An error occurred while checking the site permissions', e);
    }
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 280,
        height: 280,
        facingMode: "user"
      },
      audio: false
    }).then(stream => {
      this.video.nativeElement.srcObject = stream;
      this.video.nativeElement.play();
    }, error => {
      this.authorized = false;
      return false;
    });
  }

  public capture() {
    if (this.filesToUploaded && this.filesToUploaded.length > 1) {
      this.iziToast.show({
        message: 'Vous pouvez ajouter une seule photo.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    var context = this.canvas.nativeElement
      .getContext("2d")
      .drawImage(this.video.nativeElement, 0, 0, 280, 280);

    this.captures[0] = this.canvas.nativeElement.toDataURL("image/png");
    let identifiant = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let file = this.dataURLtoFile(this.canvas.nativeElement.toDataURL("image/png"), identifiant + '.png');
    this.filesToUploaded[0] = file;

  }

  public dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  saveImages() {
    if ((this.filesToUploaded && this.filesToUploaded.length != 1) || (!this.filesToUploaded)) {
      this.iziToast.show({
        message: 'Il faut prendre au moins une photo.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',

        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    let formData = new FormData();
    for (var i = 0; i < this.filesToUploaded.length; i++) {
      formData.append("file", this.filesToUploaded[i], this.filesToUploaded[i].name);

    }

    this.medecinService.updatePhoto(this.medecin._id, formData).subscribe((_patient: any) => {
      this.canvas = undefined
      this.filesToUploaded = []
      while (this.filesToUploaded.length > 0) {
        this.filesToUploaded.pop()
      }
      this.iziToast.show({
        message: 'Bien enregistré',
        messageColor: '#386641',
        progressBarColor: '#6a994e',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#dde5b6',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
      });
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin) {
          this.router.navigate(['/medecin/accueil']);
          return false;
        }
        if (this.medecin.image) {
          this.pathImage = _getURL("") + "data/files/medecin/" + this.medecin.image
        }
      },
        err => {
          this.router.navigate(['/medecin/accueil']);
          return false;
        });
    });
  }

  // controles added files
  droppedFile(files) {
    var allDataFiles = files.target.files;
    if (!allDataFiles || (allDataFiles && allDataFiles.length == 0)) {
      return false;
    }
    const validExtensions = ['png', 'jpg', 'jpeg'];
    this.filesToUploaded = []
    while (this.filesToUploaded.length > 0) {
      this.filesToUploaded.pop()
    }
    this.filesToUploaded[0] = allDataFiles[0]
    for (var j = 0; allDataFiles.length; j++) {
      if (allDataFiles[j] && allDataFiles[j] != undefined) {
        const fileExtension = allDataFiles[j].name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          this.iziToast.show({
            message: 'Le fichier doit être de type png, jpg ou jpeg .',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          allDataFiles = []
          while (allDataFiles.length > 0) {
            allDataFiles.pop()
          }
          return false;
        }
        if (this.filesToUploaded[j].size / (1024 * 1024) > 5) {
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          while (allDataFiles.length > 0) {
            allDataFiles.pop()
          }
          return false;
        }
      } else {
        return false;
      }


    }
  }
  droppedFileLogo(files) {
    var allDataFiles = files.target.files;
    if (!allDataFiles || (allDataFiles && allDataFiles.length == 0)) {
      return false;
    }
    const validExtensions = ['png', 'jpg', 'jpeg'];
    this.filesToUploadedLogo = []
    while (this.filesToUploadedLogo.length > 0) {
      this.filesToUploadedLogo.pop()
    }
    this.filesToUploadedLogo[0] = allDataFiles[0]
    for (var j = 0; allDataFiles.length; j++) {
      if (allDataFiles[j] && allDataFiles[j] != undefined) {
        const fileExtension = allDataFiles[j].name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          this.iziToast.show({
            message: 'Le fichier doit être de type png, jpg ou jpeg .',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          allDataFiles = []
          while (allDataFiles.length > 0) {
            allDataFiles.pop()
          }
          return false;
        }
        if (this.filesToUploadedLogo[j].size / (1024 * 1024) > 5) {
          this.iziToast.show({
            message: 'Fichier très large! merci de ne pas dépasser 5 Mo.',
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
          });
          while (allDataFiles.length > 0) {
            allDataFiles.pop()
          }
          return false;
        }
      } else {
        return false;
      }


    }
  }
  updatePassword() {
    if (!this.oldPassword || this.oldPassword.length < 6 || this.oldPassword.length > 50 || !this.newPassword || this.newPassword.length < 6 || this.newPassword.length > 50) {
      this.iziToast.show({
        message: 'Les mots de passe doivent comporter au moins 6 caractères et au maximum 50 caractères.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    // Vérifier la présence de caractères spéciaux
    var specialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    if (!specialChars.test(this.newPassword)) {
      this.iziToast.show({
        message: 'Le nouveau mot de passe doit contenir au moins un caractère spécial.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    if (this.newPassword === this.oldPassword) {
      this.iziToast.show({
        message: 'Vous avez dèjà utilisé ce mot de passe.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    if (this.newPassword != this.confirmationPassword) {
      this.iziToast.show({
        message: 'Les nouveaux mots de passe doivent être identiques.',
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    var newMed = {
      "_id": this.medecin._id,
      "oldPassword": this.oldPassword,
      "newPassword": this.newPassword,
      "confirmationPassword": this.confirmationPassword,
    }
    var newmsg; ""
    this.medecinService.updatePasswordNewFunction(newMed).subscribe((data: any) => {
      if (data && !data.success) {
        newmsg = data.msg
        this.iziToast.show({
          message: newmsg,
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      if (data && data.success) {
        this.iziToast.show({
          message: 'Bien enregistré',
          messageColor: '#386641',
          progressBarColor: '#6a994e',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#dde5b6',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
        });
        this.oldPassword = ""
        this.newPassword = ""
        this.confirmationPassword = ""
        return true;
      }
    },
      err => {
        this.iziToast.show({
          message: 'Une erreur est survenue.',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      });


  }
  showVersionFr() {
    this.versionFr = true;
    this.versionAr = false;
  }
  showVersionAr() {
    this.versionFr = false;
    this.versionAr = true;
  }
}
