<div class="container p-sm-5 p-3" *ngIf="medecin">
  <div class="row">
    <div class="col-12">
      <!-- Page title -->
      <div class="mb-5">
        <h3>Profil du médecin</h3>
        <hr style="width: 16%;color: #7fd8d2;height: 3px;">
      </div>
      <!-- Form START -->
      <form class="file-upload">
        <div class="row mb-5 gx-5">
          <!-- Contact detail -->
          <div class="col-xxl-8 mb-5 mb-xxl-0">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <h4 class="mb-1 mt-0">Détails de contact</h4>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 mb-4">
                    <span [ngClass]="{'span-langue-fr activeLangueFr': versionFr, 'span-langue-fr': !versionFr}" (click)="showVersionFr()">FR</span> <span [ngClass]="{'span-langue-ar activeLangueAr': versionAr, 'span-langue-ar': !versionAr}" (click)="showVersionAr()">AR</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <label for="inputEmail4" class="form-label">Activer l'authentification à deux facteurs </label>
                  <label class="switch m-l-10" style="left: 10px;">
                    <input type="checkbox" [(ngModel)]="medecin.doubleFactorsAuthenticate" name="doubleFactorsAuthenticate" />
                    <span class="slider round"></span>
                  </label>
                </div>
                <!-- First Name -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">Nom *</label>
                  <input type="text" class="form-control" placeholder="Nom" aria-label="First name" value="{{medecin.nom}}" style="cursor: no-drop;" disabled>
                </div>
                <!-- Last name -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">Prénom *</label>
                  <input type="text" class="form-control" placeholder="Prénom" aria-label="Last name" value="{{medecin.prenom}}" style="cursor: no-drop;" disabled>
                </div>
                <div class="col-md-6" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">N° Inscription *</label>
                  <input type="text" placeholder="N° Inscription" class="form-control" id="inputEmail4" style="cursor: no-drop;" disabled value="{{medecin.numInscription}}" name="email">
                </div>
                <div class="col-md-6" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">Email *</label>
                  <input type="email" placeholder="Email" class="form-control" id="inputEmail4" [(ngModel)]="medecin.email" name="email">
                </div>
                <!-- Phone number -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">N° mobile</label>
                  <international-phone-number [(ngModel)]="medecin.tel" name="tel" placeholder="N° mobile" [maxlength]="18" [defaultCountry]="'tn'" [locale]="'tn'" [required]="true"></international-phone-number>
                </div>
                <!-- Mobile number -->
                <div class="col-md-6" *ngIf="versionFr">
                  <label class="form-label">N° fixe</label>
                  <international-phone-number [(ngModel)]="medecin.telFixe" name="telFixe" placeholder="N° fixe" [maxlength]="18" [defaultCountry]="'tn'" [locale]="'tn'" [required]="true"></international-phone-number>
                </div>


                <!-- codeCNAM-->
                <div class="col-md-6" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">Code CNAM</label>
                  <input type="text" placeholder="code CNAM" class="form-control" id="inputEmail4" [(ngModel)]="medecin.codeCNAM" name="codeCNAM">
                </div>
                <!-- Email -->

                <div class="col-md-6" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">Code couleur</label>
                  <input type="color" placeholder="Code couleur" class="form-control" id="inputEmail4" [(ngModel)]="medecin.colorTemplates" name="colorTemplates">
                </div>


                <div class="col-md-6" *ngIf="versionFr">
                  <label for="inputEmail4" class="form-label">Logo</label>
                  <input type="file" placeholder="Logo" (change)="droppedFileLogo($event)" accept="image/*" class="form-control" id="inputEmail4" name="logo">
                  <img src="{{pathImageLogo}}" *ngIf="pathImageLogo" class="logo-cabinet">
                </div>


                <!-- First Name -->
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Nom </label>
                  <input type="text" class="form-control" placeholder="Nom" aria-label="First name" [(ngModel)]="medecin.nomAr" name="nomAr" value="{{medecin.nomAr}}">
                </div>
                <!-- Last name -->
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Prénom </label>
                  <input type="text" class="form-control" placeholder="Prénom" aria-label="Last name" [(ngModel)]="medecin.prenomAr" name="prenomAr" value="{{medecin.prenomAr}}">
                </div>
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Spécialité </label>
                  <input type="text" class="form-control" placeholder="Spécialité" aria-label="Spécialité" [(ngModel)]="medecin.specialiteAr" name="specialiteAr" value="{{medecin.specialiteAr}}">
                </div>
                <div class="col-md-6" *ngIf="versionAr">
                  <label class="form-label">Adresse </label>
                  <input type="text" class="form-control" placeholder="Adresse" aria-label="Adresse" [(ngModel)]="medecin.adresseAr" name="adresseAr" value="{{medecin.adresseAr}}">
                </div>
              </div> <!-- Row END -->
            </div>
          </div>
          <!-- Upload profile -->
          <div class="col-xxl-4">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <h4 class="mb-4 mt-0">Ajouter votre photo de profil </h4>
                <div class="text-center">
                  <!-- Image upload -->
                  <div [ngClass]="{'square position-relative display-2 mb-3': !pathImage}">
                    <img src="assets/images/person.png" class="img-profile" *ngIf="!pathImage" data-toggle="modal" data-target="#myModal7" style="cursor: pointer;" title="Ajouter votre photo de profil">
                    <img class="img-profile-new-added" src="{{pathImage}}" *ngIf="pathImage" data-toggle="modal" data-target="#myModal7" title="Ajouter votre photo de profil">
                  </div>
                  <!-- Button -->
                </div>

              </div>
            </div>
          </div>
        </div> <!-- Row END -->
        <!-- Social adresse -->
        <div class="row mb-5 gx-5">
          <div class="col-xxl-6 mb-5 mb-xxl-0">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <h4 class="mb-4 mt-0">Adresse de cabinet</h4>
                <!-- Ville -->
                <div class="col-md-6">
                  <label class="form-label">Ville*</label>
                  <select class="form-control input-sm" [(ngModel)]="medecin.adresseCabinet.ville" name="ville" value="{{medecin.adresseCabinet.ville}}" (ngModelChange)="onSelectville(medecin.adresseCabinet.ville)">
                    <option></option>
                    <option *ngFor="let ville of listefiltreville">{{ville}}</option>
                  </select>
                </div>
                <!-- Code postal -->
                <div class="col-md-6">
                  <label class="form-label">Code postal*</label>
                  <select class="form-control input-sm" value="{{medecin.adresseCabinet.codePostal}}" [(ngModel)]="medecin.adresseCabinet.codePostal" name="codePostal">
                    <option></option>
                    <option *ngFor="let postal of listefiltrecode">{{postal}}</option>
                  </select>
                </div>
                <!-- Adresse -->
                <div class="col-md-6">
                  <label class="form-label"> Adresse*</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.adresseCabinet.adresse" name="adresse" aria-label="adresse" placeholder="Adresse">
                </div>
                <!-- Dribble -->
              </div> <!-- Row END -->
            </div>
          </div>
          <!-- change password -->
          <div class="col-xxl-6">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <h4 class="my-4 mt-0">Changer votre mot de passe</h4>
                <!-- Old password -->
                <div class="col-md-6">
                  <label for="exampleInputPassword1" class="form-label">Ancien mot de passe *</label>
                  <input type="password" class="form-control" name="oldPassword" [(ngModel)]="oldPassword" id="exampleInputPassword1" placeholder="Ancien mot de passe">
                </div>
                <!-- New password -->
                <div class="col-md-6">
                  <label for="exampleInputPassword2" class="form-label">Nouveau mot de passe *</label>
                  <input type="password" class="form-control" name="newPassword" [(ngModel)]="newPassword" id="exampleInputPassword2" placeholder="Nouveau mot de passe">
                </div>
                <!-- Confirm password -->
                <div class="col-md-12">
                  <label for="exampleInputPassword3" class="form-label">Confirmation de mot de passe *</label>
                  <input type="password" class="form-control" name="confirmationPassword" [(ngModel)]="confirmationPassword" id="exampleInputPassword3" placeholder="Confirmation de mot de passe">
                </div>
              </div>
              <div class="gap-3 d-md-flex justify-content-md-center text-center mt-3">
                <button type="button" class="btn btn-primary btn-lg btn-save-2" (click)="updatePassword()">Modifer mot de passe</button>
              </div>
            </div>
          </div>
        </div> <!-- Row END -->
        <div class="row mb-5 gx-5">
          <div class="col-xxl-6 mb-5 mb-xxl-0">
            <div class="bg-secondary-soft px-4 py-5 rounded">
              <div class="row g-3">
                <h4 class="mb-4 mt-0">Détails des réseaux sociaux</h4>
                <!-- Facebook -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/facebook-new.png" style="margin-top: -6px;"> Facebook</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.facebook" name="facebook" aria-label="Facebook" placeholder="https://www.facebook.com/profil">
                </div>
                <!-- Twitter -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/twitter-new.png" style="margin-top: -6px;">Twitter</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.twitter" name="twitter" aria-label="Twitter" placeholder="https://www.twitter.com/profil">
                </div>
                <!-- Linkedin -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/linkedin-new.png">Linkedin</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.linkedin" name="linkedin" aria-label="Linkedin" placeholder="https://www.linkedin.com/in/profil">
                </div>
                <!-- Instragram -->
                <div class="col-md-6">
                  <label class="form-label"><img src="assets/images/instagram-new.png" style="margin-top: -6px;">Instagram</label>
                  <input type="text" class="form-control" [(ngModel)]="medecin.instagram" name="instagram" aria-label="Instragram" placeholder="https://www.instragram.com/profil">
                </div>
                <!-- Dribble -->
              </div> <!-- Row END -->
            </div>
          </div>
          <!-- change password -->
        </div> <!-- Row END -->
        <!-- button -->
        <div class="gap-3 d-md-flex justify-content-md-center text-center">
          <button type="button" class="btn btn-primary btn-lg btn-save" (click)="updateInfoMedecin()">Enregistrer</button>
          <br><br><br>
        </div>
      </form> <!-- Form END -->
    </div>
  </div>
</div>
<!-- modal modal ajout photo de profil -->
<div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="myModal7" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan" style="min-width: 650px !important;">
      <div class="modal-header d-flex justify-content-center">
        <h3 class="titre-modal-statut">
          Ajouter votre photo de profil
        </h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <h5 [ngClass]="{'active-type-photo-profile': isActiveDownloadPhoto}" (click)="isActiveDownloadPhotoFunction()">Télécharger une photo</h5>
          </div>
          <div class="col-md-6">
            <h5 [ngClass]="{'active-type-photo-profile': isActiveTakePhoto}" (click)="isActiveTakePhotoFunction()">Prendre une photo</h5>
          </div>
        </div>
        <!-- add photo -->
        <div class="row" *ngIf="isActiveDownloadPhoto">
          <br>
          <div class="row">
            <div class="col-md-12">
              <br>
              <label for="images" class="drop-container" id="dropcontainer">
                <span class="drop-title">Déposer les fichiers ici</span>
                ou
                <input type="file" id="images" (change)="droppedFile($event)" accept="image/*" required style="display: none;">
                <label for="images" class="custom-file-upload">
                  Choisir un fichier
                </label>
              </label>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <br>
              <button class="btn button-save" style="background: #f16c28;" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
            </div>
            <div class="col-md-6">
              <br>
              <button class="btn button-save" data-dismiss="modal">Annuler</button>
            </div>
          </div>
        </div>
        <!-- take photo -->
        <div class="row" *ngIf="isActiveTakePhoto">
          <br>
          <div class="row">
            <p *ngIf="!authorized" id="alertActiverCamera">L'accès à la caméra est bloqué. <br> Veuillez l'activer dans les paramètres de votre navigateur.</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <video #video id="video" width="280" height="280" autoplay></video>
            </div>
            <div class="col-md-6">
              <canvas #canvas id="canvas" style="border-radius: 140px;" width="280" height="280"></canvas>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <br>
              <button class="btn button-save" style="background: #f16c28;" *ngIf="authorized" (click)="capture()">Prendre une photo</button>
              <br>
              <button class="btn button-save" *ngIf="authorized && captures && captures.length >0" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- modal ajout photo de profil  -->
