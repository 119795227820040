<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fullcalendar/3.6.1/fullcalendar.min.css">
<style media="screen">
  #updateButton {
    background-color: transparent;
    color: #9af0cb;
    border-color: #9af0cb;
  }

  .fc {
    text-transform: capitalize !important;
  }

  .btn-margin {
    margin-bottom: 15px;
  }

  .btn-righ {
    margin-right: 10px;
  }

  .dot {
    height: 15px;
    width: 15px;

    border-radius: 50%;
    display: inline-block;
  }

  #spanColor {
    margin: 0 0 0 5px;
    color: black;
  }
</style>
<section style="margin-top:120px">
  <div id="demo">
    <!--     <div class="row">
      <div class="col-12">
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #039be5"></span> <span id="spanColor">Les rendez-vous personnels</span>
        </div>
        <div class="col-3 float-left">
            <span class="dot" style="background-color: #23b1a5"></span> <span id="spanColor">Les créneaux libres</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #008000"></span> <span id="spanColor">Les rendez-vous professionnels</span>
        </div>
        <div class="col-3 float-left">
          <span class="dot" style="background-color: #f48a54"></span> <span id="spanColor">Les rendez-vous en attente</span>
        </div>
      </div>
    </div> -->
    <br>
    <div class="row">
      <div class="col-12" style="margin-left: 15px;width: 97%;">
        <ng-fullcalendar id="calendar" [options]="calendarOptions" style="color:black" (eventClick)="eventClick($event.detail.event)" (dayClick)="dayClick($event.detail)"></ng-fullcalendar>
      </div>
    </div>
  </div>
  <br><br><br><br>
</section>
<div class="modal fade" id="createEventModal" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <h4 class="modal-title">
          Créer un rendez-vous
        </h4>

        <a class="exit" aria-label="Close" data-dismiss="modal" (click)="resetForm()"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>
      <div class="modal-body">
        <form [formGroup]="form" class="form-horizontal" role="form">
          <div class="col-12">
            <div class="patient-container">
              <!-- Ancien patient -->
              <div>
                <p for="ancienPatient" class="isLabel font-weight-bold" [ngClass]="{isActiveLabel: typePatient === true}" (click)="togglePatientType(true)">Ancien patient</p>
              </div>
              <!-- Nouveau patient -->
              <div>
                <p for="nouveauPatient" class="isLabel font-weight-bold" [ngClass]="{isActiveLabel: typePatient === false}" (click)="togglePatientType(false)">Nouveau patient</p>
              </div>
            </div>
            <!-- Ancien patient -->
            <div *ngIf="typePatient === true">
              <div *ngIf="patients?.length>0" class="my-2">
                <p for="Type" class="font-weight-bold">Rechercher le patient :<b style="color: red;"> *</b></p>
                <ng-select [items]="patients" bindLabel="fullDetails" placeholder="Rechercher par nom,prénom,date d'anniversaire" [virtualScroll]="true" [(ngModel)]="selectedPatient" formControlName="patient" [clearable]="true" [searchable]="true" (change)="onPatientSelect($event)" [searchFn]="customSearchFn" notFoundText="Aucun élément n'est trouvé" class="form-control input-sm">
                  <!-- Custom display for patients (optional) -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.nom }} {{ item.prenom }} {{ item.dateAnniversaire }}
                  </ng-template>
                </ng-select>
                <control-messages [control]="form.controls.patient" style="color: red;margin: -10px!important;position: relative;top: 5px;left: 10px;"></control-messages>
              </div>

              <div *ngIf="patients?.length==0" class="font-weight-bold">
                aucun élément n'est trouvé
              </div>
            </div>
            <!-- Nouveau patient -->
            <div *ngIf="typePatient === false">
              <div formArrayName="patientsArray">
                <div *ngFor="let patient of form.get('patientsArray').controls; let i = index" [formGroupName]="i">

                  <!-- Num Fiche -->
                  <div class="form-group">
                    <label for="num fiche" class="font-weight-bold">N° Fiche <b style="color: red;"> *</b></label>
                    <input formControlName="numFiche" type="text" placeholder="numFiche" class="form-control font-weight-bold" onkeypress="return /[a-zA-Z0-9]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.numFiche"></control-messages>
                  </div>


                  <!-- Nom -->
                  <div class="form-group">
                    <label for="nom" class="font-weight-bold">Nom <b style="color: red;"> *</b></label>

                    <input formControlName="nom" type="text" placeholder="Nom" class="form-control font-weight-bold" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.nom"></control-messages>
                  </div>

                  <!-- Prénom -->
                  <div class="form-group">
                    <label for="prenom" class="font-weight-bold">Prénom <b style="color: red;"> *</b></label>
                    <input formControlName="prenom" type="text" placeholder="Prénom" class="form-control font-weight-bold" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.prenom"></control-messages>
                  </div>
                  <!-- Date de naissance -->
                  <div class="form-group">
                    <label for="dateAnniversaire" class="font-weight-bold">Date de Naissance <b style="color: red;"> *</b></label>
                    <input formControlName="dateAnniversaire" type="date" class="form-control font-weight-bold" max="{{today |date:'yyyy-MM-dd'}}" />
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px" [control]="patient.controls.dateAnniversaire"></control-messages>
                  </div>

                  <!-- Téléphone -->
                  <div class="form-group">
                    <label for="telephone" class="font-weight-bold">N° Téléphone <b style="color: red;"> *</b></label>
                    <ngx-intl-tel-input class="'form-control font-weight-bold'" [enableAutoCountrySelect]="false" formControlName="tel" [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Tunisia" [maxLength]="8" [phoneValidation]="true"></ngx-intl-tel-input>
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="patient.controls.tel"></control-messages>
                    <div *ngIf="patient.value.tel&&!patient.controls['tel'].invalid == false" class="mt-1" style="color: red;">
                      Ce numéro de téléphone est invalide. <i class="bi bi-x"></i>
                    </div>
                  </div>

                  <!-- Sexe -->
                  <div class="form-group">
                    <label for="sexe" class="font-weight-bold">Sexe <b style="color: red;"> *</b></label>
                    <select formControlName="sexe" class="form-control font-weight-bold">
                      <option value="" disabled selected STYLE="color:#23b1a5 "></option>
                      <option>Homme</option>
                      <option>Femme</option>
                    </select>
                    <control-messages style="color: red;position: relative;top: 20px;left: 15px;" [control]="patient.controls.sexe"></control-messages>
                  </div>


                </div>
              </div>

              <!-- Submit button -->

              <div class="d-flex justify-content-center" *ngIf="addedPatient===false">
                <button type="submit" class="btn btn-primary mb-3 mt-1" [disabled]="!form.get('patientsArray').valid ||submitted==true" (click)="submitNewPatientForm()">Ajouter le patient</button>
              </div>

              <div *ngIf="addedPatient===true">
                <button type="submit" class="btn btn-primary mb-3 mt-1" [ngClass]="{'d-none':'addedPatient===true'}" [disabled]="!form.get('patientsArray').valid ||submitted==true" (click)="submitNewPatientForm()">Ajouter le patient</button>
              </div>

            </div>
            <div *ngIf="showMotif==true">
              <p for="Type" class="font-weight-bold">Motif <b style="color: red;"> *</b></p>
              <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)"></ng-select>
              <div><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;" (click)="removeMotif(mot)">X</div> <br></span></div>
              <control-messages [control]="form.controls.motif" style="color: red;margin: -10px!important;position: relative;top: 5px;left: 10px;"></control-messages>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <!-- Ajout de la classe pour centrer -->
            <div class="text-center" *ngIf="addedPatient===true"> <!-- Ajout de la classe pour centrer -->
              <button class=" btn btn-secondary mr-2" data-dismiss="modal" (click)="resetForm()">Annuler</button>
              <button class="btn btn-primary" (click)="createConsultation()">Sauvegarder</button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="detailsEventModal" *ngIf="eventClicked" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan">
      <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
        <h4 class="modal-title">
          Détails d'un rendez-vous
        </h4>

        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
      </div>
      <div class="modal-body">
        <div>
          <div class="row">
            <!-- First card, shown if eventClicked.type is not present -->
            <div class="col-10" *ngIf="!eventClicked.type">
              <h5 class="card-title">Patient détails</h5>
              <div class="card mb-3" *ngIf="eventClicked?.patient">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Patient :
                    <span class="badge">{{ eventClicked.patient?.nom }} </span> &nbsp; <span class="badge"> {{ eventClicked.patient?.prenom }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient.age">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Age : <span class="badge">{{ eventClicked.patient.age }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient?.profession">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Profession : <span class="badge">{{ eventClicked.patient.profession }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.motif">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Motif :&nbsp;
                    <ul class="list-inline mb-0 d-flex flex-wrap  align-items-center">
                      <li class="list-inline-item" *ngFor="let mot of eventClicked.motif">
                        <span class="badge">{{ mot }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.duration">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Duration : <span class="badge">{{ eventClicked.duration }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.start">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    From :
                    <span class="badge"> {{ eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm' }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.end">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    To :
                    <span class="badge">{{ eventClicked.end._i | date: 'dd/MM/yyyy , HH:mm' }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.tags.length>0">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Tags:&nbsp;
                    <ul class="list-inline mb-0 d-flex align-items-center">
                      <li class="list-inline-item" *ngFor="let tag of eventClicked.tags">
                        <span class="badge">{{ tag }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!-- Second card, shown if eventClicked.type === 'nextAppointment' -->
            <div class="col-10" *ngIf="eventClicked.type === 'nextAppointment'">
              <h5 class="card-title">Prochain rendez-vous</h5>
              <div class="card mb-3" *ngIf="eventClicked?.patient">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Patient :
                    <span class="badge">{{ eventClicked.patient?.nom }} </span> &nbsp; <span class="badge"> {{ eventClicked.patient?.prenom }} </span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient.age">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Age : <span class="badge">{{ eventClicked.patient.age }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.patient?.profession">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    Profession : <span class="badge">{{ eventClicked.patient.profession }}</span>
                  </p>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.motif">
                <div class="card-body">
                  <div class="card-text d-flex flex-wrap  align-items-center font-weight-bold">
                    Motif :&nbsp;
                    <ul class="list-inline mb-0 d-flex align-items-center">
                      <li class="list-inline-item" *ngFor="let mot of eventClicked.motif">
                        <span class="badge">{{ mot }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card mb-3" *ngIf="eventClicked?.start">
                <div class="card-body">
                  <p class="card-text font-weight-bold">
                    At :
                    <span class="badge"> {{ eventClicked.start._i | date: 'dd/MM/yyyy , HH:mm' }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4">
            <!-- Ajout de la classe pour centrer -->
            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
              <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
              <button class="btn btn-primary" (click)="geToUpdateConsultation(eventClicked)">Modifier</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>