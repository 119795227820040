// shared-data.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private dataSubject = new Subject<any>();
  data$ = this.dataSubject.asObservable();

  private dataSubjectBtn = new Subject<any>();
  dataBtn$ = this.dataSubjectBtn.asObservable();

  private dataSubjectBtnLaf = new Subject<any>();
  dataBtnLaf$ = this.dataSubjectBtnLaf.asObservable();

  private dataSubjectConclusionConduite = new BehaviorSubject<{ conduite: string; conclusion: string }>({ conduite: '0', conclusion: '0' });
  dataConclusionConduite$ = this.dataSubjectConclusionConduite.asObservable();

  updateData(data: any) {
    this.dataSubject.next(data);
  }
  updateDataBtn(data: any) {
    this.dataSubjectBtn.next(data);
  }
  updateDataBtnLaf(data: any) {
    this.dataSubjectBtnLaf.next(data);
  }
  updateConclusionConduite(data: { conduite: string; conclusion: string }) {
    this.dataSubjectConclusionConduite.next(data);
  }


}
