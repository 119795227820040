<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire une lettre pour confrère') > -1 && medcin">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
  <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
  <style>
    .bold-line {
      border-top: 3px solid;
    }

    .letter-spacing {
      letter-spacing: 3px;
    }

    ul {
      list-style-type: none;
    }

    .main-contenu {
      padding-left: 3.2rem !important;
      height: 800px;
    }

    .contenu {
      /* font-sZize: 1rem; */
      font-weight: 600;
    }

    #footer-cont {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      height: 150px;
    }

    .header {
      height: 200px;
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100vh;
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  </style>
  <div class="flex-wrapper">
    <!-- En-tête -->
    <app-header-template></app-header-template>
    <!-- </header> -->

    <!-- Content-->
    <div class=" container mt-5 ml-7 main-contenu" id="main-cont">
      <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>Lettre de confrère </strong></h2>
      <strong>
        <div class="mt-5">
          <p class="text-end contenu">Tunis le {{today | date:'dd/MM/yyyy'}}</p>
          <p class="text-end contenu">A madame/monsieur
            <span *ngIf="myExamen.foundImpression.medecin.nom">{{myExamen.foundImpression.medecin.nom}} &nbsp;</span>
            <span *ngIf="!myExamen.foundImpression.medecin.nom">.......................................................&nbsp;</span>

            <span *ngIf="myExamen.foundImpression.medecin.prenom">{{myExamen.foundImpression.medecin.prenom}} &nbsp;</span>
            <span *ngIf="!myExamen.foundImpression.medecin.prenom">................................................... &nbsp;</span>
          </p>
        </div>
        <div *ngIf="myExamen.foundImpression.contenue" class="mt-5 contenu">
          <p class="mt-2">Chère Consœur/Cher Confrère,</p>
          <p>{{myExamen.foundImpression.contenue}}</p>
        </div>
        <div *ngIf="!myExamen.foundImpression.contenue" class="mt-5 contenu">
          <p class="mt-2">Chère Consœur/Cher Confrère,</p>
          <p>
            ..................................................................................................................................................................................
          </p>
        </div>
      </strong>

    </div>
    <!-- End Content-->

    <!-- Pied de page  -->
    <app-footer-template></app-footer-template>

    <!-- </footer> -->

    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

  </div>
</div>