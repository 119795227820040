<br>
<div class="container mt-3 home-body" style="max-width: 2000px !important; margin: 0 auto !important;" *ngIf="myExamen">
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 border-right" *ngIf="patient">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 image-container">
        <img src="assets/images/patsDetails/man.png" *ngIf="!pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="img-profile-med" alt="">
        <img src="{{pathImage}}" *ngIf="pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="img-profile-med" alt="">
        <div class="upload-indicator">
          <img src="assets/images/img-camera.png" data-toggle="modal" data-target="#fileUploadModal" style="cursor: pointer;width: 30px;" title="Prendre une photo">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 person-details">
        <h6 id="title-dash-med"><b> {{patient.nom}} {{patient.prenom}}</b></h6>
        <h6 id="title-dash-med"><b> Age:{{patient.age}}</b></h6>
        <h6 id="title-dash-med"><b> {{patient.dateAnniversaire | date: 'dd/MM/yyyy'}}</b></h6>
      </div>
      <!-- Modal -->
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 border-right">
      <form [formGroup]="formMotif">
        <h6 id="title-dash-med"><b> Motif : </b></h6>
        <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)"></ng-select>
        <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors  " class="text-center">
          <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
          <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
          <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
        </div>
        <div class="motif-cl mt-1"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;" (click)="removeMotif(mot)">X</div><br></span></div>
      </form>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12 border-right">
      <h6 id="title-dash-med"><b *ngIf="historiquesConsultations">Nombre des visites : {{historiquesConsultations.length + 1}}</b></h6>
      <h6 id="title-dash-med" style="cursor: pointer;text-decoration: underline;" data-toggle="modal" data-target="#exampleModal"><b>Historiques des consultations</b></h6>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <img src="assets/images/chronometer.png" class="chronometer-img" alt=""> Durée : {{ timer }}
      <a (click)="restart()" title="Démarrer" *ngIf="showAndHidePlaytimer"><img src="assets/images/play.png" class="img-timer"> </a>
      <a (click)="pauseTimer()" title="Pause" *ngIf="!showAndHidePlaytimer"><img src="assets/images/pause.png" class="img-timer" alt=""> </a>
      <br>
      <button class="btn btn-secondary close-btn" title="Terminer la consultation" (click)="fermeConsultation()">Terminer</button>
    </div>
  </div>
  <button class="scroll-button left-button custom-button" title="Suivi codage de consultation" (click)="scrollLeft()">
    <div>+</div>
  </button>
  <button class="scroll-button right-button custom-button" title="Historiques des consultations" (click)="scrollRight()">
    <div>+</div>
  </button>
  <div class="row modules">
    <div class="col" [class.activeclass]="isActiveInterrogatoire" (click)="clickisActiveInterrogatoire()">
      <h4 id="p-r-t-7">Interrogatoire</h4>
    </div>
    <div class="col" [class.activeclass]="isActiveExamen" (click)="clickisActiveExamen()">
      <h4 id="p-r-t-7">Examen</h4>
      <!-- <div class="arrow arrow-up btnhistoriques" *ngIf="isActiveExamen" style="font-size:14px">▼</div> -->
    </div>
    <div class="col" [class.activeclass]="isActiveConclusion && !isActiveConduite" (click)="clickisActiveConclusion()">
      <h4 id="p-r-t-7">Conclusion</h4>
    </div>
    <div class="col" [class.activeclass]="isActiveConduite && isActiveConduite" (click)="clickisActiveConduite()">
      <h4 id="p-r-t-7">Conduite à tenir</h4>
    </div>
    <div class="col" [class.activeclass]="isActiveDocuments" (click)="clickisActiveDocuments()">
      <h4 id="p-r-t-7">Documents</h4>
    </div>
  </div>
  <!-- Interrogatoire -->
  <div class="row p-r-t-40" *ngIf="isActiveInterrogatoire">
    <br><br>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
      <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveOphtaPerso}" (click)="showAnteOphtaPerso()">Antécédents ophtalmologiques personnels</p>
      <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActivgene}" (click)="showAntegene()">Antécédents généraux / familiaux ophtalmologiques</p>
      <!-- <p id="p-interrogatoire" [ngClass]="{'p-interrogatoire': isActiveAnteFamiOpht}" (click)="showAnteFamiOpht()">Antécédents familiaux ophtalmologique</p> -->
    </div>
    <!--Antécédents ophtalmologique personnels-->

    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 questions-zone" *ngIf="isActiveOphtaPerso">
      <h2 class="mb-4">Antécédents ophtalmologiques personnels</h2>
      <div class="row mb-20">
        <!--------------------start Médicaux -------------->
        <div class="row">
          <h6 class="title-h6">Médicaux</h6>
          <div class="col-md-6 m-l-10 m-t-20">
            <ng-select [(ngModel)]="selectedMedical" [items]="medicauxListt" [virtualScroll]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" notFoundText="Aucun élément n'est trouvé." (change)="onMedicalChange($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
          </div>
        </div>
        <div class="row m-b-20">
          <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux.length > 0" class=" allOptionsAntecedOphtalPer col-md-12 m-l-10 m-t-20   m-l-10" style="margin-top: 5px;">
            <div *ngFor="let medical of myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
              <div class="d-flex mt-3 ">
                <span title="{{medical.date | date:'dd/MM/yyyy, à HH:mm'}}" class="c-p">
                  {{medical.name}}
                </span>
                <div>
                  <!-- Checkbox for Oeil Droit -->
                  <input type="checkbox" class="radiotypeinput" name="OeilDroit" id="oeilDroit" [checked]="medical.oiel==='oeilDroit'" (change)="selectOeil(medical, 'oeilDroit')">
                  <label for="oeilDroit">Oeil droit</label>

                  <!-- Checkbox for Oeil Gauche -->
                  <input type="checkbox" class="radiotypeinput" name="OeilGauche" id="oeilGauche" [checked]="medical.oiel==='oeilGauche'" (change)="selectOeil(medical, 'oeilGauche')">
                  <label for="oeilGauche">Oeil gauche</label>

                  <!-- Checkbox for Deux Yeux (both eyes) -->
                  <input type="checkbox" class="radiotypeinput" name="DeuxYeux" id="deuxYeux" [checked]="medical.oiel==='deuxYeux'" (change)="selectOeil(medical, 'deuxYeux')">
                  <label for="deuxYeux">Deux yeux</label>
                </div>
                <div class="deleted-btn" title="Supprimer" (click)="removeMedical(medical)">X</div>
              </div>

              <input type="text" placeholder="Ajouter une description" maxlength="250" class="form-control input-sm w-300" [(ngModel)]="medical.description" (change)="validateFields($event,'Médicaux OD','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">
            </div>
          </div>
        </div>
        <!--------------------end Médicaux -------------->

        <!-- ------------------start traitement en cours -->

        <div class="row ">
          <h6 class="title-h6">Traitement en cours</h6>
          <div class="col-md-6 m-l-10 m-t-20">
            <ng-select [(ngModel)]="selectedTypeClass" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" notFoundText="Aucun élément n'est trouvé." [items]="typeClasses" (change)="onTypeClassChange($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9%]/.test(event.key)"></ng-select>
            <div *ngIf="medicaments && !categories" class="mt-2 ml-3">
              <label for="medicaments" class="font-weight-bold">Choisir un médicament</label>
              <ng-select [(ngModel)]="selectedTraitement" placeholder="Selectionner un médicament" [virtualScroll]="true" [clearable]="false" notFoundText="Aucun élément n'est trouvé." [items]="medicaments" (change)="onTraitementChange()" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9%]/.test(event.key)">
              </ng-select>
            </div>

            <div *ngIf="categories" class="mt-2 ml-3">
              <label for="category" class="font-weight-bold">Choisir une catégorie</label>
              <ng-select [(ngModel)]="selectedCategory" placeholder="Selectionner une catégorie" [virtualScroll]="true" [clearable]="false" notFoundText="Aucun élément n'est trouvé." [items]="categories" bindLabel="category" (change)="onCategoryChange()" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9%]/.test(event.key)">
              </ng-select>
            </div>

            <div *ngIf="medicaments && categories" class="mt-2 ml-5">
              <label for="medicaments" class="font-weight-bold">Choisir un médicament</label>
              <ng-select [(ngModel)]="selectedTraitement" placeholder="Selectionner un médicament" [virtualScroll]="true" [clearable]="false" notFoundText="Aucun élément n'est trouvé." [items]="medicaments" (change)="onTraitementChange()" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9%]/.test(event.key)">
              </ng-select>
            </div>


          </div>
        </div>
        <div class="row m-b-20">
          <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.length > 0" class="allOptionsAntecedOphtalPer col-md-12 m-l-10 m-t-20   m-l-10" style="margin-top: 5px;">
            <div *ngFor="let traitement of myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">

              <div class="d-flex justify-content-start mt-3">
                <span title="{{traitement.date | date:'dd/MM/yyyy, à HH:mm'}}" class="c-p">
                  {{traitement.name}}
                </span>
                <div>
                  <!-- Checkbox for Oeil Droit -->
                  <input type="checkbox" class="radiotypeinput" name="OeilDroit" id="oeilDroit" [checked]="traitement.oiel==='oeilDroit'" (change)="selectOeil(traitement, 'oeilDroit')">
                  <label for="oeilDroit">Oeil droit</label>

                  <!-- Checkbox for Oeil Gauche -->
                  <input type="checkbox" class="radiotypeinput" name="OeilGauche" id="oeilGauche" [checked]="traitement.oiel==='oeilGauche'" (change)="selectOeil(traitement, 'oeilGauche')">
                  <label for="oeilGauche">Oeil gauche</label>

                  <!-- Checkbox for Deux Yeux (both eyes) -->
                  <input type="checkbox" class="radiotypeinput" name="DeuxYeux" id="deuxYeux" [checked]="traitement.oiel==='deuxYeux'" (change)="selectOeil(traitement, 'deuxYeux')">
                  <label for="deuxYeux">Deux yeux</label>
                </div>
                <div class="deleted-btn" title="Supprimer" (click)="removeTraitement(traitement)">X</div>
              </div>

              <div class="d-flex justify-content-start flex-wrap gap-2">
                <input type="text" placeholder="Ajouter une description" maxlength="250" class="form-control input-sm w-300" [(ngModel)]="traitement.description" (change)="validateFields($event, 'Traitement en cours','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">
                <div class="input-group input-sm w-300">
                  <span class="input-group-text" id="basic-addon3">Posologie :</span>
                  <input type="texte" class="form-control input-sm" maxlength="250" [(ngModel)]="traitement.posologie" (change)="validateFields($event, 'Traitement en cours','poslogie')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ /0-9+\-*!=?()_.]/.test(event.key)" aria-describedby="basic-addon3 basic-addon4">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ------------------end traitement en cours -->
        <br>
        <!--------------------start Antécédents de traitement par laser -------------->
        <div class="row ">
          <h6 class="title-h6">Antécédents de traitement par laser</h6>
          <div class="col-md-6 m-l-10 m-t-20">
            <ng-select [(ngModel)]="selectetedAntecedentsdetraitementparlaser" [items]="listAntLAser" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" notFoundText="Aucun élément n'est trouvé." (change)="onAntLaserChange($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
          </div>
        </div>
        <div class="row m-b-20">
          <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser && myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name" class=" allOptionsAntecedOphtalPer col-md-12 m-l-10 m-t-20   m-l-10" style="margin-top: 5px;">

            <div class="d-flex justify-content-start mt-1">
              <span title="{{myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.date | date:'dd/MM/yyyy, à HH:mm'}}" class="c-p">
                {{myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name}}
              </span>
              <div>
                <!-- Checkbox for Oeil Droit -->
                <input type="checkbox" class="radiotypeinput" name="OeilDroit" id="oeilDroit" [checked]="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel==='oeilDroit'" (change)="selectOeil(myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser, 'oeilDroit')">
                <label for="oeilDroit">Oeil droit</label>

                <!-- Checkbox for Oeil Gauche -->
                <input type="checkbox" class="radiotypeinput" name="OeilGauche" id="oeilGauche" [checked]="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel==='oeilGauche'" (change)="selectOeil(myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser, 'oeilGauche')">
                <label for="oeilGauche">Oeil gauche</label>

                <!-- Checkbox for Deux Yeux (both eyes) -->
                <input type="checkbox" class="radiotypeinput" name="DeuxYeux" id="deuxYeux" [checked]="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel==='deuxYeux'" (change)="selectOeil(myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser, 'deuxYeux')">
                <label for="deuxYeux">Deux yeux</label>
              </div>
              <div class="deleted-btn" title="Supprimer" (click)="removeAntLaserChange()">X</div>
            </div>
            <div class="d-flex justify-content-start flex-wrap gap-2">
              <input type="text" placeholder="Ajouter une description" maxlength="250" class="form-control input-sm w-300" [(ngModel)]="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.description" (change)="validateFields($event, 'Antécédents de traitement par laser','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">
              <div class="input-group input-sm w-300">
                <span class="input-group-text" id="basic-addon3">Date :</span>
                <input type="date" class="form-control input-sm" [max]="today|date:'yyyy-MM-dd'" [value]="myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.dateAntecedent" (change)="onDateAntecedentChange(myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name,$event)" name="date antécédent" id="date antécédent" aria-describedby="basic-addon3 basic-addon4">
              </div>
            </div>


          </div>
        </div>
        <!--------------------end Antécédents de traitement par laser -------------->
        <br>
        <!--------------------start Chirurgicaux -------------->
        <div class="row ">
          <h6 class="title-h6">Chirurgicaux</h6>
          <div class="col-md-6 m-l-10 m-t-20">
            <ng-select [(ngModel)]="selectedChirugieType" [items]="listChirugie" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" notFoundText="Aucun élément n'est trouvé." (change)="onChirurgieTypeChange($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
          </div>
        </div>
        <div class="row m-b-20">
          <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length > 0" class=" allOptionsAntecedOphtalPer  m-l-10 m-t-20   m-l-10" style="margin-top: 5px;">
            <div *ngFor="let motif of myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
              <div class="d-flex justify-content-start mt-3 ">
                <span title="{{motif.date | date:'dd/MM/yyyy, à HH:mm'}}" class="c-p">
                  {{motif.name}}
                </span>
                <div>
                  <!-- Checkbox for Oeil Droit -->
                  <input type="checkbox" class="radiotypeinput" name="OeilDroit" id="oeilDroit" [checked]="motif.oiel==='oeilDroit'" (change)="selectOeil(motif, 'oeilDroit')">
                  <label for="oeilDroit">Oeil droit</label>

                  <!-- Checkbox for Oeil Gauche -->
                  <input type="checkbox" class="radiotypeinput" name="OeilGauche" id="oeilGauche" [checked]="motif.oiel==='oeilGauche'" (change)="selectOeil(motif, 'oeilGauche')">
                  <label for="oeilGauche">Oeil gauche</label>

                  <!-- Checkbox for Deux Yeux (both eyes) -->
                  <input type="checkbox" class="radiotypeinput" name="DeuxYeux" id="deuxYeux" [checked]="motif.oiel==='deuxYeux'" (change)="selectOeil(motif, 'deuxYeux')">
                  <label for="deuxYeux">Deux yeux</label>
                </div>
                <div class="deleted-btn" title="Supprimer" (click)="removeChirurgieType(motif)">X</div>
              </div>
              <div class="d-flex justify-content-start flex-wrap gap-2">
                <input type="text" placeholder="Ajouter une description" maxlength="250" class="form-control input-sm w-300" [(ngModel)]="motif.description" (change)="validateFields($event, 'Chirurgicaux','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">

                <div class="input-group input-sm w-300">
                  <span class="input-group-text" id="basic-addon3">Date :</span>
                  <input type="date" class="form-control input-sm" [max]="today|date:'yyyy-MM-dd'" [value]="motif.dateChirugie" (change)="onChirugieDateChange(motif,$event)" name="date Chirugie" id="date Chirugie" aria-describedby="basic-addon3 basic-addon4">
                </div>
              </div>


            </div>
          </div>
        </div>
        <!--------------------end Chirurgicaux -------------->
        <br>
        <!--------------------start Correction optique  -------------->

        <div class="row m-b-20  mt-3">
          <h6 class="title-h6">Correction optique</h6>
          <div class="col-md-6 m-l-10 m-t-20">
            <ng-select [(ngModel)]="selectedCorrectionOptique" [items]="listCorrectionOptique" bindLabel="name" [virtualScroll]="true" [clearable]="false" notFoundText="Aucun élément n'est trouvé." (change)="onCorrectionOptiqueChange()"></ng-select>
          </div>
        </div>

        <div class="row m-b-20">
          <div class="row">
            <div class="col-md-6 m-l-10 m-t-20">
              <div *ngFor="let type of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique;let i=index">
                <div *ngIf="type.name!=='None'" class="text-center">
                  <span>{{type.name}} <div class="deleted-btn mt-3" title="Supprimer" (click)="removeCorrectionOptique(type.name)">X</div></span>
                  <div class="row">
                    <div class=" col-md-4 ">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Sphère" [(ngModel)]="type.sphere" (change)="changeSphDroit(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementSphDroit(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementSphDroit(i)">▼</div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-4 ">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Cylindre" [(ngModel)]="type.cylindre" (change)="changeCylDroit(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementCylDroit(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementCylDroit(i)">▼</div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-4">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Axe" [(ngModel)]="type.axe" (change)="changeAxeDroit(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementAxeDroit(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementAxeDroit(i)">▼</div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 m-l-10 m-t-20">
              <div *ngFor="let type of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique;let i=index">
                <div *ngIf="type.name=='None'">
                  <span>{{type.name}} <div class="deleted-btn mt-3" title="Supprimer" (click)="removeCorrectionOptique(type.name)">X</div></span>
                </div>
                <div *ngIf="type.name!=='None'" class="text-center">
                  <span>{{type.name}} <div class="deleted-btn mt-3" title="Supprimer" (click)="removeCorrectionOptique(type.name)">X</div></span>
                  <div class="row">
                    <div class=" col-md-4 ">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Sphère" [(ngModel)]="type.sphere" (change)="changeSphGauche(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementSphGauche(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementSphGauche(i)">▼</div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-4 ">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Cylindre" [(ngModel)]="type.cylindre" (change)="changeCylGauche(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .+-]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementCylGauche(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementCylGauche(i)">▼</div>
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-4">
                      <div class="noteDroit">
                        <input type="text" maxlength="10" class="inputCyclop form-control input-sm" placeholder="Axe" [(ngModel)]="type.axe" (change)="changeAxeGauche(i,$event.target.value)" onkeypress="return /[a-zA-Z0-9 .]/.test(event.key)">
                        <div class="number-input ">
                          <div class="arrow arrow-up" (click)="incrementAxeGauche(i)">▲</div>
                          <br />
                          <div class="arrow arrow-down" (click)="decrementAxeGauche(i)">▼</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--------------------end Correction optique  -------------->

      </div>
      <div class="modal-footer d-flex justify-content-center" style="border:none">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td class="tdButt1 ">
                  <button class="btn-next" (click)="showAntegene()"><img src="assets/images/next.png" class="iconBoutton1"></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- END Antécédents ophtalmologique personnels-->
    <!--Antécédents généraux-->
    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 questions-zone" *ngIf="isActivgene || isActiveAnteFamiOpht">
      <h2>Antécédents généraux</h2>
      <div class="row m-b-20">

        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">

          <div class="row m-b-20">
            <ng-select [(ngModel)]="selectedantecedentsGeneraux" [items]="listALLantecedent" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" notFoundText="Aucun élément n'est trouvé." (change)="onantecedentsGeneraux($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
          </div>
          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present">
            <span class="select-options" title="{{myExamen.interrogation.antecedentsGeneraux.grossesseenCours.date | date:'dd/MM/yyyy, à HH:mm'}}">Grossesse en cours <div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Grossesse en cours')">X</div></span>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <input type="text" class="form-control input-sm p-r-l-19" placeholder="Ajouter une description" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.grossesseenCours.description" maxlength="250" (change)="validateFields($event,'Grossesse en cours','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">
            </div>
            <br>
          </div>


          <!--Terrain atopique-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainatopique.present">
            <span class="select-options">Terrain atopique <div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Terrain atopique')">X</div></span>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <ng-select [(ngModel)]="terrainatopiqueInput" [items]="terrainAtopiqueList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputterrainOptique($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
              <div class="all-options mt-2">
                <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelistterrainOptique(typeselect)">X</div></span>
              </div>
            </div>
            <br>
          </div>

          <!--Terrain vasculaire particulier-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present" class="ml-2">
            <div class="row">
              <span class="select-options">Terrain vasculaire particulier <div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Terrain vasculaire particulier')">X</div></span>
            </div>
            <input type="checkbox" class="radiotypeinput mt-2" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present"><label>Diabète</label>
            <div *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present" class="row">
              <div class="col-md-4">
                <label for="">Type</label>
                <select [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.type" class="form-control input-sm" (change)="validateFields($event, 'Diabète-type', 'name')">
                  <option *ngFor="let type of diabeticTypes" [value]="type">{{ type }}</option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="">Traitement</label>
                <ng-select [(ngModel)]="selectedDiabeteTraitement" [items]="diabeteTraitement" [virtualScroll]="true" [clearable]="false" [addTag]="false" (change)="addInputTerrainvasculaireparticulierTraitement($event)"></ng-select>
                <div class="all-options mt-2">
                  <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.traitement" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelistTerrainvasculaireparticulierTraitement(typeselect)">X</div></span>
                </div>
              </div>
              <div class="col-md-4">
                <label for="">Ancienneté ( an(s) )</label>
                <input type="number" maxlength="3" (input)="validateYear($event)" onkeypress="return /[0-9]/.test(event.key)" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.anciennete" class="form-control input-sm">
              </div>
              <br>
              <div class="col-md-4">
                <label for="">Dernière HBAIC</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.derniereHBAIC" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'Diabète-dernière HBAIC','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Dernière FO</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.derniereFO" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'Diabète-dernière FO','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Médecin Traitant</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.medecinTraitant" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'Diabète-Médecin traitant','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <br>
              <div class="col-md-4">
                <label for="">Résultat HBAIC</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.resultatHBAIC" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'Diabète-résultat HBAIC','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Résultat dernière FO</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.resultatderniereFO" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'Diabète-résultat dernière FO','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
            </div>
            <br>
            <input type="checkbox" class="radiotypeinput" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present"> HTA
            <div *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present" class="row">
              <div class="col-md-4">
                <label for="">Type</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.type" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'HTA-type','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Traitement</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.traitement" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'HTA-traitement','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Ancienneté ( an(s) )</label>
                <input type="number" maxlength="3" (input)="validateYear($event)" onkeypress="return /[0-9]/.test(event.key)" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.anciennete" class="form-control input-sm">
              </div>
              <br>
              <div class="col-md-4">
                <label for="">Dernière FO</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.derniereFO" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'HTA-dernière FO','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Résultat dernière FO</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.resultatderniereFO" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'HTA-résultat dernière FO','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
              <div class="col-md-4">
                <label for="">Médecin Traitant</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.medecinTraitant" class="form-control input-sm" maxlength="100" (change)="validateFields($event, 'HTA-Médecin traitant','name')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)">
              </div>
            </div>
            <br>
            <input type="checkbox" class="radiotypeinput" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present"> Autre
            <br>
            <div class="row" *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">
              <div class="col-md-8">
                <select [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.valeurs" class="form-control input-sm">
                  <option value="Dyslipidémie">Dyslipidémie</option>
                  <option value="Cardiopathie">Cardiopathie</option>
                  <option value="Traitement anticoagulant en cours">Traitement anticoagulant en cours</option>
                  <option value="Vascularité">Vascularité</option>
                  <option value="Syndrome d'apnée de sommeil">Syndrome d'apnée de sommeil</option>
                </select>
              </div>
            </div>
            <br>
          </div>

          <!--Terrain d'immunodépression-->

          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present">
            <span class="select-options">Terrain d'immunodépression<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Terrain dimmunodepression')">X</div></span>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <ng-select [(ngModel)]="terrainimmunodepressionInput" [items]="terrainimmunodepressionList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputterrainimmunodepression($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
              <div class="all-options mt-2">
                <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelistterrainimmunodepression(typeselect)">X</div></span>
              </div>
            </div>
            <br>
          </div>

          <!--Maladie dysimunitaire-->

          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present">
            <span class="select-options">Maladie dysimmunitaire <div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Maladie dysimmunitaire')">X</div></span>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <ng-select [(ngModel)]="maladiedysimunitaireInput" [items]="maladiedysimunitairelist" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputmaladiedysimunitaire($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
              <div class="all-options mt-2">
                <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelistmaladiedysimunitaire(typeselect)">X</div></span>
              </div>
            </div>
            <br>
          </div>

          <!--Maladie neurologique-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present">
            <span class="select-options">Maladie neurologique <div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Maladie neurologique')">X</div></span>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
              <ng-select [(ngModel)]="maladieneurologiqueInput" [items]="maladieNeurologiqueList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputmaladieneurologique($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
              <div class="all-options mt-2">
                <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelistmaladieneurologique(typeselect)">X</div></span>
              </div>
            </div>
            <br>
          </div>

          <!--Notion de vaccination récente-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present">
            <span class="select-options">Notion de vaccination récente<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Notion de vaccination récente')">X</div></span>
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="">Date</label>
                <input type="date" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date" class="form-control input-sm">
              </div>
              <div class="col-md-4">
                <label for="">Type</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type" class="form-control input-sm">
              </div>
            </div>
            <br>
          </div>

          <!--Notion d'anesthésie récente-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present">
            <span class="select-options">Notion d'anesthésie récente<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Notion danesthésie récente')">X</div></span>
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="">Date</label>
                <input type="date" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date" class="form-control input-sm">
              </div>
              <div class="col-md-4">
                <label for="">Type</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.type" class="form-control input-sm">
              </div>
            </div>
            <br>
          </div>

          <!--Traitement général en cours-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.traitementencours.present">
            <span class="select-options">Traitement général en cours<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Traitement général en cours')">X</div></span>
            <div class="row mt-2">
              <div class="col-md-4">
                <label for="">Type</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.traitementencours.type" class="form-control input-sm">
              </div>
              <div class="col-md-4">
                <label for="">Dose</label>
                <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.traitementencours.dose" class="form-control input-sm">
              </div>
            </div>
            <br>
          </div>

          <!--Cas particulier nourrisson enfant-->


          <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.present">
            <span class="select-options">Cas particulier nourrisson enfant<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux('Cas particulier nourrisson enfant')">X</div></span>
            <div class="row mt-2">
              <div class="col-md-8">
                <select class="form-control input-sm" (change)="addCasParticulierNourissonEnfant($event)">
                  <option></option>
                  <option>Accouchement césarienne</option>
                  <option>Accouchement voie basse</option>
                  <option>Cas de rétinoblastorne dans la famille</option>
                  <option>Comportement de malvoyance</option>
                  <option>Consanguinité</option>
                  <option>Faible poids de la naissance</option>
                  <option>Prématurité</option>
                  <option>Retard scolaire</option>
                  <option>Séroconversion pendant la grossesse</option>
                  <option>Souffrance foetale aigue</option>
                </select>
              </div>
              <br><br>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.present" (change)="addRemoveCasParticulierNourissonEnfant('Consanguinité')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.consanguinite.date | date:'dd/MM/yyyy, à HH:mm'}}">Consanguinité</span>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.present" (change)="addRemoveCasParticulierNourissonEnfant('Séroconversion pendant la grossesse')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.date | date:'dd/MM/yyyy, à HH:mm'}}">
                  Séroconversion pendant la grossesse
                </span>
                <br>
                <div class="row">
                  <div class="col-md-4">
                    <label for="">Type</label>
                    <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.type" class="form-control input-sm">
                  </div>
                  <div class="col-md-8">
                    <label for=""></label>
                    <input type="text" class="form-control input-sm" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.seroconversionpendantlagrossesse.valeur" style="position: relative;top: 7px;">
                    <div id="pos-div-2">SA</div>
                  </div>
                </div>
                <br>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.present" (change)="addRemoveCasParticulierNourissonEnfant('Prématurité')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.date | date:'dd/MM/yyyy, à HH:mm'}}">Prématurité</span>
                <br>
                <div class="row">
                  <div class="col-md-4">
                    <input type="text" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.valeur" class="form-control input-sm">
                    <div id="pos-div">SA</div>
                  </div>
                  <div class="col-md-8">
                    <input type="checkbox" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.voiebasse" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.voiebasse" class="radiotypeinput">Voie basse
                    <input type="checkbox" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.cesarienne" checked="!myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.prematurite.cesarienne" class="radiotypeinput">Césarienne
                  </div>
                </div>
                <br>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.present" (change)="addRemoveCasParticulierNourissonEnfant('Accouchement voie basse')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementvoiebasse.date | date:'dd/MM/yyyy, à HH:mm'}}">Accouchement voie basse</span>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.present" (change)="addRemoveCasParticulierNourissonEnfant('Accouchement césarienne')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.accouchementcesarienne.date | date:'dd/MM/yyyy, à HH:mm'}}">Accouchement césarienne</span>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.present" (change)="addRemoveCasParticulierNourissonEnfant('Faible poids de la naissance')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.date | date:'dd/MM/yyyy, à HH:mm'}}">
                  Faible poids de la naissance
                </span>
                <br>
                <div class="row">
                  <div class="col-md-4">
                    <input type="number" min="0" maxlength="250" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.faiblepoidsdelanaissance.valeur" class="form-control input-sm" value="Entrer un nombre" (change)="checkSigne($event)">
                    <div id="pos-div">g</div>
                  </div>

                </div>
                <br>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.present" (change)="addRemoveCasParticulierNourissonEnfant('Souffrance foetale aigue')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.date | date:'dd/MM/yyyy, à HH:mm'}}">Souffrance foetale aigue</span>
                <br>
                <div class="row">
                  <div class="col-md-4">
                    <input type="number" min="0" maxlength="250" class="form-control input-sm" [(ngModel)]="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.souffrancefoetaleaigue.valeur" value="Entrer un nombre" (change)="checkSigne($event)">
                  </div>
                </div>
                <br>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.present" (change)="addRemoveCasParticulierNourissonEnfant('Cas de rétinoblastorne dans la famille')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.casderetinoblastornedanslafamille.date | date:'dd/MM/yyyy, à HH:mm'}}">Cas de rétinoblastorne dans la famille</span>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.present" (change)="addRemoveCasParticulierNourissonEnfant('Retard scolaire')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.retardscolaire.date | date:'dd/MM/yyyy, à HH:mm'}}">Retard scolaire</span>
              </div>
              <div *ngIf="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.present">
                <input type="checkbox" class="radiotypeinput" checked="myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.present" (change)="addRemoveCasParticulierNourissonEnfant('Comportement de malvoyance')"> <span title="{{myExamen.interrogation.antecedentsGeneraux.casparticuliernourrissonenfant.comportementdemalvoyance.date | date:'dd/MM/yyyy, à HH:mm'}}">Comportement de malvoyance</span>
              </div>
            </div>
          </div>

          <!-- anomalie ajouté par medecin -->
          <div *ngIf="this.myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin && this.myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin.length>0">
            <ng-container *ngFor="let antecedent of this.myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin">
              <span class="select-options">{{antecedent.name}}<div class="deleted-btn" title="Supprimer" (click)="removeonantecedentsGeneraux(antecedent.name)">X</div></span>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 mb-2">
                <input type="text" class="form-control input-sm p-r-l-19" placeholder="Ajouter une description" [(ngModel)]="antecedent.description" maxlength="250" (change)="validateFields($event,'Antécédents généraux','description')" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ 0-9+\-*!=?()_.]/.test(event.key)">
              </div>
            </ng-container>
          </div>
          <br>

        </div>
        <!-- end Antécédents généraux!-->

      </div>
      <!--Antécédents familiaux ophtalmologiqu-->
      <h2 id="antFamOpht">Antécédents familiaux ophtalmologiques</h2>
      <div class="row m-b-20">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <label for="titre">Type</label>
          <ng-select [(ngModel)]="typeAnteFamiOpht" [items]="typeAntecedFamilauxList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputtypeAnteFamiOpht($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>

          <div class="all-options mt-2">
            <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelisttypeAnteFamiOpht(typeselect)">X</div></span>
          </div>
        </div>
      </div>
      <div class="row m-b-20">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <h2 id="antFamOpht">Adressé par</h2>
          <input type="text" class="form-control input-sm" placeholder="Médecin" name="medecin" autocomplete="off" [(ngModel)]="nom" (keyup)="onKey($event)" (valueChanged)="myCallback($event)" ngui-auto-complete [source]="mesMedecins" [list-formatter]="autocompleListFormatterPrenom" min-chars="4" max-num-list="10" no-match-found-text="Aucun élément">
          <span class="select-options" style="position: relative;top: 10px;" *ngIf="myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy">{{myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy.nom}} {{myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy.prenom}} {{myExamen.interrogation.antecedentsFamiliauxOphtalmologique.addressedBy.specialite}}<div class="deleted-btn" title="Supprimer" (click)="removeAdressedBy()">X</div></span>
        </div>
      </div>
      <h2>Habitudes de vie</h2>
      <div class="row m-b-20">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <ng-select [(ngModel)]="habitudeDeVie" [items]="HabitudedeVideList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" [addTag]="true" (change)="addInputhabitudeDeVie($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/.test(event.key)"></ng-select>
          <div class="all-options mt-2">
            <span class="select-options" *ngFor="let typeselect of myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie" title="{{typeselect.date | date:'dd/MM/yyyy, à HH:mm'}}">{{typeselect.name}} <div class="deleted-btn" title="Supprimer" (click)="removelisthabitudeDeVie(typeselect)">X</div></span>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center" style="border:none">
        <div class=" col d-flex justify-content-center buttonsPopup">
          <div class="col-auto nbr-dossier ">
            <table>
              <tr>
                <td class="tdButt2">
                  <button class="btn-previous" (click)="showAnteOphtaPerso()"><img src="assets/images/retour.png" class="iconBoutton1"></button>
                </td>
                <td class="tdButt1 ">
                  <button class="btn-next" (click)="saveDataInterrogatoire()"><img src="assets/images/save.png" class="iconBoutton1"></button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!--End Antécédents familiaux ophtalmologiqu-->


    </div>
    <!-- END Antécédents généraux-->

  </div>
  <!-- end Interrogatoire -->
  <!-- Examen -->
  <div class="row" *ngIf="isActiveExamen">
    <app-examen (navigateToConclusion)=clickisActiveConclusion()></app-examen>
  </div>
  <!-- end Examen -->
  <!-- Document -->
  <div class="row" *ngIf="isActiveDocuments">
    <app-document-examen></app-document-examen>
  </div>
  <!-- end document -->
  <!-- end conclusion -->
  <div class="row" *ngIf="isActiveConclusion">
    <conclusion></conclusion>
  </div>
  <!-- end conclusion -->
</div>
<!-- !SIDE BAR HISTORY-->
<app-side-bar-gauche></app-side-bar-gauche>

<!-- !END  SIDE BAR HISTORY-->
<!-- !side Bar suivi codage  -->
<!-- <app-side-bar-droit [myExamen]="myExamen"></app-side-bar-droit> -->

<div class="container demo">
  <div class="text-center" style="display: none;">
    <button type="button" class="btn btn-siderbar-left" id="btn-siderbar-left" data-toggle="modal" data-target="#exampleModalleft">
      Lancer
    </button>
  </div>
  <div class="modal left fade" id="exampleModalleft" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" style="left:0 !important;right: auto;" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Historiques des consultations générale -->
          <div *ngIf="myExamen">
            <h6 id="left-side-titel">Anomalie à l'interrogatoire</h6>
            <div *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length>0
                ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser
                ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.length>0
                ||myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux?.length>0
                || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique?.length>0
                || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique?.length>0
                ||myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present==true
                || myExamen.interrogation.antecedentsGeneraux.terrainatopique.present ==true
                ||myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true
                || myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present ==true
                ||myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present==true
                ||myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present ==true
                ||myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present ==true
                ||myExamen.interrogation.antecedentsGeneraux.traitementencours.present ==true
                ||myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0
                ||myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
              <div class="row">
                <!-- Médicaux -->
                <div>
                  <div *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length > 0">
                    <div class="row mt-2">

                      <!-- Check for oeilDroit or both eyes -->
                      <div class="col-md-4 codageElements">
                        <ng-container *ngIf="hasMedicalOeilDroit === false">None</ng-container>
                        <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                          <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                            {{ value.name }}
                          </ng-container>
                        </p>
                      </div>

                      <div class="col-md-4 titleConclusionCenter">
                        <h6>Médicaux</h6>
                      </div>

                      <!-- Check for oeilGauche or both eyes -->
                      <div class="col-md-4 codageElements">
                        <ng-container *ngIf="hasMedicalOeilGauche === false">None</ng-container>
                        <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                          <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                            {{ value.name }}
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Antécédents de traitement par laser  -->
                <div *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name ">
                  <div class="row mt-4">

                    <!-- Check for oeilDroit or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasTraitementLaserOeilDroit === false">None</ng-container>
                      <ng-container *ngIf="hasTraitementLaserOeilDroit === true">
                        {{ myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name }}
                      </ng-container>
                    </div>

                    <div class="col-md-4 titleConclusionCenter">
                      <h6>Antécédents de traitement par laser
                      </h6>
                    </div>

                    <!-- Check for oeilGauche or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasTraitementLaserOeilGauche === false">None</ng-container>
                      <ng-container *ngIf="hasTraitementLaserOeilGauche === true">
                        {{ myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name }}
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!--traitement En Cours  -->
                <div *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours?.length > 0">
                  <div class="row mt-4">

                    <!-- Check for oeilDroit or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasTraitementOeilDroit === false">None</ng-container>
                      <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                        <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                          {{ value.name }}
                        </ng-container>
                      </p>
                    </div>

                    <div class="col-md-4 titleConclusionCenter">
                      <h6>Traitement En Cours</h6>
                    </div>

                    <!-- Check for oeilGauche or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasTraitementOeilGauche === false">None</ng-container>
                      <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                        <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                          {{ value.name }}
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>

                <!--Chirurgicaux  -->
                <div *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux?.length > 0">
                  <div class="row mt-4">

                    <!-- Check for oeilDroit or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasChirugieOeilDroit === false">None</ng-container>
                      <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                        <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                          {{ value.name }}
                        </ng-container>
                      </p>
                    </div>

                    <div class="col-md-4 titleConclusionCenter">
                      <h6>Chirurgicaux</h6>
                    </div>

                    <!-- Check for oeilGauche or both eyes -->
                    <div class="col-md-4 codageElements">
                      <ng-container *ngIf="hasChirugieOeilGauche === false">None</ng-container>
                      <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                        <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                          {{ value.name }}
                        </ng-container>
                      </p>
                    </div>
                  </div>
                </div>

                <!--Correction optique  -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsophtalmologiquepersonnels && myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0 || myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                  <div class="col-md-4 codageElements" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length==0">
                    <p>None</p>
                  </div>
                  <div class="col-md-4 codageElements" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0">
                    <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique">{{value.name}}</p>
                  </div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Correction optique</h6>
                  </div>
                  <div class="col-md-4 codageElements" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length==0">
                    <p>None</p>
                  </div>
                  <div class="col-md-4 codageElements" *ngIf="myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                    <p *ngFor="let value of myExamen.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique">{{value.name}}</p>
                  </div>
                </div>

                <!--Grossesse en cours  -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.grossesseenCours.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Grossesse en cours </h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                </div>
                <!-- Terrain Atopique -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.terrainatopique.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Terrain Atopique</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainatopique.valeurs">{{value.name}}</p>
                  </div>
                </div>
                <!-- Terrain vasculaire particulier -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Terrain vasculaire particulier</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement" *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present">Diabète</p>
                    <p id="pCodageElement" *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present">HTA</p>
                    <p id="pCodageElement" *ngIf="myExamen.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">Autre</p>
                  </div>
                </div>
                <!-- Terrain d'immunodépression -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression && myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Terrain d'immunodépression</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs">{{value.name}}</p>
                  </div>
                </div>
                <!-- Maladie dysimmunitaire -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire && myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Maladie dysimmunitaire</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs">{{value.name}}</p>
                  </div>
                </div>
                <!-- Maladie neurologique -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.maladieneurologique && myExamen.interrogation.antecedentsGeneraux.maladieneurologique.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Maladie neurologique</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement" *ngFor="let value of myExamen.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">{{value.name}}</p>
                  </div>
                </div>
                <!-- Notion de vaccination récente -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente && myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Notion de vaccination récente</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement">Type : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.type}}</p>
                    <p id="pCodageElement">Date : {{myExamen.interrogation.antecedentsGeneraux.notionvaccinationrecente.date}}</p>
                  </div>
                </div>
                <!-- Notion d'anesthésie récente -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente && myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Notion d'anesthésie récente</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement">Type : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.type}}</p>
                    <p id="pCodageElement">Date : {{myExamen.interrogation.antecedentsGeneraux.notionanesthesierecente.date}}</p>
                  </div>
                </div>
                <!--Traitement général en cours  -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux && myExamen.interrogation.antecedentsGeneraux.traitementencours && myExamen.interrogation.antecedentsGeneraux.traitementencours.present">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Traitement général en cours</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div class="d-flex">
                    <p id="pCodageElement">Type : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.type}}</p>
                    <p id="pCodageElement">Dose : {{myExamen.interrogation.antecedentsGeneraux.traitementencours.dose}}</p>
                  </div>
                </div>
                <!--Traitement général ajouté par Médecin  -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsGeneraux  && myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin&&myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin.length>0">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter" *ngFor="let antec of myExamen.interrogation.antecedentsGeneraux.antecedentByMedecin">
                    <h6>{{antec.name }}</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                </div>
                <!-- Type des antécédents familiaux -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Type des antécédents familiaux</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>
                  <div>
                    <p id="pCodageElement" *ngFor="let antecedent of myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">Type : {{antecedent.name}}</p>
                  </div>
                </div>
                <!-- Medecin -->
                <div *ngIf="selectedMedecinn">
                  <p id="pCodageElement"> Adressé par Dr {{selectedMedecinn.nom}} {{selectedMedecinn.prenom}} {{selectedMedecinn.specialite}}</p>
                </div>
                <!-- Habitudes de vie -->
                <div class="row mt-4" *ngIf="myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
                  <div class="col-md-4 text-center"></div>
                  <div class="col-md-4 titleConclusionCenter">
                    <h6>Habitudes de vie</h6>
                  </div>
                  <div class="col-md-4 text-center"></div>

                  <div class="d-flex ">
                    <p id="pCodageElement" *ngFor="let antecedent of myExamen.interrogation && myExamen.interrogation.antecedentsFamiliauxOphtalmologique && myExamen.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie">{{antecedent.name}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!--!----------------------- inspection -->
            <h6 id="left-side-titel">Inspection</h6>

            <div class="row" *ngIf="receivedData.inspectionOD?.normale?.present==false||receivedData.inspectionOG?.normale?.present==false">
              <div class="col-md-4 text-center codageElements" *ngIf="receivedData.inspectionOD">
                <p *ngIf="receivedData.inspectionOD?.normale?.present">Normale</p>
                <p *ngIf="receivedData.inspectionOD?.attitudedemalvoyance?.present">Attitude de malvoyance</p>
                <p *ngIf="receivedData.inspectionOD?.buphtalmie?.present">Buphtalmie</p>
                <p *ngIf="receivedData.inspectionOD?.exphtalmie?.present">Exphtalmie</p>
                <p *ngIf="receivedData.inspectionOD?.enophtalmie?.present">Énophtalmie</p>
                <p *ngIf="receivedData.inspectionOD?.microphtalmie?.present">Microphtalmie</p>
                <p *ngIf="receivedData.inspectionOD?.leucocorie?.present">Leucocorie</p>
                <p *ngIf="receivedData.inspectionOD?.protheseoculaire?.present">Prothèse oculaire</p>
                <p *ngIf="receivedData.inspectionOD?.plaies?.present">Plaies</p>
                <p *ngIf="receivedData.inspectionOD?.paralysiefaciale?.present">Paralysie faciale</p>
                <p *ngIf="receivedData.inspectionOD?.ptosis?.present">Ptosis</p>
                <p *ngIf="receivedData.inspectionOD?.strabisme?.present">Strabisme</p>
                <p *ngFor="let anomaly of receivedData.inspectionOD?.allAnomalies">{{anomaly.titre}}</p>
              </div>
              <div class="col-md-4 titleConclusionCenter">
                <h6>Inspection</h6>
              </div>
              <div class="col-md-4 text-center codageElements" *ngIf="receivedData.inspectionOG">
                <p *ngIf="receivedData.inspectionOG?.normale?.present">Normale</p>
                <p *ngIf="receivedData.inspectionOG?.attitudedemalvoyance?.present">Attitude de malvoyance</p>
                <p *ngIf="receivedData.inspectionOG?.buphtalmie?.present">Buphtalmie</p>
                <p *ngIf="receivedData.inspectionOG?.exphtalmie?.present">Exphtalmie</p>
                <p *ngIf="receivedData.inspectionOG?.enophtalmie?.present">Énophtalmie</p>
                <p *ngIf="receivedData.inspectionOG?.microphtalmie?.present">Microphtalmie</p>
                <p *ngIf="receivedData.inspectionOG?.leucocorie?.present">Leucocorie</p>
                <p *ngIf="receivedData.inspectionOG?.protheseoculaire?.present">Prothèse oculaire</p>
                <p *ngIf="receivedData.inspectionOG?.plaies?.present">Plaies</p>
                <p *ngIf="receivedData.inspectionOG?.paralysiefaciale?.present">Paralysie faciale</p>
                <p *ngIf="receivedData.inspectionOG?.ptosis?.present">Ptosis</p>
                <p *ngIf="receivedData.inspectionOG?.strabisme?.present">Strabisme</p>
                <p *ngFor="let anomaly of receivedData.inspectionOG?.allAnomalies">{{anomaly.titre}}</p>
              </div>
            </div>

            <!--!----------------------------- Annexe -->
            <h6 id="left-side-titel">Annexe</h6>
            <div class="row" *ngIf="receivedData.annexeOD?.normale?.present==false||receivedData.annexeOG?.normale?.present==false">
              <div class="col-md-4 text-center codageElements" *ngIf="receivedData.annexeOD">
                <p *ngIf="receivedData.annexeOD?.normale?.present">Normale</p>
                <p *ngIf="receivedData.annexeOD?.chalazion?.present">Chalazion</p>
                <p *ngIf="receivedData.annexeOD?.dca?.present">DCA</p>
                <p *ngIf="receivedData.annexeOD?.dcc?.present">DCC</p>
                <p *ngIf="receivedData.annexeOD?.dermabrasion?.present">Dermabrasion</p>
                <p *ngIf="receivedData.annexeOD?.diplopie?.present">Diplopie</p>
                <p *ngIf="receivedData.annexeOD?.distichoasis?.present">Distichoasis</p>
                <p *ngIf="receivedData.annexeOD?.dgm?.present">DGM</p>
                <p *ngIf="receivedData.annexeOD?.ecoulementlacrymal?.present">Écoulement lacrymal</p>
                <p *ngIf="receivedData.annexeOD?.entropion?.present">Entropion</p>
                <p *ngIf="receivedData.annexeOD?.ectropion?.present">Ectropion</p>
                <p *ngIf="receivedData.annexeOD?.fistule?.present">Fistule</p>
                <p *ngIf="receivedData.annexeOD?.insuffisancedeconvergence?.present">Insuffisance de convergence</p>
                <p *ngIf="receivedData.annexeOD?.massedelalogelacrymal?.present">Masse de la loge lacrymal</p>
                <p *ngIf="receivedData.annexeOD?.meeibomite?.present">Meeibomite</p>
                <p *ngIf="receivedData.annexeOD?.ophtalmologie?.present">Ophtalmologie</p>
                <p *ngIf="receivedData.annexeOD?.orgelet?.present">Orgelet</p>
                <p *ngIf="receivedData.annexeOD?.paralysieocculomotrice?.present">Paralysie occulomotrice</p>
                <p *ngIf="receivedData.annexeOD?.plaie?.present">Plaie</p>
                <p *ngIf="receivedData.annexeOD?.presencedestrabisme?.present">Présence de strabisme</p>
                <p *ngIf="receivedData.annexeOD?.ptosis?.present">Ptosis</p>
                <p *ngIf="receivedData.annexeOD?.refletcorneendecentre?.present">Réflet cornéen décentré</p>
                <p *ngIf="receivedData.annexeOD?.stenoselacrymal?.present">Sténose lacrymal</p>
                <p *ngIf="receivedData.annexeOD?.sondedeDCRenplace?.present">Sonde de DCR en place</p>
                <p *ngIf="receivedData.annexeOD?.telangiectasie?.present">Telangiectasie</p>
                <p *ngIf="receivedData.annexeOD?.tumeurpaplepbrale?.present">Tumeur paplepbrale</p>
                <p *ngFor="let anomaly of receivedData.annexeOD?.allAnomalies">{{anomaly.titre}}</p>
              </div>
              <div class="col-md-4 titleConclusionCenter">
                <h6>Annexe</h6>
              </div>
              <div class="col-md-4 text-center codageElements" *ngIf="receivedData.annexeOG">
                <p *ngIf="receivedData.annexeOG?.normale?.present">Normale</p>
                <p *ngIf="receivedData.annexeOG?.chalazion?.present">Chalazion</p>
                <p *ngIf="receivedData.annexeOG?.dca?.present">DCA</p>
                <p *ngIf="receivedData.annexeOG?.dcc?.present">DCC</p>
                <p *ngIf="receivedData.annexeOG?.dermabrasion?.present">Dermabrasion</p>
                <p *ngIf="receivedData.annexeOG?.diplopie?.present">Diplopie</p>
                <p *ngIf="receivedData.annexeOG?.distichoasis?.present">Distichoasis</p>
                <p *ngIf="receivedData.annexeOG?.dgm?.present">DGM</p>
                <p *ngIf="receivedData.annexeOG?.ecoulementlacrymal?.present">Écoulement lacrymal</p>
                <p *ngIf="receivedData.annexeOG?.entropion?.present">Entropion</p>
                <p *ngIf="receivedData.annexeOG?.ectropion?.present">Ectropion</p>
                <p *ngIf="receivedData.annexeOG?.fistule?.present">Fistule</p>
                <p *ngIf="receivedData.annexeOG?.insuffisancedeconvergence?.present">Insuffisance de convergence</p>
                <p *ngIf="receivedData.annexeOG?.massedelalogelacrymal?.present">Masse de la loge lacrymal</p>
                <p *ngIf="receivedData.annexeOG?.meeibomite?.present">Meeibomite</p>
                <p *ngIf="receivedData.annexeOG?.ophtalmologie?.present">Ophtalmologie</p>
                <p *ngIf="receivedData.annexeOG?.orgelet?.present">Orgelet</p>
                <p *ngIf="receivedData.annexeOG?.paralysieocculomotrice?.present">Paralysie occulomotrice</p>
                <p *ngIf="receivedData.annexeOG?.plaie?.present">Plaie</p>
                <p *ngIf="receivedData.annexeOG?.presencedestrabisme?.present">Présence de strabisme</p>
                <p *ngIf="receivedData.annexeOG?.ptosis?.present">Ptosis</p>
                <p *ngIf="receivedData.annexeOG?.refletcorneendecentre?.present">Réflet cornéen décentré</p>
                <p *ngIf="receivedData.annexeOG?.stenoselacrymal?.present">Sténose lacrymal</p>
                <p *ngIf="receivedData.annexeOG?.sondedeDCRenplace?.present">Sonde de DCR en place</p>
                <p *ngIf="receivedData.annexeOG?.telangiectasie?.present">Telangiectasie</p>
                <p *ngIf="receivedData.annexeOG?.tumeurpaplepbrale?.present">Tumeur paplepbrale</p>
                <p *ngFor="let anomaly of receivedData.annexeOG?.allAnomalies">{{anomaly.titre}}</p>
              </div>
            </div>
            <!--!------------------------------- LAF  -->
            <h6 id="left-side-titel">LAF</h6>
            <div *ngIf="receivedData">
              <!-- Sclére -->
              <div class="row mt-3" *ngIf="receivedData.sclereOD?.blancheEtIntacte?.present==false ||receivedData.sclereOG?.blancheEtIntacte?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.sclereOD">
                  <p *ngIf="receivedData.sclereOD?.blancheEtIntacte?.present">Blanche et intacte</p>
                  <p *ngIf="receivedData.sclereOD?.episclerite?.present">Episclérite</p>
                  <p *ngIf="receivedData.sclereOD?.plaie?.present">Plaie</p>
                  <p *ngIf="receivedData.sclereOD?.sclerite?.present">Sclérite</p>
                  <p *ngIf="receivedData.sclereOD?.scleromalacie?.present"> Scléromalacie</p>
                  <p *ngFor="let anomaly of receivedData.sclereOD?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Sclère</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.sclereOG">
                  <p *ngIf="receivedData.sclereOG?.blancheEtIntacte?.present">Blanche et intacte</p>
                  <p *ngIf="receivedData.sclereOG?.episclerite?.present">Episclérite</p>
                  <p *ngIf="receivedData.sclereOG?.plaie?.present">Plaie</p>
                  <p *ngIf="receivedData.sclereOG?.sclerite?.present">Sclérite</p>
                  <p *ngIf="receivedData.sclereOG?.scleromalacie?.present"> Scléromalacie</p>
                  <p *ngFor="let anomaly of receivedData.sclereOG?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Conj-->
              <div class="row mt-3" *ngIf="receivedData.conjOD?.normocoloreEtIntacte?.present==false||receivedData.conjOG?.normocoloreEtIntacte?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.conjOD">
                  <p *ngIf="receivedData.conjOD?.normocoloreEtIntacte?.present">Normocolore et intacte </p>
                  <p *ngIf="receivedData.conjOD?.ankyloblepharon?.present"> Ankyloblepharon</p>
                  <p *ngIf="receivedData.conjOD?.cercleperikeratique?.present"> Cercle périkératique</p>
                  <p *ngIf="receivedData.conjOD?.Chemosis?.present">Chémosis</p>
                  <p *ngIf="receivedData.conjOD?.faussemembrane?.present"> Fausse membrane</p>
                  <p *ngIf="receivedData.conjOD?.fibroseconjonctival?.present">Fibrose conjonctival</p>
                  <p *ngIf="receivedData.conjOD?.follicule?.present">Follicule</p>
                  <p *ngIf="receivedData.conjOD?.granulomeconjonctivale?.present">Granulome conjonctivale</p>
                  <p *ngIf="receivedData.conjOD?.hemorragiesousconjonctival?.present">Hémorragie sous conjonctivale</p>
                  <p *ngIf="receivedData.conjOD?.hyperhemieconj?.present">Hyperhémie conjonctivale</p>
                  <p *ngIf="receivedData.conjOD?.icterique?.present">Ictérique</p>
                  <p *ngIf="receivedData.conjOD?.micropapille?.present ">Micro-papille</p>
                  <p *ngIf="receivedData.conjOD?.macropapille?.present "> Macro-papille</p>
                  <p *ngIf="receivedData.conjOD?.papillegeante?.present "> Papille géante</p>
                  <p *ngIf="receivedData.conjOD?.Pemphigoide?.present">( Conj) Pemphigoïde</p>
                  <p *ngIf="receivedData.conjOD?.plaie?.present ">Plaie</p>
                  <p *ngIf="receivedData.conjOD?.ptgStade?.present ">PTG(stade)</p>
                  <p *ngIf="receivedData.conjOD?.secretion?.present ">Sécrétion</p>
                  <p *ngIf="receivedData.conjOD?.symblepharon?.present "> Symblépharon</p>
                  <p *ngIf="receivedData.conjOD?.tumeurconjonctivale?.present"> Tumeur conjonctivale</p>
                  <p *ngFor="let anomaly of receivedData.conjOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Conj</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.conjOG">
                  <p *ngIf="receivedData.conjOG?.normocoloreEtIntacte?.present">Normocolore et intacte </p>
                  <p *ngIf="receivedData.conjOG?.ankyloblepharon?.present"> Ankyloblepharon</p>
                  <p *ngIf="receivedData.conjOG?.cercleperikeratique?.present"> Cercle périkératique</p>
                  <p *ngIf="receivedData.conjOG?.Chemosis?.present">Chémosis</p>
                  <p *ngIf="receivedData.conjOG?.faussemembrane?.present"> Fausse membrane</p>
                  <p *ngIf="receivedData.conjOG?.fibroseconjonctival?.present">Fibrose conjonctival</p>
                  <p *ngIf="receivedData.conjOG?.follicule?.present">Follicule</p>
                  <p *ngIf="receivedData.conjOG?.granulomeconjonctivale?.present">Granulome conjonctivale</p>
                  <p *ngIf="receivedData.conjOG?.hemorragiesousconjonctival?.present">Hémorragie sous conjonctivale</p>
                  <p *ngIf="receivedData.conjOG?.hyperhemieconj?.present">Hyperhémie conjonctivale</p>
                  <p *ngIf="receivedData.conjOG?.icterique?.present">Ictérique</p>
                  <p *ngIf="receivedData.conjOG?.micropapille?.present ">Micro-papille</p>
                  <p *ngIf="receivedData.conjOG?.macropapille?.present "> Macro-papille</p>
                  <p *ngIf="receivedData.conjOG?.papillegeante?.present "> Papille géante</p>
                  <p *ngIf="receivedData.conjOG?.Pemphigoide?.present">( Conj) Pemphigoïde</p>
                  <p *ngIf="receivedData.conjOG?.plaie?.present ">Plaie</p>
                  <p *ngIf="receivedData.conjOG?.ptgStade?.present ">PTG(stade)</p>
                  <p *ngIf="receivedData.conjOG?.secretion?.present ">Sécrétion</p>
                  <p *ngIf="receivedData.conjOG?.symblepharon?.present "> Symblépharon</p>
                  <p *ngIf="receivedData.conjOG?.tumeurconjonctivale?.present"> Tumeur conjonctivale</p>
                  <p *ngFor="let anomaly of receivedData.conjOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- cornee -->
              <div class="row mt-3" *ngIf="receivedData.corneeOD?.transparente?.present==false||receivedData.corneeOG?.transparente?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.corneeOD">
                  <p *ngIf="receivedData.corneeOD?.transparente?.present==true"> Transparente</p>
                  <p *ngIf="receivedData.corneeOD?.abces?.present==true"> Abcès</p>
                  <p *ngIf="receivedData.corneeOD?.anneuximmunitaire?.present==true"> Anneux immunitaire</p>
                  <p *ngIf="receivedData.corneeOD?.bueeEpitheliale?.present==true"> Buée épithéliale</p>
                  <p *ngIf="receivedData.corneeOD?.bullesousepitheliale?.present==true"> Bulle sous épithéliale</p>
                  <p *ngIf="receivedData.corneeOD?.buphtalmie?.present==true"> Buphtalmie</p>
                  <p *ngIf="receivedData.corneeOD?.corneaguttata?.present==true"> Cornea guttata</p>
                  <p *ngIf="receivedData.corneeOD?.endothelite?.present==true"> Endothélite</p>
                  <p *ngIf="receivedData.corneeOD?.epaississementepithelialeStromale?.present==true"> Épaississement épithéliale/stromale</p>
                  <p *ngIf="receivedData.corneeOD?.gerontoxon?.present==true"> Gérontoxon</p>
                  <p *ngIf="receivedData.corneeOD?.hematocornee?.present==true"> Hématocornée</p>
                  <p *ngIf="receivedData.corneeOD?.hydrops?.present==true"> Hydrops</p>
                  <p *ngIf="receivedData.corneeOD?.hypoesthesie?.present==true"> Hypoesthésie</p>
                  <p *ngIf="receivedData.corneeOD?.hypoesthesiecorneenne?.present==true"> Hypoesthésie cornéenne</p>
                  <p *ngIf="receivedData.corneeOD?.irregularitePlisdeladescemet?.present==true"> Irrégularité/plis de la descemet</p>
                  <p *ngIf="receivedData.corneeOD?.KB?.present==true">KB</p>
                  <p *ngIf="receivedData.corneeOD?.Keratopathieenbandelette?.present==true"> Kératopathie en bandelette</p>
                  <p *ngIf="receivedData.corneeOD?.KPS?.present==true"> KPS</p>
                  <p *ngIf="receivedData.corneeOD?.leucome?.present==true"> Leucome</p>
                  <p *ngIf="receivedData.corneeOD?.microkysteEpitheliaux?.present==true"> Microkyste épithéliaux</p>
                  <p *ngIf="receivedData.corneeOD?.microkystique?.present==true"> Microkystique</p>
                  <p *ngIf="receivedData.corneeOD?.neovascularisationcorneenne?.present==true"> Néovascularisation cornéenne</p>
                  <p *ngIf="receivedData.corneeOD?.NSE?.present==true"> NSE</p>
                  <p *ngIf="receivedData.corneeOD?.oedemecorneen?.present==true"> Oedème cornéen</p>
                  <p *ngIf="receivedData.corneeOD?.opaque?.present==true"> Opaque</p>
                  <p *ngIf="receivedData.corneeOD?.opacite?.present==true"> Opacité</p>
                  <p *ngIf="receivedData.corneeOD?.perforationcorneenne?.present==true"> Perforation cornéenne</p>
                  <p *ngIf="receivedData.corneeOD?.plaiescorneenne?.present==true"> Plaie cornéenne</p>
                  <p *ngIf="receivedData.corneeOD?.plaquevernale?.present==true"> Plaque vernale</p>
                  <p *ngIf="receivedData.corneeOD?.pointcorneen?.present==true"> Point cornéen</p>
                  <p *ngIf="receivedData.corneeOD?.PRD?.present==true"> PRD</p>
                  <p *ngIf="receivedData.corneeOD?.striesdevogt?.present==true"> Stries de vogt</p>
                  <p *ngIf="receivedData.corneeOD?.ulcerationcorneenne?.present==true"> Ulcération cornéenne</p>
                  <p *ngIf="receivedData.corneeOD?.visibiliteanormaledesnerfscorneen?.present==true"> Visibilitéanormale des nerfs cornéen</p>
                  <p *ngIf="receivedData.corneeOD?.ulcerevernal?.present==true"> Ulcère vernal</p>
                  <p *ngIf="receivedData.corneeOD?.noduledetrantas?.present==true"> Nodule de trantas</p>
                  <p *ngFor="let anomaly of receivedData.corneeOD?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Cornée</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.corneeOG">
                  <p *ngIf="receivedData.corneeOG?.transparente?.present==true"> Transparente</p>
                  <p *ngIf="receivedData.corneeOG?.abces?.present==true"> Abcès</p>
                  <p *ngIf="receivedData.corneeOG?.anneuximmunitaire?.present==true"> Anneux immunitaire</p>
                  <p *ngIf="receivedData.corneeOG?.bueeEpitheliale?.present==true"> Buée épithéliale</p>
                  <p *ngIf="receivedData.corneeOG?.bullesousepitheliale?.present==true"> Bulle sous épithéliale</p>
                  <p *ngIf="receivedData.corneeOG?.buphtalmie?.present==true"> Buphtalmie</p>
                  <p *ngIf="receivedData.corneeOG?.corneaguttata?.present==true"> Cornea guttata</p>
                  <p *ngIf="receivedData.corneeOG?.endothelite?.present==true"> Endothélite</p>
                  <p *ngIf="receivedData.corneeOG?.epaississementepithelialeStromale?.present==true"> Épaississement épithéliale/stromale</p>
                  <p *ngIf="receivedData.corneeOG?.gerontoxon?.present==true"> Gérontoxon</p>
                  <p *ngIf="receivedData.corneeOG?.hematocornee?.present==true"> Hématocornée</p>
                  <p *ngIf="receivedData.corneeOG?.hydrops?.present==true"> Hydrops</p>
                  <p *ngIf="receivedData.corneeOG?.hypoesthesie?.present==true"> Hypoesthésie</p>
                  <p *ngIf="receivedData.corneeOG?.hypoesthesiecorneenne?.present==true"> Hypoesthésie cornéenne</p>
                  <p *ngIf="receivedData.corneeOG?.irregularitePlisdeladescemet?.present==true"> Irrégularité/plis de la descemet</p>
                  <p *ngIf="receivedData.corneeOG?.KB?.present==true">KB</p>
                  <p *ngIf="receivedData.corneeOG?.Keratopathieenbandelette?.present==true"> Kératopathie en bandelette</p>
                  <p *ngIf="receivedData.corneeOG?.KPS?.present==true"> KPS</p>
                  <p *ngIf="receivedData.corneeOG?.leucome?.present==true"> Leucome</p>
                  <p *ngIf="receivedData.corneeOG?.microkysteEpitheliaux?.present==true"> Microkyste épithéliaux</p>
                  <p *ngIf="receivedData.corneeOG?.microkystique?.present==true"> Microkystique</p>
                  <p *ngIf="receivedData.corneeOG?.neovascularisationcorneenne?.present==true"> Néovascularisation cornéenne</p>
                  <p *ngIf="receivedData.corneeOG?.NSE?.present==true"> NSE</p>
                  <p *ngIf="receivedData.corneeOG?.oedemecorneen?.present==true"> Oedème cornéen</p>
                  <p *ngIf="receivedData.corneeOG?.opaque?.present==true"> Opaque</p>
                  <p *ngIf="receivedData.corneeOG?.opacite?.present==true"> Opacité</p>
                  <p *ngIf="receivedData.corneeOG?.perforationcorneenne?.present==true"> Perforation cornéenne</p>
                  <p *ngIf="receivedData.corneeOG?.plaiescorneenne?.present==true"> Plaie cornéenne</p>
                  <p *ngIf="receivedData.corneeOG?.plaquevernale?.present==true"> Plaque vernale</p>
                  <p *ngIf="receivedData.corneeOG?.pointcorneen?.present==true"> Point cornéen</p>
                  <p *ngIf="receivedData.corneeOG?.PRD?.present==true"> PRD</p>
                  <p *ngIf="receivedData.corneeOG?.striesdevogt?.present==true"> Stries de vogt</p>
                  <p *ngIf="receivedData.corneeOG?.ulcerationcorneenne?.present==true"> Ulcération cornéenne</p>
                  <p *ngIf="receivedData.corneeOG?.visibiliteanormaledesnerfscorneen?.present==true"> Visibilitéanormale des nerfs cornéen</p>
                  <p *ngIf="receivedData.corneeOG?.ulcerevernal?.present==true"> Ulcère vernal</p>
                  <p *ngIf="receivedData.corneeOG?.noduledetrantas?.present==true"> Nodule de trantas</p>
                  <p *ngFor="let anomaly of receivedData.corneeOG?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
              </div>
              <!-- chambre antérieur -->
              <div class="row mt-3" *ngIf="receivedData.chambreOD?.calmeEtProfonde?.present==false||receivedData.chambreOG?.calmeEtProfonde?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.chambreOD">
                  <p *ngIf="receivedData.chambreOD?.calmeEtProfonde?.present==true">Calme et profonde</p>
                  <p *ngIf="receivedData.chambreOD?.CAetroite?.present==true">CA étroite</p>
                  <p *ngIf="receivedData.chambreOD?.corpsetranger?.present==true">Corps étranger </p>
                  <p *ngIf="receivedData.chambreOD?.cyclodialyse?.present==true">Cyclodialyse</p>
                  <p *ngIf="receivedData.chambreOD?.hemorragiesousconjonctival?.present==true">Hémorragie sous conjonctivale</p>
                  <p *ngIf="receivedData.chambreOD?.hypopion?.present==true">Hypopion</p>
                  <p *ngIf="receivedData.chambreOD?.hyphema?.present==true">Hyphema </p>
                  <p *ngIf="receivedData.chambreOD?.ICA?.present==true">ICA</p>
                  <p *ngIf="receivedData.chambreOD?.IP?.present==true">IP</p>
                  <p *ngIf="receivedData.chambreOD?.massecristallinienne?.present==true">Masse cristallinienne</p>
                  <p *ngIf="receivedData.chambreOD?.mechedevitre?.present==true ">Mèche de vitre</p>
                  <p *ngIf="receivedData.chambreOD?.pigments?.present==true ">Pigments</p>
                  <p *ngIf="receivedData.chambreOD?.recession?.present==true">Récession</p>
                  <p *ngIf="receivedData.chambreOD?.signedulimbe?.present==true">Signe du limbe +</p>
                  <p *ngIf="receivedData.chambreOD?.testdevanherick?.present==true">Test de van herick</p>
                  <p *ngIf="receivedData.chambreOD?.tyndallcellulaire?.present==true">Tyndall cellulaire</p>
                  <p *ngIf="receivedData.chambreOD?.tyndallproteique?.present==true">Tyndall proteique</p>
                  <p *ngFor="let anomaly of receivedData.chambreOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Chambre antérieure</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.chambreOG">
                  <p *ngIf="receivedData.chambreOG?.calmeEtProfonde?.present==true">Calme et profonde</p>
                  <p *ngIf="receivedData.chambreOG?.CAetroite?.present==true">CA étroite</p>
                  <p *ngIf="receivedData.chambreOG?.corpsetranger?.present==true">Corps étranger </p>
                  <p *ngIf="receivedData.chambreOG?.cyclodialyse?.present==true">Cyclodialyse</p>
                  <p *ngIf="receivedData.chambreOG?.hemorragiesousconjonctival?.present==true">Hémorragie sous conjonctivale</p>
                  <p *ngIf="receivedData.chambreOG?.hypopion?.present==true">Hypopion</p>
                  <p *ngIf="receivedData.chambreOG?.hyphema?.present==true">Hyphema </p>
                  <p *ngIf="receivedData.chambreOG?.ICA?.present==true">ICA</p>
                  <p *ngIf="receivedData.chambreOG?.IP?.present==true">IP</p>
                  <p *ngIf="receivedData.chambreOG?.massecristallinienne?.present==true">Masse cristallinienne</p>
                  <p *ngIf="receivedData.chambreOG?.mechedevitre?.present==true ">Mèche de vitre</p>
                  <p *ngIf="receivedData.chambreOG?.pigments?.present==true ">Pigments</p>
                  <p *ngIf="receivedData.chambreOG?.recession?.present==true">Récession</p>
                  <p *ngIf="receivedData.chambreOG?.signedulimbe?.present==true">Signe du limbe +</p>
                  <p *ngIf="receivedData.chambreOG?.testdevanherick?.present==true">Test de van herick</p>
                  <p *ngIf="receivedData.chambreOG?.tyndallcellulaire?.present==true">Tyndall cellulaire</p>
                  <p *ngIf="receivedData.chambreOG?.tyndallproteique?.present==true">Tyndall proteique</p>
                  <p *ngFor="let anomaly of receivedData.chambreOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- IRIS -->
              <div class="row mt-3" *ngIf="receivedData.irisOD?.normale?.present==false||receivedData.irisOG?.normale?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.irisOD">
                  <p *ngIf="receivedData.irisOD?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.irisOD?.atrophieirienne?.present==true">Atrophie irienne</p>
                  <p *ngIf="receivedData.irisOD?.convexe?.present==true">Convexe</p>
                  <p *ngIf="receivedData.irisOD?.cristauxirien?.present==true">Cristaux irien</p>
                  <p *ngIf="receivedData.irisOD?.granulomeirien?.present==true">Granulome irien</p>
                  <p *ngIf="receivedData.irisOD?.heterochromie?.present==true">Hétérochromie</p>
                  <p *ngIf="receivedData.irisOD?.irisconcave?.present==true">Iris concave</p>
                  <p *ngIf="receivedData.irisOD?.melanomeMassesuspecte?.present==true">Mélanome/masse suspecte</p>
                  <p *ngIf="receivedData.irisOD?.naevusirien?.present==true">Naevus irien</p>
                  <p *ngIf="receivedData.irisOD?.noduleirien?.present==true">Nodule irien</p>
                  <p *ngIf="receivedData.irisOD?.plaieirienne?.present==true">Plaie irienne</p>
                  <p *ngIf="receivedData.irisOD?.rubeose?.present==true">Rubéose</p>
                  <p *ngIf="receivedData.irisOD?.SPEirien?.present==true">SPE irien</p>
                  <p *ngIf="receivedData.irisOD?.synechieiridocristalienne?.present==true">Synéchie irido-cristallienne</p>
                  <p *ngFor="let anomaly of receivedData.irisOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>IRIS</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.irisOG">
                  <p *ngIf="receivedData.irisOG?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.irisOG?.atrophieirienne?.present==true">Atrophie irienne</p>
                  <p *ngIf="receivedData.irisOG?.convexe?.present==true">Convexe</p>
                  <p *ngIf="receivedData.irisOG?.cristauxirien?.present==true">Cristaux irien</p>
                  <p *ngIf="receivedData.irisOG?.granulomeirien?.present==true">Granulome irien</p>
                  <p *ngIf="receivedData.irisOG?.heterochromie?.present==true">Hétérochromie</p>
                  <p *ngIf="receivedData.irisOG?.irisconcave?.present==true">Iris concave</p>
                  <p *ngIf="receivedData.irisOG?.melanomeMassesuspecte?.present==true">Mélanome/masse suspecte</p>
                  <p *ngIf="receivedData.irisOG?.naevusirien?.present==true">Naevus irien</p>
                  <p *ngIf="receivedData.irisOG?.noduleirien?.present==true">Nodule irien</p>
                  <p *ngIf="receivedData.irisOG?.plaieirienne?.present==true">Plaie irienne</p>
                  <p *ngIf="receivedData.irisOG?.rubeose?.present==true">Rubéose</p>
                  <p *ngIf="receivedData.irisOG?.SPEirien?.present==true">SPE irien</p>
                  <p *ngIf="receivedData.irisOG?.synechieiridocristalienne?.present==true">Synéchie irido-cristallienne</p>
                  <p *ngFor="let anomaly of receivedData.irisOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- RPM -->
              <div class="row mt-3" *ngIf="receivedData.rpmOD?.presentEtSymetrique?.present==false||receivedData.rpmOG?.presentEtSymetrique?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.rpmOD">
                  <p *ngIf="receivedData.rpmOD?.presentEtSymetrique?.present==true"> Présent et symétrique</p>
                  <p *ngIf="receivedData.rpmOD?.marcusgun?.present==true"> Marcus gun +</p>
                  <p *ngIf="receivedData.rpmOD?.mydriase?.present==true"> Mydriase</p>
                  <p *ngIf="receivedData.rpmOD?.myosis?.present==true"> Myosis</p>
                  <p *ngIf="receivedData.rpmOD?.semimydriase?.present==true">Semi mydriase</p>
                  <p *ngFor="let anomaly of receivedData.rpmOD?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>RPM</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.rpmOG">
                  <p *ngIf="receivedData.rpmOG?.presentEtSymetrique?.present==true"> Présent et symétrique</p>
                  <p *ngIf="receivedData.rpmOG?.marcusgun?.present==true"> Marcus gun +</p>
                  <p *ngIf="receivedData.rpmOG?.mydriase?.present==true"> Mydriase</p>
                  <p *ngIf="receivedData.rpmOG?.myosis?.present==true"> Myosis</p>
                  <p *ngIf="receivedData.rpmOG?.semimydriase?.present==true">Semi mydriase</p>
                  <p *ngFor="let anomaly of receivedData.rpmOG?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
              </div>
              <!-- dilatation -->
              <div class="row mt-3" *ngIf="receivedData.dilatationOD?.bonne?.present==false||receivedData.dilatationOG?.bonne?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.dilatationOD">
                  <p *ngIf="receivedData.dilatationOD?.bonne?.present==true">Bonne</p>
                  <p *ngIf="receivedData.dilatationOD?.mauvaise?.present==true ">Mauvaise</p>
                  <p *ngIf="receivedData.dilatationOD?.moyenne?.present==true">Moyenne</p>
                  <p *ngFor="let anomaly of receivedData?.dilatationOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Dilatation</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.dilatationOG">
                  <p *ngIf="receivedData.dilatationOG?.bonne?.present==true">Bonne</p>
                  <p *ngIf="receivedData.dilatationOG?.mauvaise?.present==true ">Mauvaise</p>
                  <p *ngIf="receivedData.dilatationOG?.moyenne?.present==true">Moyenne</p>
                  <p *ngFor="let anomaly of receivedData?.dilatationOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Cristallin -->
              <div class="row mt-3" *ngIf="receivedData.cristallinOD?.transparent?.present==false||receivedData.cristallinOG?.transparent?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.cristallinOD">
                  <p *ngIf="receivedData.cristallinOD?.transparent?.present==true">Transparent</p>
                  <p *ngIf="receivedData.cristallinOD?.aphaquie?.present==true">Aphaquie</p>
                  <p *ngIf="receivedData.cristallinOD?.capsuleantcalcifie?.present==true">Capsule antérieure calcifiée</p>
                  <p *ngIf="receivedData.cristallinOD?.capsuleantrompu?.present==true">Capsule antérieure rompue</p>
                  <p *ngIf="receivedData.cristallinOD?.cataracte?.present==true">Cataracte</p>
                  <p *ngIf="receivedData.cristallinOD?.corpsetranger?.present==true">Corps étranger</p>
                  <p *ngIf="receivedData.cristallinOD?.ectopie?.present==true">Ectopie</p>
                  <p *ngIf="receivedData.cristallinOD?.fragilitezonulaire?.present==true">Fragilité zonulaire</p>
                  <p *ngIf="receivedData.cristallinOD?.glaucomeflecken?.present==true">Glaucome flecken</p>
                  <p *ngIf="receivedData.cristallinOD?.luxationSubluxation?.present==true">Luxation/subluxation</p>
                  <p *ngIf="receivedData.cristallinOD?.microspheiohaquie?.present==true">Microspheiohaquie</p>
                  <p *ngIf="receivedData.cristallinOD?.phacosclerose?.present==true">Phacosclérose</p>
                  <p *ngIf="receivedData.cristallinOD?.phacodonesis?.present==true">Phacodonésis</p>
                  <p *ngIf="receivedData.cristallinOD?.pseudophaque?.present==true">Pseudophaque</p>
                  <p *ngIf="receivedData.cristallinOD?.SPE?.present==true">SPE</p>
                  <p *ngFor="let anomaly of receivedData.cristallinOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Cristallin</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.cristallinOG">
                  <p *ngIf="receivedData.cristallinOG?.transparent?.present==true">Transparent</p>
                  <p *ngIf="receivedData.cristallinOG?.aphaquie?.present==true">Aphaquie</p>
                  <p *ngIf="receivedData.cristallinOG?.capsuleantcalcifie?.present==true">Capsule antérieure calcifiée</p>
                  <p *ngIf="receivedData.cristallinOG?.capsuleantrompu?.present==true">Capsule antérieure rompue</p>
                  <p *ngIf="receivedData.cristallinOG?.cataracte?.present==true">Cataracte</p>
                  <p *ngIf="receivedData.cristallinOG?.corpsetranger?.present==true">Corps étranger</p>
                  <p *ngIf="receivedData.cristallinOG?.ectopie?.present==true">Ectopie</p>
                  <p *ngIf="receivedData.cristallinOG?.fragilitezonulaire?.present==true">Fragilité zonulaire</p>
                  <p *ngIf="receivedData.cristallinOG?.glaucomeflecken?.present==true">Glaucome flecken</p>
                  <p *ngIf="receivedData.cristallinOG?.luxationSubluxation?.present==true">Luxation/subluxation</p>
                  <p *ngIf="receivedData.cristallinOG?.microspheiohaquie?.present==true">Microspheiohaquie</p>
                  <p *ngIf="receivedData.cristallinOG?.phacosclerose?.present==true">Phacosclérose</p>
                  <p *ngIf="receivedData.cristallinOG?.phacodonesis?.present==true">Phacodonésis</p>
                  <p *ngIf="receivedData.cristallinOG?.pseudophaque?.present==true">Pseudophaque</p>
                  <p *ngIf="receivedData.cristallinOG?.SPE?.present==true">SPE</p>
                  <p *ngFor="let anomaly of receivedData.cristallinOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Angle Irido -->
              <div class="row mt-3" *ngIf="receivedData.angleIridoOD?.ouvertSur360?.present==false||receivedData.angleIridoOG?.ouvertSur360?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.angleIridoOD">
                  <p *ngIf="receivedData.angleIridoOD?.ouvertSur360?.present==true"> Ouvert sur 360 degrés</p>
                  <p *ngIf="receivedData.angleIridoOD?.apposition?.present==true"> Apposition</p>
                  <p *ngIf="receivedData.angleIridoOD?.corpsetranger?.present==true"> Corps étranger</p>
                  <p *ngIf="receivedData.angleIridoOD?.ferme?.present==true "> Fermé</p>
                  <p *ngIf="receivedData.angleIridoOD?.hyphema?.present==true"> Hyphema</p>
                  <p *ngIf="receivedData.angleIridoOD?.hypopion?.present==true">Hypopion</p>
                  <p *ngIf="receivedData.angleIridoOD?.occludable?.present==true"> Occludable</p>
                  <p *ngIf="receivedData.angleIridoOD?.pigmente?.present==true"> Pigmenté</p>
                  <p *ngIf="receivedData.angleIridoOD?.rubeose?.present==true"> Rubéose</p>
                  <p *ngIf="receivedData.angleIridoOD?.synechie?.present==true"> Synéchie</p>
                  <p *ngFor="let anomaly of receivedData.angleIridoOD?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Angle irido</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.angleIridoOG">
                  <p *ngIf="receivedData.angleIridoOG?.ouvertSur360?.present==true"> Ouvert sur 360 degrés</p>
                  <p *ngIf="receivedData.angleIridoOG?.apposition?.present==true"> Apposition</p>
                  <p *ngIf="receivedData.angleIridoOG?.corpsetranger?.present==true"> Corps étranger</p>
                  <p *ngIf="receivedData.angleIridoOG?.ferme?.present==true "> Fermé</p>
                  <p *ngIf="receivedData.angleIridoOG?.hyphema?.present==true"> Hyphema</p>
                  <p *ngIf="receivedData.angleIridoOG?.hypopion?.present==true">Hypopion</p>
                  <p *ngIf="receivedData.angleIridoOG?.occludable?.present==true"> Occludable</p>
                  <p *ngIf="receivedData.angleIridoOG?.pigmente?.present==true"> Pigmenté</p>
                  <p *ngIf="receivedData.angleIridoOG?.rubeose?.present==true"> Rubéose</p>
                  <p *ngIf="receivedData.angleIridoOG?.synechie?.present==true"> Synéchie</p>
                  <p *ngFor="let anomaly of receivedData.angleIridoOG?.allAnomalies"> {{anomaly.titre}}</p>
                </div>
              </div>
              <!-- vitre -->
              <div class="row mt-3" *ngIf="receivedData.vitreOD?.calmeEtAcellulaire?.present==false||receivedData.vitreOG?.calmeEtAcellulaire?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.vitreOD">
                  <p *ngIf="receivedData.vitreOD?.calmeEtAcellulaire?.present==true">Calme et acellulaire</p>
                  <p *ngIf="receivedData.vitreOD?.banquise?.present==true">Banquise</p>
                  <p *ngIf="receivedData.vitreOD?.brides?.present==true">Brides</p>
                  <p *ngIf="receivedData.vitreOD?.cordage?.present==true">Cordage</p>
                  <p *ngIf="receivedData.vitreOD?.corpsasterode?.present==true">Corps astéroïde</p>
                  <p *ngIf="receivedData.vitreOD?.corpsetrangers?.present==true">Corps étrangers</p>
                  <p *ngIf="receivedData.vitreOD?.DPV?.present==true">DPV</p>
                  <p *ngIf="receivedData.vitreOD?.essaimagetumoral?.present==true">Essaimage tumoral</p>
                  <p *ngIf="receivedData.vitreOD?.fragmentcristallinien?.present==true">Fragment cristallinien</p>
                  <p *ngIf="receivedData.vitreOD?.hematique?.present==true">Hématique</p>
                  <p *ngIf="receivedData.vitreOD?.hemorragiepreretinienne?.present==true">Hémorragie prérétinienne</p>
                  <p *ngIf="receivedData.vitreOD?.hemorragievitreene?.present==true">Hémorragie vitréene</p>
                  <p *ngIf="receivedData.vitreOD?.Lacune?.present==true">Lacune</p>
                  <p *ngIf="receivedData.vitreOD?.liquefaction?.present==true">Liquéfaction</p>
                  <p *ngIf="receivedData.vitreOD?.massecristallinienne?.present==true">Masse cristallinienne</p>
                  <p *ngIf="receivedData.vitreOD?.oeufdefourmis?.present==true">Oeuf de fourmis</p>
                  <p *ngIf="receivedData.vitreOD?.persistanceduvitreprimitif?.present==true">Persistance du vitré primitif</p>
                  <p *ngIf="receivedData.vitreOD?.pigmentaire?.present==true">Pigmentaire</p>
                  <p *ngIf="receivedData.vitreOD?.precipiteencolierdeperle?.present==true">Précipités en colier de perles</p>
                  <p *ngIf="receivedData.vitreOD?.proteique?.present==true">Protéique</p>
                  <p *ngIf="receivedData.vitreOD?.synchesisetincelant?.present==true">Synchisis étincelant</p>
                  <p *ngIf="receivedData.vitreOD?.tyndallcellulaire?.present==true">Tyndall cellulaire</p>
                  <p *ngIf="receivedData.vitreOD?.voilevitreen?.present==true">Voile vitréen</p>
                  <p *ngFor="let anomaly of receivedData.vitreOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Vitre</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.vitreOG">
                  <p *ngIf="receivedData.vitreOG?.calmeEtAcellulaire?.present==true">Calme et acellulaire</p>
                  <p *ngIf="receivedData.vitreOG?.banquise?.present==true">Banquise</p>
                  <p *ngIf="receivedData.vitreOG?.brides?.present==true">Brides</p>
                  <p *ngIf="receivedData.vitreOG?.cordage?.present==true">Cordage</p>
                  <p *ngIf="receivedData.vitreOG?.corpsasterode?.present==true">Corps astéroïde</p>
                  <p *ngIf="receivedData.vitreOG?.corpsetrangers?.present==true">Corps étrangers</p>
                  <p *ngIf="receivedData.vitreOG?.DPV?.present==true">DPV</p>
                  <p *ngIf="receivedData.vitreOG?.essaimagetumoral?.present==true">Essaimage tumoral</p>
                  <p *ngIf="receivedData.vitreOG?.fragmentcristallinien?.present==true">Fragment cristallinien</p>
                  <p *ngIf="receivedData.vitreOG?.hematique?.present==true">Hématique</p>
                  <p *ngIf="receivedData.vitreOG?.hemorragiepreretinienne?.present==true">Hémorragie prérétinienne</p>
                  <p *ngIf="receivedData.vitreOG?.hemorragievitreene?.present==true">Hémorragie vitréene</p>
                  <p *ngIf="receivedData.vitreOG?.Lacune?.present==true">Lacune</p>
                  <p *ngIf="receivedData.vitreOG?.liquefaction?.present==true">Liquéfaction</p>
                  <p *ngIf="receivedData.vitreOG?.massecristallinienne?.present==true">Masse cristallinienne</p>
                  <p *ngIf="receivedData.vitreOG?.oeufdefourmis?.present==true">Oeuf de fourmis</p>
                  <p *ngIf="receivedData.vitreOG?.persistanceduvitreprimitif?.present==true">Persistance du vitré primitif</p>
                  <p *ngIf="receivedData.vitreOG?.pigmentaire?.present==true">Pigmentaire</p>
                  <p *ngIf="receivedData.vitreOG?.precipiteencolierdeperle?.present==true">Précipités en colier de perles</p>
                  <p *ngIf="receivedData.vitreOG?.proteique?.present==true">Protéique</p>
                  <p *ngIf="receivedData.vitreOG?.synchesisetincelant?.present==true">Synchisis étincelant</p>
                  <p *ngIf="receivedData.vitreOG?.tyndallcellulaire?.present==true">Tyndall cellulaire</p>
                  <p *ngIf="receivedData.vitreOG?.voilevitreen?.present==true">Voile vitréen</p>
                  <p *ngFor="let anomaly of receivedData.vitreOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Papille -->
              <div class="row mt-3" *ngIf="receivedData.papilleOD?.normaleRapport?.present==false||receivedData.papilleOG?.normaleRapport?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.papilleOD">
                  <p *ngIf="receivedData.papilleOD?.normaleRapport?.present==true">Normale</p>
                  <p *ngIf="receivedData.papilleOD?.atrophiepupillaire?.present==true">Atrophie pupillaire</p>
                  <p *ngIf="receivedData.papilleOD?.bouclesasculairesprepapillaires?.present==true">Boucles vasculaires prépapillaires</p>
                  <p *ngIf="receivedData.papilleOD?.drusen?.present==true">Drusen</p>
                  <p *ngIf="receivedData.papilleOD?.dysversion?.present==true">Dysversion</p>
                  <p *ngIf="receivedData.papilleOD?.ElargissementdelazoneB?.present==true">Élargissement de la zone B</p>
                  <p *ngIf="receivedData.papilleOD?.Excavationpathologique?.present==true">Excavation pathologique</p>
                  <p *ngIf="receivedData.papilleOD?.ExclusiondUnvaisseauxcircumlineaire?.present==true">Exclusion d’un vaisseaux circum linéaire</p>
                  <p *ngIf="receivedData.papilleOD?.fibreamyeline?.present==true">Fibre à myéline</p>
                  <p *ngIf="receivedData.papilleOD?.fossettecolobomateuse?.present==true">Fossette colobomateuse</p>
                  <p *ngIf="receivedData.papilleOD?.hemorragieperipapillaire?.present==true">Hémorragie peripapillaire</p>
                  <p *ngIf="receivedData.papilleOD?.isntnonrespecte?.present==true">ISNT non respecté</p>
                  <p *ngIf="receivedData.papilleOD?.Morningglory?.present==true">Morning glory</p>
                  <p *ngIf="receivedData.papilleOD?.nevoxpapillaire?.present==true">Néo-vaisseaux papillaire</p>
                  <p *ngIf="receivedData.papilleOD?.oedemepapillaire?.present==true">Oedème papillaire</p>
                  <p *ngIf="receivedData.papilleOD?.papilleenfleurdeliseron?.present==true">Papille en fleur de liseron</p>
                  <p *ngIf="receivedData.papilleOD?.paleurpapillaire?.present==true">Paleur papillaire</p>
                  <p *ngIf="receivedData.papilleOD?.sriesangoide?.present==true">Stries angioïdes</p>
                  <p *ngFor="let anomaly of receivedData.papilleOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Papille</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.papilleOG">
                  <p *ngIf="receivedData.papilleOG?.normaleRapport?.present==true">Normale</p>
                  <p *ngIf="receivedData.papilleOG?.atrophiepupillaire?.present==true">Atrophie pupillaire</p>
                  <p *ngIf="receivedData.papilleOG?.bouclesasculairesprepapillaires?.present==true">Boucles vasculaires prépapillaires</p>
                  <p *ngIf="receivedData.papilleOG?.drusen?.present==true">Drusen</p>
                  <p *ngIf="receivedData.papilleOG?.dysversion?.present==true">Dysversion</p>
                  <p *ngIf="receivedData.papilleOG?.ElargissementdelazoneB?.present==true">Élargissement de la zone B</p>
                  <p *ngIf="receivedData.papilleOG?.Excavationpathologique?.present==true">Excavation pathologique</p>
                  <p *ngIf="receivedData.papilleOG?.ExclusiondUnvaisseauxcircumlineaire?.present==true">Exclusion d’un vaisseaux circum linéaire</p>
                  <p *ngIf="receivedData.papilleOG?.fibreamyeline?.present==true">Fibre à myéline</p>
                  <p *ngIf="receivedData.papilleOG?.fossettecolobomateuse?.present==true">Fossette colobomateuse</p>
                  <p *ngIf="receivedData.papilleOG?.hemorragieperipapillaire?.present==true">Hémorragie peripapillaire</p>
                  <p *ngIf="receivedData.papilleOG?.isntnonrespecte?.present==true">ISNT non respecté</p>
                  <p *ngIf="receivedData.papilleOG?.Morningglory?.present==true">Morning glory</p>
                  <p *ngIf="receivedData.papilleOG?.nevoxpapillaire?.present==true">Néo-vaisseaux papillaire</p>
                  <p *ngIf="receivedData.papilleOG?.oedemepapillaire?.present==true">Oedème papillaire</p>
                  <p *ngIf="receivedData.papilleOG?.papilleenfleurdeliseron?.present==true">Papille en fleur de liseron</p>
                  <p *ngIf="receivedData.papilleOG?.paleurpapillaire?.present==true">Paleur papillaire</p>
                  <p *ngIf="receivedData.papilleOG?.sriesangoide?.present==true">Stries angioïdes</p>
                  <p *ngFor="let anomaly of receivedData.papilleOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Macule -->
              <div class="row mt-3" *ngIf="receivedData.maculaOD?.normale?.present==false||receivedData.maculaOG?.normale?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.maculaOD">
                  <p *ngIf="receivedData.maculaOD?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.maculaOD?.aspectenoeildeboeuf?.present==true">Aspect en oeil de boeuf</p>
                  <p *ngIf="receivedData.maculaOD?.aspectjaunedoeufauplat?.present==true"> Aspect jaune d’oeuf au plat </p>
                  <p *ngIf="receivedData.maculaOD?.aspectpoivreetsel?.present==true"> Aspect poivre et sel</p>
                  <p *ngIf="receivedData.maculaOD?.couronneexcsudatif?.present==true"> Couronne exsudative</p>
                  <p *ngIf="receivedData.maculaOD?.DEP?.present==true">DEP</p>
                  <p *ngIf="receivedData.maculaOD?.dreusenmaculaire?.present==true"> Dreusen maculaire</p>
                  <p *ngIf="receivedData.maculaOD?.DSR?.present==true"> DSR</p>
                  <p *ngIf="receivedData.maculaOD?.eLogettemaculaire?.present==true">Logette maculaire</p>
                  <p *ngIf="receivedData.maculaOD?.etoilemaculaire?.present==true">Etoile maculaire</p>
                  <p *ngIf="receivedData.maculaOD?.hematomemaculaire?.present==true">Hématome maculaire</p>
                  <p *ngIf="receivedData.maculaOD?.hemorragiemaculaire?.present==true">Hémorragie maculaire</p>
                  <p *ngIf="receivedData.maculaOD?.hemorragieretrohyaloidienne?.present==true"> Hémorragie rétrohyaloïdienne</p>
                  <p *ngIf="receivedData.maculaOD?.macroanevrysme?.present==true"> Macroanévrisme</p>
                  <p *ngIf="receivedData.maculaOD?.macularougecerise?.present==true"> Macula rouge cerise</p>
                  <p *ngIf="receivedData.maculaOD?.membraneepimaculairen?.present==true"> Membrane épimaculaire</p>
                  <p *ngIf="receivedData.maculaOD?.perterefletfoveolaire?.present==true"> Perte du reflet fovéolaire</p>
                  <p *ngIf="receivedData.maculaOD?.pigmentationanormale?.present==true"> Pigmentation anormale</p>
                  <p *ngIf="receivedData.maculaOD?.plagedatrophie?.present==true">Plage d’atrophie</p>
                  <p *ngIf="receivedData.maculaOD?.remaniementmicorkystique?.present==true">Remaniement micorkystique</p>
                  <p *ngIf="receivedData.maculaOD?.trousmaculaire?.present==true">Trous maculaire</p>
                  <p *ngFor="let anomaly of receivedData.maculaOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Macula</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.maculaOG">
                  <p *ngIf="receivedData.maculaOG?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.maculaOG?.aspectenoeildeboeuf?.present==true">Aspect en oeil de boeuf</p>
                  <p *ngIf="receivedData.maculaOG?.aspectjaunedoeufauplat?.present==true"> Aspect jaune d’oeuf au plat </p>
                  <p *ngIf="receivedData.maculaOG?.aspectpoivreetsel?.present==true"> Aspect poivre et sel</p>
                  <p *ngIf="receivedData.maculaOG?.couronneexcsudatif?.present==true"> Couronne exsudative</p>
                  <p *ngIf="receivedData.maculaOG?.DEP?.present==true">DEP</p>
                  <p *ngIf="receivedData.maculaOG?.dreusenmaculaire?.present==true"> Dreusen maculaire</p>
                  <p *ngIf="receivedData.maculaOG?.DSR?.present==true"> DSR</p>
                  <p *ngIf="receivedData.maculaOG?.eLogettemaculaire?.present==true">Logette maculaire</p>
                  <p *ngIf="receivedData.maculaOG?.etoilemaculaire?.present==true">Etoile maculaire</p>
                  <p *ngIf="receivedData.maculaOG?.hematomemaculaire?.present==true">Hématome maculaire</p>
                  <p *ngIf="receivedData.maculaOG?.hemorragiemaculaire?.present==true">Hémorragie maculaire</p>
                  <p *ngIf="receivedData.maculaOG?.hemorragieretrohyaloidienne?.present==true"> Hémorragie rétrohyaloïdienne</p>
                  <p *ngIf="receivedData.maculaOG?.macroanevrysme?.present==true"> Macroanévrisme</p>
                  <p *ngIf="receivedData.maculaOG?.macularougecerise?.present==true"> Macula rouge cerise</p>
                  <p *ngIf="receivedData.maculaOG?.membraneepimaculairen?.present==true"> Membrane épimaculaire</p>
                  <p *ngIf="receivedData.maculaOG?.perterefletfoveolaire?.present==true"> Perte du reflet fovéolaire</p>
                  <p *ngIf="receivedData.maculaOG?.pigmentationanormale?.present==true"> Pigmentation anormale</p>
                  <p *ngIf="receivedData.maculaOG?.plagedatrophie?.present==true">Plage d’atrophie</p>
                  <p *ngIf="receivedData.maculaOG?.remaniementmicorkystique?.present==true">Remaniement micorkystique</p>
                  <p *ngIf="receivedData.maculaOG?.trousmaculaire?.present==true">Trous maculaire</p>
                  <p *ngFor="let anomaly of receivedData.maculaOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- anomalie vascualire -->
              <div class="row mt-3" *ngIf="receivedData.anomalieVasculaireOD?.normale?.present==false||receivedData.anomalieVasculaireOG?.normale?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.anomalieVasculaireOD">
                  <p *ngIf="receivedData.anomalieVasculaireOD?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.arteriosclerose?.present==true">Artériosclérose</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.signedeRH?.present==true"> Signe de rétinopathie hypertensive</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.signedeRD?.present==true">Signe de rétinopathie diabétique</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.signedOcclusionVeineuse?.present==true"> Signe d’Occlusion Veineuse</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.signedOcclusionARTERIELLE?.present==true">Signe d’Occlusion Artérielle</p>
                  <p *ngIf="receivedData.anomalieVasculaireOD?.vasculite?.present==true"> Vasculite</p>
                  <p *ngFor="let anomaly of receivedData.anomalieVasculaireOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Anomalie vasculaire</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.anomalieVasculaireOG">
                  <p *ngIf="receivedData.anomalieVasculaireOG?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.arteriosclerose?.present==true">Artériosclérose</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.signedeRH?.present==true"> Signe de rétinopathie hypertensive</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.signedeRD?.present==true">Signe de rétinopathie diabétique</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.signedOcclusionVeineuse?.present==true"> Signe d’Occlusion Veineuse</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.signedOcclusionARTERIELLE?.present==true">Signe d’Occlusion Artérielle</p>
                  <p *ngIf="receivedData.anomalieVasculaireOG?.vasculite?.present==true"> Vasculite</p>
                  <p *ngFor="let anomaly of receivedData.anomalieVasculaireOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
              <!-- Rétine -->
              <div class="row mt-3" *ngIf="receivedData.retineOD?.normale?.present==false||receivedData.retineOG?.normale?.present==false">
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.retineOD">
                  <p *ngIf="receivedData.retineOD?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.retineOD?.blancavecpression?.present==true"> Blanc avec pression</p>
                  <p *ngIf="receivedData.retineOD?.blancsanspression?.present==true"> Blanc sans pression</p>
                  <p *ngIf="receivedData.retineOD?.decollementchorodien?.present==true"> Décollement choroïdien</p>
                  <p *ngIf="receivedData.retineOD?.decollementretine?.present==true">Décollement de rétine</p>
                  <p *ngIf="receivedData.retineOD?.degenerescencelattice?.present==true"> Dégénérescence lattice</p>
                  <p *ngIf="receivedData.retineOD?.dehiscenceretinienne?.present==true"> Déhiscence rétinienne</p>
                  <p *ngIf="receivedData.retineOD?.DEP?.present==true"> DEP</p>
                  <p *ngIf="receivedData.retineOD?.DSR?.present==true"> DSR</p>
                  <p *ngIf="receivedData.retineOD?.exsudat?.present==true">Exsudat</p>
                  <p *ngIf="receivedData.retineOD?.foyer?.present==true">Foyer</p>
                  <p *ngIf="receivedData.retineOD?.masseretinochoroidienne?.present==true"> Masse rétino/choroïdienne</p>
                  <p *ngIf="receivedData.retineOD?.givre?.present==true"> Givre</p>
                  <p *ngIf="receivedData.retineOD?.hemorragieretinienne?.present==true"> Hémorragie rétinienne</p>
                  <p *ngIf="receivedData.retineOD?.macroanevrisme?.present==true"> Macroanévrisme</p>
                  <p *ngIf="receivedData.retineOD?.microanevrisme?.present==true"> Microanévrisme</p>
                  <p *ngIf="receivedData.retineOD?.nodulecotonneux?.present==true"> Nodule cotonneux</p>
                  <p *ngIf="receivedData.retineOD?.palissade?.present==true"> Palissade</p>
                  <p *ngIf="receivedData.retineOD?.pigmentation?.present==true">Pigmentation</p>
                  <p *ngIf="receivedData.retineOD?.retinoschisis?.present==true"> Rétinoschisis</p>
                  <p *ngIf="receivedData.retineOD?.tachederp?.present==true">Tâche de rétinite pigmentaire</p>
                  <p *ngFor="let anomaly of receivedData.retineOD?.allAnomalies">{{anomaly.titre}}</p>
                </div>
                <div class="col-md-4 titleConclusionCenter">
                  <h6>Rétine</h6>
                </div>
                <div class="col-md-4 text-center codageElements" *ngIf="receivedData.retineOG">
                  <p *ngIf="receivedData.retineOG?.normale?.present==true">Normale</p>
                  <p *ngIf="receivedData.retineOG?.blancavecpression?.present==true"> Blanc avec pression</p>
                  <p *ngIf="receivedData.retineOG?.blancsanspression?.present==true"> Blanc sans pression</p>
                  <p *ngIf="receivedData.retineOG?.decollementchorodien?.present==true"> Décollement choroïdien</p>
                  <p *ngIf="receivedData.retineOG?.decollementretine?.present==true">Décollement de rétine</p>
                  <p *ngIf="receivedData.retineOG?.degenerescencelattice?.present==true"> Dégénérescence lattice</p>
                  <p *ngIf="receivedData.retineOG?.dehiscenceretinienne?.present==true"> Déhiscence rétinienne</p>
                  <p *ngIf="receivedData.retineOG?.DEP?.present==true"> DEP</p>
                  <p *ngIf="receivedData.retineOG?.DSR?.present==true"> DSR</p>
                  <p *ngIf="receivedData.retineOG?.exsudat?.present==true">Exsudat</p>
                  <p *ngIf="receivedData.retineOG?.foyer?.present==true">Foyer</p>
                  <p *ngIf="receivedData.retineOG?.masseretinochoroidienne?.present==true"> Masse rétino/choroïdienne</p>
                  <p *ngIf="receivedData.retineOG?.givre?.present==true"> Givre</p>
                  <p *ngIf="receivedData.retineOG?.hemorragieretinienne?.present==true"> Hémorragie rétinienne</p>
                  <p *ngIf="receivedData.retineOG?.macroanevrisme?.present==true"> Macroanévrisme</p>
                  <p *ngIf="receivedData.retineOG?.microanevrisme?.present==true"> Microanévrisme</p>
                  <p *ngIf="receivedData.retineOG?.nodulecotonneux?.present==true"> Nodule cotonneux</p>
                  <p *ngIf="receivedData.retineOG?.palissade?.present==true"> Palissade</p>
                  <p *ngIf="receivedData.retineOG?.pigmentation?.present==true">Pigmentation</p>
                  <p *ngIf="receivedData.retineOG?.retinoschisis?.present==true"> Rétinoschisis</p>
                  <p *ngIf="receivedData.retineOG?.tachederp?.present==true">Tâche de rétinite pigmentaire</p>
                  <p *ngFor="let anomaly of receivedData.retineOG?.allAnomalies">{{anomaly.titre}}</p>
                </div>
              </div>
            </div>
            <!--!-------------------- end of LAF -->
            <!-- Pachymétrie -->
            <h6 id="left-side-titel">Pachymétrie</h6>
            <div *ngIf="receivedData" class="row">
              <table>
                <thead>
                  <tr [ngClass]="{'text-center' :receivedData.pachymtrieOD  && receivedData.pachymtrieOG }">
                    <th [ngClass]="{'pl-3' :receivedData.pachymtrieOD  && !receivedData.pachymtrieOG }" *ngIf="receivedData && receivedData.pachymtrieOD && receivedData.pachymtrieOD ">Oeil Droit</th>
                    <th [ngClass]="{'pl-3' :!receivedData.pachymtrieOD  && receivedData.pachymtrieOG  }" *ngIf="receivedData && receivedData.pachymtrieOG && receivedData.pachymtrieOG ">Oeil Gauche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'text-center' :receivedData.pachymtrieOD  && receivedData.pachymtrieOG }">
                    <td [ngClass]="{'pl-4' :receivedData.pachymtrieOD  && !receivedData.pachymtrieOG }" *ngIf="receivedData && receivedData.pachymtrieOD && receivedData.pachymtrieOD ">{{receivedData.pachymtrieOD + '&nbsp; μm'}}</td>
                    <td [ngClass]="{'pl-4' :!receivedData.pachymtrieOD  && receivedData.pachymtrieOG  }" *ngIf="receivedData && receivedData.pachymtrieOG && receivedData.pachymtrieOG ">{{receivedData.pachymtrieOG + ' &nbsp; μm'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- But -->
            <h6 id="left-side-titel">BUT </h6>
            <div *ngIf="receivedData" class="row">
              <table>
                <thead>
                  <tr [ngClass]="{'text-center' :receivedData.butOD > 10 && receivedData.butOG > 10}">
                    <th [ngClass]="{'pl-3' :receivedData.butOD > 10 && !receivedData.butOG }" *ngIf="receivedData && receivedData.butOD && receivedData.butOD > 10">Oeil Droit</th>
                    <th [ngClass]="{'pl-3' :!receivedData.butOD && receivedData.butOG > 10}" *ngIf="receivedData && receivedData.butOG && receivedData.butOG > 10">Oeil Gauche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'text-center' :receivedData.butOD > 10 && receivedData.butOG > 10}">
                    <td [ngClass]="{'pl-4' :receivedData.butOD > 10 && !receivedData.butOG }" *ngIf="receivedData && receivedData.butOD && receivedData.butOD > 10">{{'Instabilité lacrymale : &nbsp;' + receivedData.butOD + '&nbsp; S'}}</td>
                    <td [ngClass]="{'pl-4' :!receivedData.butOD && receivedData.butOG > 10}" *ngIf="receivedData && receivedData.butOG && receivedData.butOG > 10">{{'Instabilité lacrymale : &nbsp;' + receivedData.butOG + '&nbsp; S'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Tonus -->
            <h6 id="left-side-titel">Tonus </h6>
            <div *ngIf="receivedData" class="row">
              <table>
                <thead>
                  <tr [ngClass]="{'text-center' :receivedData.TOOD > 21 && receivedData.TOOG > 21}">
                    <th [ngClass]="{'pl-3' :receivedData.TOOD > 21 && !receivedData.TOOG}" *ngIf="receivedData && receivedData.TOOD && receivedData.TOOD > 21">Oeil Droit</th>
                    <th [ngClass]="{'pl-3' :!receivedData.TOOD && receivedData.TOOG> 21}" *ngIf="receivedData && receivedData.TOOG && receivedData.TOOG > 21">Oeil Gauche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'text-center' :receivedData.TOOD > 21 && receivedData.TOOG > 21}">
                    <td [ngClass]="{'pl-4' :receivedData.TOOD > 21 && !receivedData.TOOG}" *ngIf="receivedData && receivedData.TOOD && receivedData.TOOD > 21">{{'Hypertonie oculaire : &nbsp;' + receivedData.TOOD + '&nbsp; mmHg'}}</td>
                    <td [ngClass]="{'pl-4' :!receivedData.TOOD && receivedData.TOOG> 21}" *ngIf="receivedData && receivedData.TOOG && receivedData.TOOG > 21">{{'Hypertonie oculaire : &nbsp;' + receivedData.TOOG + '&nbsp; mmHg'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Shirmer -->
            <h6 id="left-side-titel">SHIRMER </h6>
            <div *ngIf="receivedData" class="row">
              <table>
                <thead>
                  <tr [ngClass]="{'text-center' :receivedData.shirmerOD > 5 && receivedData.shirmerOG > 5}">
                    <th [ngClass]="{'pl-3' :receivedData.shirmerOD > 5 && !receivedData.shirmerOG }" *ngIf="receivedData && receivedData.shirmerOD && receivedData.shirmerOD > 5">Oeil Droit</th>
                    <th [ngClass]="{'pl-3' :!receivedData.shirmerOD  && receivedData.shirmerOG > 5 }" *ngIf="receivedData && receivedData.shirmerOG && receivedData.shirmerOG > 5">Oeil Gauche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'text-center' :receivedData.shirmerOD > 5 && receivedData.shirmerOG > 5}">
                    <td [ngClass]="{'pl-4' :receivedData.shirmerOD > 5 && !receivedData.shirmerOG }" *ngIf="receivedData && receivedData.shirmerOD && receivedData.shirmerOD > 5">{{'Sècheresse oculaire : &nbsp;' + receivedData.shirmerOD + '&nbsp; mm'}}</td>
                    <td [ngClass]="{'pl-4' :!receivedData.shirmerOD  && receivedData.shirmerOG > 5 }" *ngIf="receivedData && receivedData.shirmerOG && receivedData.shirmerOG > 5">{{'Sècheresse oculaire : &nbsp;' + receivedData.shirmerOG + ' &nbsp; mm'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--!---------------------- Refraction -->
            <h6 id="left-side-titel">Réfraction </h6>
            <div *ngIf="receivedData" class="row">
              <table>
                <thead>
                  <tr [ngClass]="{'text-center' :(receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan'  ||receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan'|| receivedData.sansCycloCylindreDroit && receivedData.sansCycloCylindreDroit !=='Sph'  ||receivedData.avecCycloCylindreDroit&&receivedData.avecCycloCylindreDroit !=='Sph'||receivedData.rvLoinD||receivedData.addOD) &&( receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' ||receivedData.sansCycloSphereGauche&&receivedData.sansCycloSphereGauche!='Plan' ||receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph'||receivedData.avecCycloCylindreGauche && receivedData.avecCycloCylindreGauche!=='Sph'||receivedData.rvLoinG ||receivedData.addOG)}">
                    <th class="col-md-6" *ngIf="receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan'  ||receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan' ||receivedData.sansCycloCylindreDroit && receivedData.sansCycloCylindreDroit !=='Sph' ||receivedData.avecCycloCylindreDroit&&receivedData.avecCycloCylindreDroit !=='Sph'||receivedData.rvLoinD||receivedData.addOD" [ngClass]="{'pl-3': (receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan' || receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan'|| receivedData.sansCycloCylindreDroit && receivedData.sansCycloCylindreDroit !=='Sph' ||receivedData.avecCycloCylindreDroit &&receivedData.avecCycloCylindreDroit !=='Sph' || receivedData.rvLoinD||receivedData.addOD) && !(receivedData.avecCycloSphereGauche || receivedData.sansCycloSphereGauche || receivedData.sansCycloCylindreGauche || receivedData.avecCycloCylindreGauche || receivedData.rvLoinG||receivedData.addOG)}">
                      Oeil Droit</th>
                    <th class="col-md-6" *ngIf="receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' ||receivedData.sansCycloSphereGauche&&receivedData.sansCycloSphereGauche!='Plan' ||receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph'||receivedData.avecCycloCylindreGauche &&receivedData.avecCycloCylindreGauche!=='Sph'||receivedData.rvLoinG||receivedData.addOG" [ngClass]="{'pl-3': (receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' || receivedData.sansCycloSphereGauche&&receivedData.sansCycloSphereGauche!='Plan' || receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph' || receivedData.avecCycloCylindreGauche !=='Sph'|| receivedData.rvLoinG ||receivedData.addOG) && !(receivedData.avecCycloSphereDroit  || receivedData.sansCycloSphereDroit || receivedData.sansCycloCylindreDroit || receivedData.avecCycloCylindreDroit || receivedData.rvLoinD||receivedData.addOD)}">
                      Oeil Gauche</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [ngClass]="{'text-center' :(receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan'  ||receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan' ||receivedData.sansCycloCylindreDroit &&receivedData.sansCycloCylindreDroit !=='Sph' ||receivedData.avecCycloCylindreDroit&&receivedData.avecCycloCylindreDroit !=='Sph'||receivedData.rvLoinD||receivedData.addOD) &&( receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' ||receivedData.sansCycloSphereGauche&&receivedData.sansCycloSphereGauche!='Plan' ||receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph'||receivedData.avecCycloCylindreGauche &&receivedData.avecCycloCylindreGauche!=='Sph'||receivedData.rvLoinG ||receivedData.addOG)}">
                    <td class="col-md-6" *ngIf="receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan' ||receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan' ||receivedData.sansCycloCylindreDroit &&receivedData.sansCycloCylindreDroit !=='Sph' ||receivedData.avecCycloCylindreDroit&&receivedData.avecCycloCylindreDroit !=='Sph'||receivedData.rvLoinD||receivedData.addOD" [ngClass]="{'pl-4': (receivedData.avecCycloSphereDroit&&receivedData.avecCycloSphereDroit !=='Plan'||receivedData.sansCycloSphereDroit&&receivedData.sansCycloSphereDroit!='Plan' || receivedData.sansCycloCylindreDroit!=='Sph' || receivedData.avecCycloCylindreDroit !=='Sph'||receivedData.avecCycloCylindreDroit!=null||receivedData.avecCycloCylindreDroit!=null|| receivedData.rvLoinD||receivedData.addOD) && !(receivedData.avecCycloSphereGauche || receivedData.sansCycloSphereGauche || receivedData.sansCycloCylindreGauche || receivedData.avecCycloCylindreGauche || receivedData.rvLoinG||receivedData.addOG)}">
                      <!-- sans cyclo-->
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.sansCycloSphereDroit&&  receivedData.sansCycloSphereDroit !=='Plan'&& (receivedData.sansCycloSphereDroit | numeric)< 0 &&(receivedData.avecCycloSphereDroit=='Plan' || !receivedData.avecCycloSphereDroit)  ">
                        {{'Myopie : &nbsp;' + receivedData.sansCycloSphereDroit}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.sansCycloSphereDroit&&  receivedData.sansCycloSphereDroit !=='Plan'&& (receivedData.sansCycloSphereDroit | numeric)> 0 &&(receivedData.avecCycloSphereDroit=='Plan' || !receivedData.avecCycloSphereDroit) ">
                        {{'Hypermétropie : &nbsp;' +&nbsp; &nbsp; receivedData.sansCycloSphereDroit}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.sansCycloCylindreDroit&& receivedData.sansCycloCylindreDroit !=='Sph' &&(receivedData.avecCycloCylindreDroit=='Sph' || !receivedData.avecCycloCylindreDroit)  ">
                        {{'Astigmatisme : &nbsp;' +&nbsp; &nbsp; receivedData.sansCycloCylindreDroit}}
                      </p>
                      <!-- sans cyclo-->

                      <!-- avec cyclo-->
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.avecCycloSphereDroit&&  receivedData.avecCycloSphereDroit !=='Plan'&& (receivedData.avecCycloSphereDroit | numeric)< 0  ">
                        {{'Myopie : &nbsp;' + receivedData.avecCycloSphereDroit}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.avecCycloSphereDroit && receivedData.avecCycloSphereDroit !=='Plan'&& (receivedData.avecCycloSphereDroit| numeric) >0 ">
                        {{'Hypermétropie : &nbsp;' + receivedData.avecCycloSphereDroit}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.avecCycloCylindreDroit&& receivedData.avecCycloCylindreDroit !=='Sph'">
                        {{'Astigmatisme : &nbsp;' + receivedData.avecCycloCylindreDroit}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.addOD>0">
                        {{'Presbytie : &nbsp;' + receivedData.addOD}}
                      </p>
                      <!-- avec cyclo-->
                      <!-- RV -->
                      <div *ngIf="receivedData && receivedData.rvLoinD">
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinD==='R < V'">RV (loin) : Voit mieux dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinD==='R = V'">RV (loin) : Voit aussi bien dans le rouge que dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinD==='R > V'">RV (loin) : Voit mieux dans le rouge</p>
                      </div>
                      <div *ngIf="receivedData && receivedData.rvPresD">
                        <p id="pCodageElement" *ngIf="receivedData.rvPresD==='R < V'">RV (près) : Voit mieux dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvPresD==='R = V'">RV (près) : Voit aussi bien dans le rouge que dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvPresD==='R > V'">RV (près) : Voit mieux dans le rouge</p>
                      </div>
                    </td>
                    <td class="col-md-6 " *ngIf="receivedData&&receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' ||receivedData.sansCycloSphereGauche&&receivedData.sansCycloSphereGauche!='Plan' ||receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph'||receivedData.avecCycloCylindreGauche &&receivedData.avecCycloCylindreGauche!=='Sph'||receivedData.rvLoinG||receivedData.addOG" [ngClass]="{'pl-4': (receivedData&&receivedData.avecCycloSphereGauche&&receivedData.avecCycloSphereGauche!=='Plan' || receivedData.sansCycloCylindreGauche &&receivedData.sansCycloCylindreGauche!=='Sph' || receivedData.sansCycloCylindreGauche !=='Sph'|| receivedData.avecCycloCylindreGauche &&receivedData.avecCycloCylindreGauche!=='Sph' || receivedData.rvLoinG ||receivedData.addOG) && !(receivedData.avecCycloSphereDroit  || receivedData.sansCycloSphereDroit || receivedData.sansCycloCylindreDroit || receivedData.avecCycloCylindreDroit || receivedData.rvLoinD||receivedData.addOD)}">
                      <!-- sans cyclo-->
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.sansCycloSphereGauche&&  receivedData.sansCycloSphereGauche !=='Plan'&& (receivedData.sansCycloSphereGauche | numeric)< 0 &&(receivedData.avecCycloSphereGauche=='Plan' || !receivedData.avecCycloSphereGauche)">
                        {{'Myopie : &nbsp;' + receivedData.sansCycloSphereGauche}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.sansCycloSphereGauche&&  receivedData.sansCycloSphereGauche !=='Plan'&& (receivedData.sansCycloSphereGauche | numeric)> 0&&(receivedData.avecCycloSphereGauche=='Plan' || !receivedData.avecCycloSphereGauche)">
                        {{'Hypermétropie : &nbsp;' + receivedData.sansCycloSphereGauche}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData &&receivedData.sansCycloCylindreGauche&& receivedData.sansCycloCylindreGauche !=='Sph'  &&(receivedData.avecCycloCylindreGauche=='Sph' || !receivedData.avecCycloCylindreGauche)  ">
                        {{'Astigmatisme : &nbsp;' + receivedData.sansCycloCylindreGauche}}
                      </p>
                      <!-- sans cyclo-->

                      <!-- avec cyclo-->
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.avecCycloSphereGauche&&  receivedData.avecCycloSphereGauche !=='Plan'&& (receivedData.avecCycloSphereGauche | numeric)< 0  ">
                        {{'Myopie : &nbsp;' + receivedData.avecCycloSphereGauche}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.avecCycloSphereGauche&&  receivedData.avecCycloSphereGauche !=='Plan'&& (receivedData.avecCycloSphereGauche | numeric)> 0  ">
                        {{'Hypermétropie : &nbsp;' + receivedData.avecCycloSphereGauche}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData &&receivedData.avecCycloCylindreGauche&& receivedData.avecCycloCylindreGauche !=='Sph' ">
                        {{'Astigmatisme : &nbsp;' + receivedData.avecCycloCylindreGauche}}
                      </p>
                      <p id="pCodageElement" *ngIf="receivedData && receivedData.addOG>0">
                        {{'Presbytie : &nbsp;' + receivedData.addOG}}
                      </p>
                      <!-- avec cyclo-->
                      <!-- RV -->
                      <div *ngIf="receivedData && receivedData.rvLoinG">
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinG==='R < V'">RV (loin) : Voit mieux dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinG==='R = V'">RV (loin) : Voit aussi bien dans le rouge que dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvLoinG==='R > V'">RV (loin) : Voit mieux dans le rouge</p>
                      </div>
                      <div *ngIf="receivedData && receivedData.rvPresG">
                        <p id="pCodageElement" *ngIf="receivedData.rvPresG==='R < V'">RV (près) : Voit mieux dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvPresG==='R = V'">RV (près) : Voit aussi bien dans le rouge que dans le vert</p>
                        <p id="pCodageElement" *ngIf="receivedData.rvPresG==='R > V'">RV (près) : Voit mieux dans le rouge</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h6 id="left-side-titel">Conduite à tenir</h6>
            <div *ngIf="receivedData && receivedData.cat && receivedData.cat.length >0">
              <p class="ml-2 .pCodageElement" *ngFor="let cat of receivedData.cat">{{cat}}</p>
            </div>
            <h6 id="left-side-titel">Diagnostic spécifique</h6>
            <div *ngIf="receivedData && receivedData.haveSpecificDiagnostic">
              <p class="ml-2 .pCodageElement" *ngFor="let additional of  receivedData.additionalConclusion"> {{additional}}</p>
            </div>
            <h6 id="left-side-titel">Prochain rendez-vous</h6>
            <div *ngIf="receivedData" class="row">

              <div *ngIf="receivedData.nextAppointmentDuration" class="col-md-6">
                <h6 class="codageSubTitle text-center">Date</h6>
                <p class="text-center .pCodageElement" *ngIf="receivedData.nextAppointmentDuration"> {{receivedData.nextAppointmentDuration}} ==> {{receivedData.nextAppointment | date:"dd/MM/yyyy, à HH:mm"}}</p>
              </div>

              <div *ngIf="receivedData.nextMotif.length>0 " class="col-md-6">
                <h6 class="codageSubTitle text-center">Motif</h6>
                <p class="text-center .pCodageElement" *ngFor="let mot of receivedData.nextMotif">{{mot}}</p>
              </div>

            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" style="background-color: #00958a; border: 1px solid #00958a; color: white;" id="btn-siderbar-close" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- !end of side Bar recap of consulataion  -->
<!-- END LEFT SIDE BAR CODAGE-->
<div class="modal" id="fileUploadModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="min-width: 650px;">
      <div class="modal-header">
        <h5 class="modal-title">Prendre une photo</h5>
        <button type="button" class="close" data-dismiss="modal" title="Fermer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="app">
          <div class="row">
            <p *ngIf="!authorized" id="alertActiverCamera">L'accès à la caméra est bloqué. <br> Veuillez l'activer dans les paramètres de votre navigateur.</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <video #video id="video" width="280" height="280" autoplay></video>
            </div>
            <div class="col-md-6">
              <canvas #canvas id="canvas" style="border-radius: 140px;" width="280" height="280"></canvas>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button class="btn button-save" style="background: #f16c28;" *ngIf="authorized" (click)="capture()">Prendre une photo</button>
              <br>
              <button class="btn button-save" *ngIf="authorized && captures && captures.length >0" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
