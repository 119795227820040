import { Component, OnInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms'
import { ConsultationService } from '../../../../services/consultation/consultation.service';
import { PatientService } from '../../../../services/profils/patient/patient.service';
import { MedecinService } from '../../../../services/profils/medecin/medecin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FlashMessagesService } from '../../../../../module/index';
import { ValidateService } from '../../../../services/validate/validate.service';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { ImageService } from '../../../../services/image/image.service';
import { FileUploader } from 'ng2-file-upload';
declare let jsPDF;
import { RdvService } from '../../../../services/rdv/rdv.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { MedicamentsService } from '../../../../services/medicaments/medicaments.service';
import { allTitreConsultations } from '../../../pays/consultation';
import { titreConsultations } from '../../../pays/consultation';
import { Select2OptionData } from 'ng-select2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import { ExamenService } from '../../../../services/examen/examen.service';
import { _getURL } from 'config/API_CONFIG';
import { motifList } from 'src/app/dashboard/pays/motifList';
import { motifMaxLength, motifMinLength, champSpecialCaracters } from '../../../../services/constants/messages';
import { ValidationService } from 'src/app/services/validate/validateError.service';

@Component({
  selector: 'app-listconsultation',
  templateUrl: './listconsultation.component.html',
  styleUrls: ['./listconsultation.component.scss', '../../patient/list-patients/list-patients.component.css']
})
export class ListconsultationComponent implements OnInit {
  dropdownWidthPercentage: number = 50;
  public search: any = '';
  consultations: any[] = [];
  consultation: any;
  patient: any;
  medecin: any;
  image: any;
  patients: any[] = [];
  form: any;
  formPatient: any;
  formFile: any;
  examens: any[] = [];
  formRDV: any;
  age: any;
  imagePatient: any;
  interval: any;
  messages: any[] = [];
  titres: any[] = [];
  p: any;
  tra: any
  c: any;
  o: any;
  searchDoc: any
  searchCer: any;
  searchOrd: any;
  secretaire: any;
  public uploader: FileUploader = new FileUploader({ url: _getURL(API_CONFIG.image) + '/patient' });
  pathImage = _getURL("") + "data/files/imagePatient/"
  public items: any[] = [];
  private value: any = ['FLUOROURACIL'];
  completeItems: Array<string> = [];
  private disabled: boolean = false;
  @ViewChild('closeBtn') closeBtn: ElementRef;

  @ViewChild('closeBtnRDV') closeBtnRDV: ElementRef;
  showMedicaments = false;
  showMedTraitant = false;
  showDoc = false
  newMedicaments: any[] = []
  showautre = false;
  showMyContainer = 1;
  selectedConsultation: any;
  selectedMedTraitant: any;
  selectedOrdonnace: any;
  public medicamentsData: Array<any>;
  public medicamentsDataa: Array<any>;
  public medicamentsDataaa: Array<any>;
  public options: any;
  public optionss: any;
  public optionsss: any;
  startValue: any;
  showCertZoneActive = false;
  showOrdZoneActive = false;
  urlImage: any;
  styleOneAll = false
  styleOnelab = false
  styleOneVac = false
  styleOneIm = false
  screenWidth: any
  ng2slectwidh: any
  //ARC DENTAIRE CONFIGURATION
  style1 = "#dc3545";
  style2 = "bold";
  style3 = "small";
  pageSize: any = 4;
  selectedDent: any;
  foundDent: any;
  actionDate: any;
  // selectedDent: any;
  actionDescription: any;
  currentDescription: any;
  descriptionToDelete: any;
  submitted = false;
  dentiste: any;

  apci = [
    { 'id': '8', 'name': 'Affections coronariennes et leurs complications' },
    { 'id': '3', 'name': 'Affections hypophysaires' },
    { 'id': '4', 'name': 'Affections surrénaliennes' },
    { 'id': '6', 'name': 'Cardiopathies congénitales et valvulopathies' },
    { 'id': '22', 'name': 'Cirrhoses et insuffisance hépatique' },
    { 'id': '1', 'name': 'Diabète insulinodépendant ou non insulinodépendant' },
    { 'id': '2', 'name': 'Dysthyroïdies' },
    { 'id': '13', 'name': 'Epilepsie' },
    { 'id': '23', 'name': 'Glaucome' },
    { 'id': '5', 'name': 'HTA sévère' },
    { 'id': '21', 'name': 'Hépatites chroniques actives' },
    { 'id': '7', 'name': 'Insuffisance cardiaque et troubles du rythme' },
    { 'id': '11', 'name': 'Insuffisance respiratoire chronique' },
    { 'id': '16', 'name': 'Insuffisance rénale chronique' },
    { 'id': '20', 'name': 'Les maladies inflammatoires chroniques de l’intestin' },
    { 'id': '14', 'name': 'Maladie de Parkinson' },
    { 'id': '18', 'name': 'Maladies auto-immunes' },
    { 'id': '24', 'name': 'Mucoviscidose' },
    { 'id': '9', 'name': 'Phlébites' },
    { 'id': '15', 'name': 'Psychoses et névroses' },
    { 'id': '17', 'name': 'Rhumatismes inflammatoires chroniques' },
    { 'id': '12', 'name': 'Sclérose en plaques' },
    { 'id': '10', 'name': 'Tuberculose active' },
    { 'id': '19', 'name': 'Tumeurs et hémopathies malignes' }
  ];
  today: any;
  day: any;
  month: any;
  year: any;
  allExamnes: any[] = []
  listpreviousconsultations: any[] = []
  // END ARC DENTAIRE CONFIGURATION
  selectedMotifs: string[] = [];
  selectedMotif: any;
  formMotif: any;
  motifList = motifList
  isValid = false
  medecinId: any
  motifMinLength = motifMinLength
  motifMaxLength = motifMaxLength
  champSpecialCaracters = champSpecialCaracters
  constructor(private authService: AuthService, private router: Router, private flashMessage: FlashMessagesService, private consultationService: ConsultationService, private medicamentsService: MedicamentsService,
    public http: HttpClient, private formBuilder: FormBuilder, private medecinService: MedecinService,
    private activatedRoute: ActivatedRoute, private patientService: PatientService, private rdvService: RdvService, private examenService: ExamenService,
    private validateService: ValidateService, private imageService: ImageService,
    private responsiveService: ResponsiveService,
  ) {


    this.formMotif = this.formBuilder.group({
      motif: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), ValidationService.validateMotif]]
    })

  }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this.screenWidth = window.innerWidth;
    // Subscribe to screen width changes
    this.responsiveService.getScreenWidth().subscribe((width) => {
      this.screenWidth = width;
      if (this.screenWidth < 768) {
        // Handle small screens
        this.ng2slectwidh = 500
        return true;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        // Handle medium screens
        this.ng2slectwidh = 1000
        return true;
      } else {
        // Handle large screens
        this.ng2slectwidh = 1400
        return true;
      }
    });

    let params: any = this.activatedRoute.snapshot.params;
    if (localStorage.getItem('medecin')) {
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        if (!this.medecin.roles.gestionPatient) {
          this.router.navigate(['/notFound']);
          return false;
        }
        this.medecinId = this.medecin._id;
        this.getOtherDataForMedecin()
      },
        err => {
          console.log(err);
          return false;
        });
    }
    if (localStorage.getItem('secretaire')) {
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionPatient) {
          this.router.navigate(['/notFound']);;
          return false;
        }
        this.medecinId = this.secretaire.medecinId;
        this.getOtherDataForSecretaire()
      },
        err => {
          console.log(err);
          return false;
        });
    }
    this.imageService.getImagePatient(params.id).subscribe(profile => {
      this.imagePatient = profile[0];
      if (this.imagePatient) {
        this.urlImage = "https://test.medivisto.com/uploads/patient/" + this.imagePatient.name
      }
    },
      err => {
        console.log(err);
        return false;
      });


  }
  getOtherDataForMedecin() {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
      if (this.patient) {
        this.examenService.getAllExamnByPatient(this.patient._id).subscribe((data: any) => {
          if (data && data.results && data.results.length > 0) {
            this.allExamnes = data.results
          }
        },
          err => {
            return false;
          });
      }
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  getOtherDataForSecretaire() {
    let params: any = this.activatedRoute.snapshot.params;
    this.patientService.getPatientByID(params.id).subscribe(patient => {
      this.patient = patient;
    },
      err => {
        this.router.navigate(['/medecin/patients-liste']);
        return false;
      });
  }
  public selected(value: any): void {
  }
  public removed(value: any): void {
  }
  public typed(value: any): void {
  }
  public refreshValue(value: any): void {
    this.value = value;
  }
  public scrollToBottom(elem) {
    elem.scrollTop = elem.scrollHeight;
  }
  // filter des medicaments
  public searchMed(searchString: string) {
    if (searchString.length > 2) { //3 character minimum
      this.completeItems = this.items
    }
    else {
      this.completeItems = [];
    }
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0]
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  // end arc dentaire fonctionnalités
  getNameById(id: string): string {
    const match = this.apci.find(item => item.id === id);
    return match ? match.name : id;
  }
  // check previes consultations and add motif
  getPreviousConsultation(id) {
    this.selectedMotif = ""
    while (this.selectedMotifs.length > 0) {
      this.selectedMotifs.pop();
    }
    localStorage.setItem('element-to-delete', JSON.stringify(id));
    this.listpreviousconsultations = []
    this.examenService.getPreviousConsultation(id).subscribe((data: any) => {
      if (data && data.needToUpdate && data.result && data.result.results && data.result.results.length > 0) {
        this.listpreviousconsultations = data.result.results
      }
      if (data && !data.needToUpdate && data.result && data.result.results && data.result.results.length > 0 && data.result.results[0].nextMotif) {
        this.selectedMotifs = data.result.results[0].nextMotif
      }
    },
      err => {
        return false;
      });
  }

  //  motif 
  addInputMotif($event) {
    if (this.motifList.indexOf($event) < 0 && $event && $event.trim().length >= 3 && this.motifList.length < 20 && this.formMotif.valid) {
      this.motifList.push($event)
      this.selectedMotif = ""
    }
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.trim().length >= 3 && this.selectedMotifs.length < 20 && this.formMotif.valid) {
      this.selectedMotifs.push($event);
      this.selectedMotif = ""
    }
    return true
  }
  saveMotif(patient) {
    patient = this.patient
    if (this.selectedMotifs && this.selectedMotifs.length > 0) {
      localStorage.setItem("motif", JSON.stringify(this.selectedMotifs))
      const consultationBody = {
        createdByDoctor: this.medecinId,
        patientId: patient._id,
        motif: this.selectedMotifs,
      };
      this.examenService
        .ajoutExamen(consultationBody)
        .subscribe((response: any) => {
          window.open(`medecin/ajout/interrogatoire/${patient._id}/${response._id}`, '_blank')
        });
      // close modal after selecting motif
      let motifModal = $('#myModalExamen') as any
      motifModal.modal('hide')
      // 
      this.selectedMotif = undefined

    }
  }
  checkFormAndSave(i) {
    if (this.formMotif.valid || this.selectedMotifs && this.selectedMotifs.length > 0) {
      this.saveMotif(i);
    } else {
      this.formMotif.markAllAsTouched();
    }
  }
  geToUpdateConsultation() {
    let patient = this.patient
    if (this.listpreviousconsultations && this.listpreviousconsultations.length > 0) {
      window.open(`medecin/ajout/interrogatoire/${this.patient._id}/${this.listpreviousconsultations[0].id}`, '_blank')

    }
  }
  deleteSelectedMotif(event) {
    if (event && event.pointerType && event.pointerType == "mouse") {
      this.formMotif.reset()
      this.selectedMotif = undefined
      this.selectedMotifs = []
      while (this.selectedMotifs.length > 0) {
        this.selectedMotifs.pop();
      }
      let motifModal = $('#myModalExamen') as any
      motifModal.modal('hide')
    } else {
      return false
    }
  }
  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    return true;
  }
  scrollHistorique(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });

  }
  selectedConsultationFunction(consultation) {
    this.selectedConsultation = this.allExamnes.find(x => x.id == consultation.id);
  }

  //delete un consultation d'un patient
  deleteconsultation(consultation) {
    consultation = this.selectedConsultation
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    this.http.delete(_getURL(API_CONFIG.examen) + '/delete-consultation/' + consultation.id, { headers: headers }).subscribe((res: any) => {
      this.allExamnes.splice(this.allExamnes.indexOf(consultation), 1);
      let mymodal = jQuery("#deleted") as any
      mymodal.modal("toggle")
    })

  }


}

