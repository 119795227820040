import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../../services/event/event.service';
import { AuthService } from '../../../../services/auth/auth.service';
import * as $ from 'jquery';
declare var jQuery: any;
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { Router } from '@angular/router';
import { RdvService } from '../../../../services/rdv/rdv.service';
import { CalendarComponent } from 'ng-fullcalendar';
import { times } from '../../../pays/pays';
import { ExamenService } from '../../../../services/examen/examen.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import * as moment from 'moment'
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MotifService } from 'src/app/services/motif/motif.service';
import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { contactAdmin } from 'src/app/services/constants/messages';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css',]
})
export class CalendarComponentt implements OnInit {
  medecin: any;
  secretaire: any;
  titre: any;
  form: any;
  start: any;
  allExamnes: any[] = [];
  event: any;
  public search: string = '';
  interval: any;
  messages: any[] = [];
  rdvsToday: any[];
  day: any;
  month: any;
  year: any;
  showErrorDate = false;
  medecinId: any;
  eventClicked: any;
  hours = [];
  optionsTimeFermeture = [];
  allData = []
  showTitle = false;
  showErrorExistingEvents = false
  p: any;
  listpreviousconsultations: any[] = []
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  changeCalendarView(view) {
    this.calendar.fullCalendar('changeView', view);
  }
  motifList = []
  showAndHideDelete = false
  @ViewChild('closeBtnRdvAdd') closeBtnRdvAdd: ElementRef;
  @ViewChild('closeBtnRdvUpdate') closeBtnRdvUpdate: ElementRef;
  @ViewChild('closeBtnRdvDemande') closeBtnRdvDemande: ElementRef;
  patients: any[] = []
  calendarOptions: any = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    },
    minTime: '08:00',
    maxTime: '22:00',
    locale: 'fr',
    lang: 'fr',
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },
    allDayDefault: false,
    selectable: true,
    selectHelper: true,
    editable: true,
    height: 1200,
    timeFormat: 'H:mm',
    eventLimit: true, // allow "more" link when too many events
    slotDuration: '00:15:00',
    slotLabelInterval: 15,
    slotLabelFormat: 'HH:mm',
    allDaySlot: false,
    events: [],
  };
  selectedMotifs: any[] = [];
  selectedMotif: any;
  newEventToaddList: any[] = []
  copyOfAllPatients: any
  typePatient: boolean
  selectedPatient: any
  showMotif: boolean = false
  patientsArray: FormArray;
  age: any
  SearchCountryField = SearchCountryField;
  today: any
  thisDay: Date = new Date()
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  createdPatient: any
  submitted: boolean = false
  addedPatient: boolean = false
  exam: boolean = false
  rdv: boolean = false
  formRdv: any
  updateformRdv: any
  deleteAndProposeForm: any
  events: any[] = []
  searchDate: any;

  eventToDelete = false
  eventSeriesToDelete = false

  storedEventToDelete: any
  storedeventSeriesToDelete: any

  showDeleteModal: boolean = false; // Controls modal visibility
  deleteMode: 'single' | 'series' | null = null; // Tracks if deleting a single event or series
  existingMedivIds:any[]

  constructor(public iziToast: Ng2IzitoastService, private patientService: PatientService, private examenService: ExamenService, private rdvService: RdvService, private router: Router, private formBuilder: FormBuilder, private authService: AuthService,
    private eventService: EventService, private http: HttpClient, private motifService: MotifService, private consultationService: ConsultationService, private toastService: ToastService, private notificationService: NotificationService) {
    this.hours = times
    window.scrollTo(0, 0);
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      this.medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        this.getExemens()
        this.getExistingMedivIds()
        if (!this.medecin.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
        this.patientService.getPatientsByDoctor(this.medecin._id)
          .subscribe((patients: any[]) => {
            this.patients = patients;
            this.patients = this.patients.map(patient => {
              return {
                ...patient,
                dateAnniversaire: new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0], // Keep the date formatted as 'en-GB'
                fullDetails: `${patient.nom} ${patient.prenom} (${new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0]})` // Combine nom, prenom, and dateAnniversaire
              };
            })
          },
            err => {
              return false;
            });
      },
        err => {
          return false;
        });
      this.motifService.getMotifByDoctor()
        .subscribe((motifs: any[]) => {
          this.motifList = motifs;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      this.medecinId = JSON.parse(secretaireString).medecinId;
      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          return false;
        });
    }

    this.form = this.formBuilder.group({
      motif: ['', [Validators.minLength(3)]],
      patient: ['', [Validators.required]],
      patientsArray: this.formBuilder.array([]),  // Initialize FormArray for patients
    });

    this.initializeForms()

    setTimeout(() => {
      this.initiliaseCalendar()
      this.loadEvents()
    }, 500);


  }

  ngOnInit(): void {
    this.initializeDate();
    this.loadRdvs();
    this.formRdv.get('type').setValue('Professionnel');
    this.formRdv.get('timing').setValue('15');

  }

  getExistingMedivIds() {
    this.consultationService.getAllPatientsByMedecin(this.medecinId)
      .subscribe((patients: any[]) => {
        if (patients) {
          //--- getting the existing MedivID
          this.existingMedivIds = patients
          .map(el => el.identifiantMedivisto)
          .filter(id => id !== null && id !== undefined);
        }
      });
  }

  generateMedivId(identifiantMedivisto: string, existingIds: string[]): string {
    let newPatientNumber = 1; // Default to 1 if no IDs exist

    // Filter and extract valid numbers from IDs matching the same doctor
    const filteredNumbers = existingIds
        .map(id => {
            const match = id.match(new RegExp(`${identifiantMedivisto}-(\\d+)$`)); // Extract the number part
            return match ? parseInt(match[1], 10) : null; // Convert to number
        })
        .filter(num => num !== null && !isNaN(num)) as number[]; // Ensure valid numbers

    if (filteredNumbers.length > 0) {
        newPatientNumber = Math.max(...filteredNumbers) + 1; // Get the highest number and increment
    }

    return `${identifiantMedivisto}-${newPatientNumber}`;
}

  initializeForms(): void {
    this.formRdv = this.formBuilder.group({
      type: ['Professionnel', [Validators.required]],
      title: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      timeStart: ['', Validators.required],
      timing: ['15', [Validators.required]],
      timeEnd: ['', [Validators.required]],
      backgroundColor: [''],
      identifiant: ['']
    });

    this.updateformRdv = this.formBuilder.group({
      type: ['', [Validators.required]],
      title: ['', [Validators.minLength(3), Validators.maxLength(25)]],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });

    this.deleteAndProposeForm = this.formBuilder.group({
      jour: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
    });
  }
  loadEvents(): void {
    this.eventService.getAllEventsLibresByMedecin(this.medecinId).subscribe(
      (events: any[]) => {
        // Format events with ISO strings for start and end dates
        if (events.length > 0) {
          this.events = events.map(event => ({
            ...event,
            // start: new Date(event.start).toISOString(),
            // end: new Date(event.end).toISOString(),
            start: moment(event.start).format(),
            end: moment(event.end).format()

          }))
          // Set events in calendarOptions directly
          // this.events = events
          this.calendarOptions.events.push(...this.events);
          $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
          $('#calendar').fullCalendar('removeEvents');
          $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  loadRdvs(): void {
    this.rdvService.getAllRdvsByDate(this.searchDate, this.medecinId).subscribe(
      (rdvs) => {
        this.rdvsToday = rdvs;
      },
      (err) => {
        console.log(err);
        return false;
      }
    );
  }
  initializeDate(): void {
    const currentDate = new Date();
    this.day = currentDate.getDate();
    this.month = currentDate.getMonth() + 1;
    this.year = currentDate.getFullYear();
    if (this.day < 10) {
      this.day = '0' + this.day;
    }
    if (this.month < 10) {
      this.month = '0' + this.month;
    }
    this.today = this.day + '/' + this.month + '/' + this.year;
    this.searchDate = this.year + '-' + this.month + '-' + this.day;
  }

  getExemens() {
    this.examenService.getAllExamnByDoctor().subscribe((data: any) => {
      if (data && data.results) {
        this.allExamnes = data.results;
        for (var j = 0; j < this.allExamnes.length; j++) {
          this.allExamnes[j].backgroundColor = "#008000"
          this.allExamnes[j].borderColor = "#008000"
          this.allExamnes[j].textColor = "#FFFFFF"
          if (this.allExamnes[j].tags && this.allExamnes[j].tags.length > 0 && this.allExamnes[j].tags.indexOf('Prescrire une chirurgie') > -1) {
            this.allExamnes[j].backgroundColor = "#FF0000"
            this.allExamnes[j].borderColor = "#FF0000"
          }
          if (this.allExamnes[j].nextAppointment && this.allExamnes[j].nextAppointment.split("T")[0] > new Date().toISOString().split("T")[0]
          ) {
            var newEventToadd = {
              "backgroundColor": "#008000",
              "borderColor": "#008000",
              "textColor": "#FFFFFF",
              "id": this.allExamnes[j].id,
              "start": this.allExamnes[j].nextAppointment,
              "nextAppointmentDuration": this.allExamnes[j].nextAppointment,
              "nextMotif": this.allExamnes[j].nextMotif,
              "patient": this.allExamnes[j].patient,
              "type": "nextAppointment",
              "title": "Prochaine RDV"
            }
            this.newEventToaddList.push(newEventToadd)
          }
          if (this.allExamnes[j].patient)
            this.allExamnes[j].title = this.allExamnes[j].patient.nom + " " + this.allExamnes[j].patient.prenom
          this.allExamnes[j].start = new Date(this.allExamnes[j].start).toISOString()

        }

        // Helper function to filter out duplicates based on a unique event property, like 'id'
        function addUniqueEvents(eventsArray, newEvents) {
          const existingEventIds = new Set(eventsArray.map(event => event.id));
          newEvents.forEach(event => {
            if (!existingEventIds.has(event.id)) {
              eventsArray.push(event);
            }
          });
        }
        addUniqueEvents(this.calendarOptions.events, this.allExamnes);
        addUniqueEvents(this.calendarOptions.events, this.newEventToaddList);

        $('#calendar').fullCalendar('removeEvents'); // Clear existing events
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true); // Add new events


      }

    },
      err => {
        return false;
      });
  }
  chooseTypeModal(value) {
    switch (value) {
      case 'exam':
        this.exam = true
        this.rdv = false
        break;
      case 'rdv':
        this.exam = false
        this.rdv = true
        this.showTitle = false
        this.formRdv.get('type').setValue('Professionnel');
        this.formRdv.get('timing').setValue('15');
        break;

      default:
        break;
    }
    this.form.reset()

  }
  // Method to create a new patient form group
  createPatientGroup(): FormGroup {
    return this.formBuilder.group({
      nom: ['', [Validators.minLength(3), Validators.required]],
      prenom: ['', [Validators.minLength(3), Validators.required]],
      dateAnniversaire: ['', [Validators.required, ValidationService.dateValidator]],
      numFiche: ['', [Validators.minLength(1), Validators.required]],
      tel: ['', Validators.required],
      sexe: ['', Validators.required],
      identifiantMedivisto: [''],
      profession: [''],
      autreProfession: [''],
      statusFamilial: [''],
      groupeSanguin: [''],
      email: [''],
      apci: new FormArray([]),
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['',],
        email: [''],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: [''],
          codePostal: [''],
          adresse: [''],
        }),
      }),
      adresse: this.formBuilder.group({
        adresse: [''],
        ville: [''],
        pays: ['Tunisie'],
        codePostal: [''],
      })
    });
  }
  // Handle submission for "Nouveau Patient"
  submitNewPatientForm(): void {
    let identifiantMedivisto = this.medecin.identifiantMedivisto;

    const patientsArray = this.form.get('patientsArray') as FormArray;
    const patientData = patientsArray.value[0];  // Only one patient

    patientData.createdByDoctor = true
    patientData.listMedecinsId = this.medecinId;
    var today = new Date();
    var birthDate = new Date(patientData.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    patientData.age = this.age;
    if (patientData.tel) {
      patientData.tel = patientData.tel.internationalNumber;
    }
    patientData.expiredAt = null

    let medivId=this.generateMedivId(identifiantMedivisto,this.existingMedivIds)
    patientData.identifiantMedivisto=medivId

    if (this.form.get('patientsArray').valid) {
      this.consultationService.registerPatient(patientData)
        .subscribe((patient: any) => {
          if (patient && patient._id) {
            this.createdPatient = patient
            this.showMotif = true
            this.toastService.showSuccess("Bien ajouté")

            this.patientService.getPatientsByDoctor(this.medecin._id)
              .subscribe((patients: any[]) => {
                this.patients = patients;
                this.patients = this.patients.map(patient => {
                  return {
                    ...patient,
                    dateAnniversaire: new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0], // Keep the date formatted as 'en-GB'
                    fullDetails: `${patient.nom} ${patient.prenom} (${new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0]})` // Combine nom, prenom, and dateAnniversaire
                  };
                })
              },
                err => {
                  return false;
                });
            this.submitted = true
            this.addedPatient = true
            return true;
          }
          if ((!patient) || (patient && !patient._id)) {
            this.toastService.showError("Une erreur est survenue")

            this.form.get('patientsArray').reset()
            return true;
          }
        });
    }
  }

  initiliaseCalendar() {
    $('#calendar').fullCalendar('changeView', 'agendaWeek');
  }
  eventClick(calEvent) {
    if (this.medecin && calEvent && (calEvent.backgroundColor === "#008000" || calEvent.backgroundColor === "#FF0000")) {
      this.showAndHideDelete = false;
      this.showErrorDate = false;
      this.eventClicked = {
        "id": calEvent.id,
        "title": calEvent.title,
        "motif": calEvent.motif,
        "duration": calEvent.duration,
        "start": calEvent.start,
        "end": calEvent.end,
        "patient": calEvent.patient,
        "type": calEvent.type,
        "tags": calEvent.tags,
        "nextAppointmentDuration": calEvent.nextAppointment,
        "backgroundColor": calEvent.backgroundColor,
        "nextMotif": calEvent.nextMotif,
      }
      jQuery('#detailsEventModal').modal('show');
    }


    // if (calEvent &&
    //   calEvent.backgroundColor == '#039be5' ||
    //   calEvent.backgroundColor == '#23b1a5'
    // ) {
    //   this.eventClicked = {
    //     "_id": calEvent._id,
    //     "title": calEvent.title,
    //     "identifiant": calEvent.identifiant,
    //     "motif": calEvent.motif,
    //     "type": calEvent.type,
    //     "backgroundColor": calEvent.backgroundColor,
    //     "start": new Date(calEvent.start).toISOString().slice(0, 16),
    //     "end": new Date(calEvent.end).toISOString().slice(0, 16),
    //     "nom_patient": calEvent?.nom_patient,
    //     "prenom_patient": calEvent?.prenom_patient,
    //     "email": calEvent?.email,
    //     "tel": calEvent?.tel,
    //     "patientId": calEvent?.patientId,
    //   };
    //   jQuery('#detailsEventModal').modal('show');
    // }
    // if (calEvent.backgroundColor == '#F48A54') {
    //   jQuery('#detailsEventModalDemandeRDV').modal('show');
    // }


  }
  dayClick(details) {
    if (this.medecin) {
      var todayData = new Date().toISOString().split('T')[0]

      this.start = details.date.format();
      // this.showTitle = false
      // this.formRdv.get('type').setValue('Professionnel');
      // this.formRdv.get('timing').setValue('15');

      if (todayData > this.start) {
        return false;
      }

      jQuery('#createEventModal').modal('show');
      this.resetForm()
    }


  }

  saveEvent() {
    if (!this.medecin) {
      return false;
    }
    this.showErrorExistingEvents = false
    this.eventService.getAllEventsByMedecinByDateBetweenTwoDates(this.medecin._id, this.start + 'T' + this.form.value.timeStart, this.start + 'T' + this.form.value.timeEnd).subscribe((data: any) => {
      if (data && data.existevents) {
        this.showErrorExistingEvents = true
        return false;
      } else {
        //this.poursuivrecreationEvents()
        return true;
      }
    },
      err => {
        return false;
      });

  }
  saveEventRdv() {
    let identifiant =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    this.allData = [];
    this.formRdv.get('identifiant').setValue(identifiant)
    this.formRdv.value.medecinId = this.medecinId;
    this.start.includes('T') ? this.start = this.start.split('T')[0] : this.start
    if (!Array.isArray(this.calendarOptions.events)) {
      this.calendarOptions.events = [];
    }

    if (this.formRdv.value.type == 'Personnel') {
      this.formRdv.get('backgroundColor').setValue('#039be5');
      var rdvObject = {
        identifiant: this.formRdv.value.identifiant,
        start: this.start + 'T' + this.formRdv.value.timeStart,
        end: this.start + 'T' + this.formRdv.value.timeEnd,
        type: this.formRdv.value.type,
        backgroundColor: this.formRdv.value.backgroundColor,
        medecinId: this.medecinId,
        title: this.formRdv.value.title,
      };
      this.eventService.registerEvent(rdvObject).subscribe((event: any) => {
        this.calendarOptions.events.push(event);
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
        $('#calendar').fullCalendar('removeEvents');
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);

        this.toastService.showSuccess("Evenement ajouté avec succès!")
        this.showErrorDate = false;
        this.formRdv.reset();
        jQuery('#createEventModal').modal('hide');
      });
      //this.closeBtnRdvAdd.nativeElement.click();
    }
    if (this.formRdv.value.type == 'Professionnel') {
      // this.formRdv.value.backgroundColor = '#23b1a5';
      this.formRdv.get('backgroundColor').setValue('#23b1a5');
      var i = 1;
      var newOject = {
        dateDebut: moment(this.formRdv.value.timeStart, 'HH:mm')
          .add(Number(this.formRdv.value.timing) * Number(i - 1), 'minutes')
          .format('HH:mm'),
        dateFin: moment(this.formRdv.value.timeStart, 'HH:mm')
          .add(Number(this.formRdv.value.timing) * i, 'minutes')
          .format('HH:mm'),
      };

      this.formRdv.value.start = this.start + 'T' + newOject.dateDebut;
      this.formRdv.value.end = this.start + 'T' + newOject.dateFin;
      let hasExistEvent = this.calendarOptions.events.some(
        (event) => event['start'] === this.formRdv.value.start
      );
      if (!hasExistEvent) {
        this.allData.push(this.formRdv.value);
      }
      var endTime = moment(this.formRdv.value.timeEnd, 'HH:mm');
      while (moment(newOject.dateFin, 'HH:mm').isBefore(endTime)) {
        i++;
        var newOject = {
          dateDebut: moment(this.formRdv.value.timeStart, 'HH:mm')
            .add(Number(this.formRdv.value.timing) * Number(i - 1), 'minutes')
            .format('HH:mm'),
          dateFin: moment(this.formRdv.value.timeStart, 'HH:mm')
            .add(Number(this.formRdv.value.timing) * i, 'minutes')
            .format('HH:mm'),
        };
        var rdvObject = {
          identifiant: this.formRdv.value.identifiant,
          start: this.start + 'T' + newOject.dateDebut,
          end: this.start + 'T' + newOject.dateFin,
          type: this.formRdv.value.type,
          backgroundColor: this.formRdv.value.backgroundColor,
          medecinId: this.medecinId,
          title: this.formRdv.value.title,
        };

        let hasExistEvent = this.calendarOptions.events.some(
          (event) => event['start'] === rdvObject.start
        );
        if (!hasExistEvent) {
          this.allData.push(rdvObject);
        }
      }
      if (this.allData.length > 0) {
        this.eventService
          .registerEvent(this.allData)
          .subscribe((event: any[]) => {
            if (event) {
              for (var i = 0; i < event.length; i++) {
                this.calendarOptions.events.push(event[i]);
              }
              this.toastService.showSuccess("Evenement ajouté avec succès!")
            }
            $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
            $('#calendar').fullCalendar('removeEvents');
            $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
            this.showErrorDate = false;
            this.formRdv.reset();
            jQuery('#createEventModal').modal('hide');
          });

        //this.closeBtnRdvAdd.nativeElement.click();
      }
      if (this.allData.length < 1) {
        alert('Vérifier les horaires choisis');
        return false;
      }
    }
  }
  controlerTimeFermeture() {
    this.optionsTimeFermeture = []
    var numberDuration = Number(this.formRdv.value.timing)
    for (var i = 1; i < this.hours.length; i++) {
      var date = moment(this.formRdv.value.timeStart, 'HH:mm')
        .add(numberDuration * i, 'minutes')
        .format("HH:mm");
      if (this.hours.indexOf(date) >= this.hours.indexOf("23:45")) {
        return false;
      }
      if (this.optionsTimeFermeture.indexOf(date) === -1) {
        this.optionsTimeFermeture.push(date);
      }
    }
  }
  showAndHideTitle() {
    if (this.formRdv.value.type == "Personnel") {
      this.showTitle = true;
      return true;
    }
    if (this.formRdv.value.type == "Professionnel") {
      this.showTitle = false;
      return false;
    }
  }

  hideDelete() {
    this.showAndHideDelete = true;
  }
  geToUpdateConsultation(consultation) {
    window.open(`medecin/ajout/interrogatoire/${consultation.patient._id}/${consultation.id}`, '_blank')

  }
  createConsultation() {
    if (!this.medecin) {
      return false;
    }
    let consultationBody

    if (this.typePatient === false) {
      // nouveau patient
      if (!this.createdPatient || (this.selectedMotifs && this.selectedMotifs.length === 0)) {
        this.iziToast.show({
          message: 'Tous les champs sont obligatoires',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      consultationBody = {
        patientId: this.createdPatient._id,
        createdByDoctor: this.medecin._id,
        motif: this.selectedMotifs,
        start: this.start
      };
      this.examenService
        .ajoutExamen(consultationBody)
        .subscribe((response: any) => {
          this.getExemens()
          window.open(`medecin/ajout/interrogatoire/${this.createdPatient._id}/${response._id}`, '_blank')
        });
      this.submitted = false
      this.addedPatient = false
    } else {
      // ancien patient
      if (!this.form.value.patient || (this.selectedMotifs && this.selectedMotifs.length === 0)) {
        this.iziToast.show({
          message: 'Tous les champs sont obligatoires',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      let listpreviousconsultationsLength = this.listpreviousconsultations && (this.listpreviousconsultations.length > 0 && new Date(this.listpreviousconsultations[0].start.split('T')[0]).getTime() === new Date(this.start.split('T')[0]).getTime())

      switch (listpreviousconsultationsLength) {
        case true:
          window.open(`medecin/ajout/interrogatoire/${this.form.value.patient._id}/${this.listpreviousconsultations[0].id}`, '_blank')
          break;
        case false:
          consultationBody = {
            patientId: this.form.value.patient._id,
            createdByDoctor: this.medecin._id,
            motif: this.selectedMotifs,
            start: this.start
          };
          this.examenService
            .ajoutExamen(consultationBody)
            .subscribe((response: any) => {
              this.getExemens()
              window.open(`medecin/ajout/interrogatoire/${this.form.value.patient._id}/${response._id}`, '_blank')
            });
          break;

        default:
          break;
      }
    }

    // close modal after selecting motif
    jQuery('#createEventModal').modal('hide');
  }


  addInputMotif($event) {
    if (this.motifList.indexOf($event) < 0 && $event && $event.trim().length >= 3 && $event.trim().length < 100) {
      this.motifList.push($event)
      this.motifService
        .ajoutMotif({ nom: $event })
        .subscribe((response: any) => {
        });
    }
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.trim().length >= 3 && $event.trim().length < 100 && this.selectedMotifs.length < 20) {
      this.selectedMotifs.push($event);
      this.selectedMotif = ""
    }
    if (this.createdPatient) {
      this.form.value.patient = this.createdPatient
    }


    return true
  }

  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    return true;
  }

  togglePatientType(type: boolean): void {
    this.typePatient = type;
    this.form.reset()
    this.showMotif = false
    this.selectedMotifs = []
    this.submitted = false
    this.addedPatient = false


    // Clear the form and add the new patient form when "Nouveau Patient" is selected
    if (type == false) {
      let identifiantMedivisto = this.medecin.identifiantMedivisto; // Example value
      const regex = /^Mediv-M\d+$/;
      if (!identifiantMedivisto|| !regex.test(identifiantMedivisto)) {
        this.typePatient =true
        this.iziToast.show({
          message: contactAdmin,
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
      }

      const patientsArray = this.form.get('patientsArray') as FormArray;

      if (patientsArray.length === 0) {
        const newPatientGroup = this.createPatientGroup();
        patientsArray.push(newPatientGroup);

      }
    }
  }

  customSearchFn(term: string, item: any): boolean {
    term = term.toLowerCase();

    // Combine both nom and dateAnniversaire for search
    const fullName = `${item.nom} ${item.prenom}`.toLowerCase();
    const fullName2 = `${item.prenom} ${item.nom}`.toLowerCase()
    const dateAnniversaire = item.dateAnniversaire ? item.dateAnniversaire : '';

    // Check if the search term matches either the full name or dateAnniversaire
    return fullName.includes(term) || fullName2.includes(term) || dateAnniversaire.includes(term);
  }

  onPatientSelect(patient: any) {
    if (patient) {
      this.selectedPatient = patient; // Handle selected patient
      this.listpreviousconsultations = []
      while (this.selectedMotifs.length > 0) {
        this.selectedMotifs.pop();
      }
      this.examenService.getPreviousConsultation(this.selectedPatient._id).subscribe((data: any) => {
        if (data && data.needToUpdate && data.result && data.result.results && data.result.results.length > 0) {
          this.listpreviousconsultations = data.result.results
        }
        if (data && !data.needToUpdate && data.result && data.result.results && data.result.results.length > 0 && data.result.results[0].nextMotif) {
          this.selectedMotifs.push(...data.result.results[0].nextMotif)
        }
      },
        err => {
          return false;
        });
      this.showMotif = true
      this.addedPatient = true
    } else {
      this.selectedPatient = null
      this.showMotif = false
      this.addedPatient = false
    }
  }
  resetForm() {
    this.form.reset()
    this.selectedMotifs = []
    this.showMotif = false
    this.typePatient = null
    this.submitted = false
    this.addedPatient = false
    jQuery('#createEventModal').modal('hide');
  }
  updateDuration(duration: number): void {
    this.formRdv.get('timing').setValue(duration);

    document.querySelectorAll('.event-duration').forEach((button) => {
      button.classList.remove('active');
    });

    const activeButton = document.querySelector(`button[data-duration="${duration}"]`);
    if (activeButton) {
      activeButton.classList.add('active');
    }

    this.controlerTimeFermeture();
  }
  AcceptedDemande(eventClicked) {
    if (window.confirm('Voulez vous vraiment accepter cette demande ?')) {
      eventClicked.backgroundColor = '#008000';
      $('#calendar').fullCalendar('removeEvents', eventClicked._id);
      $('#calendar').fullCalendar('renderEvent', eventClicked, true);
      var notification = {
        nom_medecin: this.medecin.nom,
        prenom_medecin: this.medecin.prenom,
        accepted: true,
        texte: 'a accepté votre demande de rendez vous',
        lien: 'agenda',
        patientId: eventClicked.patientId,
      };

      var rdv = {
        nom_patient: eventClicked.nom_patient,
        prenom_patient: eventClicked.prenom_patient,
        prenom_medecin: this.medecin.prenom,
        email: eventClicked.email,
        tel: eventClicked.tel,
        date: eventClicked.start,
        motif: eventClicked.motif,
        medecinId: this.medecin._id,
        emailMedecin: this.medecin.email,
        eventId: eventClicked._id,
      };

      var rdvPatient = {
        nom_patient: eventClicked.nom_patient,
        prenom_patient: eventClicked.prenom_patient,
        prenom_medecin: this.medecin.prenom,
        nom_medecin: this.medecin.nom,
        email: eventClicked.email,
        tel: eventClicked.tel,
        date: eventClicked.start,
        motif: eventClicked.motif,
        patientId: eventClicked.patientId,
        emailMedecin: this.medecin.email,
        adresseCabinet: this.medecin.adresseCabinet,
        telMedecin: this.medecin.telFixe,
        backgroundColor: '#008000',
        eventId: eventClicked._id,
      };

      this.notificationService
        .addNotification(notification)
        .subscribe((notification) => { });
      this.rdvService.saveRDVeVENT(rdv).subscribe((rdv) => { });
      this.rdvService.saveRDVeVENT(rdvPatient).subscribe((rdv) => { });
      this.eventService.editEvent(eventClicked).subscribe((_event) => { });
      //this.closeBtnRdvDemande.nativeElement.click();
    }
  }

  refuserDemande(eventClicked) {
    if (
      window.confirm(
        'Voulez vous vraiment refuser complétement cette demande ?'
      )
    ) {
      var notification = {
        nom_medecin: this.medecin.nom,
        prenom_medecin: this.medecin.prenom,
        accepted: false,
        texte: 'a refusé votre demande de rendez vous',
        lien: 'agenda',
        patientId: eventClicked.patientId,
      };
      this.notificationService
        .addNotification(notification)
        .subscribe((notification) => { });
      var headers = new HttpHeaders();
      headers.append('Authorization', localStorage.getItem('id_token'));
      this.http
        .delete(_getURL(API_CONFIG.event) + '/' + eventClicked._id, {
          headers: headers,
        })
        .subscribe((res: any) => {
          this.calendarOptions.events.splice(
            this.calendarOptions.events.indexOf(eventClicked),
            1
          );
          $('#calendar').fullCalendar('removeEvents', eventClicked._id);
        });
      //this.closeBtnRdvDemande.nativeElement.click();
    }
  }



  proposerOtherDate(eventClicked) {
    var nowDate = moment().format('YYYY-MM-DDTHH:mm');
    var start =
      this.deleteAndProposeForm.value.jour +
      'T' +
      this.deleteAndProposeForm.value.start;
    var end =
      this.deleteAndProposeForm.value.jour +
      'T' +
      this.deleteAndProposeForm.value.end;

    if (start <= nowDate || start >= end) {
      this.showErrorDate = true;
      return false;
    }
    var notification = {
      nom_medecin: this.medecin.nom,
      prenom_medecin: this.medecin.prenom,
      accepted: false,
      texte:
        'a refusé votre demande de rendez vous.Il vous a proposé un nouveau rendez vous.Merci de vérifier votre mail',
      lien: 'agenda',
      patientId: eventClicked.patientId,
    };

    var rdvObject = {
      start: start,
      end: end,
      type: 'Professionnel',
      backgroundColor: '#23b1a5',
      medecinId: this.medecin._id,
      title: 'Prop',
      nom_patient: eventClicked.nom_patient,
      prenom_patient: eventClicked.prenom_patient,
      nom_medecin: this.medecin.nom,
      prenom_medecin: this.medecin.prenom,
      email: eventClicked.email,
      motif: eventClicked.motif,
      tel: eventClicked.tel,
    };

    this.notificationService
      .addNotification(notification)
      .subscribe((notification) => { });
    this.eventService
      .registerEventProposition(rdvObject)
      .subscribe((event: any) => {
        this.calendarOptions.events.push(event);
        $('#calendar').fullCalendar('renderEvents', event, true);
        this.deleteAndProposeForm.reset();
      });
    var headers = new HttpHeaders();
    headers.append('Authorization', localStorage.getItem('id_token'));
    this.http
      .delete(_getURL(API_CONFIG.event) + '/' + eventClicked._id, {
        headers: headers,
      })
      .subscribe((res: any) => {
        this.calendarOptions.events.splice(
          this.calendarOptions.events.indexOf(eventClicked),
          1
        );
        $('#calendar').fullCalendar('removeEvents', eventClicked._id);
      });
    //this.closeBtnRdvDemande.nativeElement.click();
  }

  // Trigger modal for single event deletion
  deleteEvent(event: any) {
    this.eventClicked = event;
    this.deleteMode = 'single';
    this.showDeleteModal = true; // Show the modal
  }

  // Trigger modal for event series deletion
  deleteEventSerie(event: any) {
    this.eventClicked = event;
    this.deleteMode = 'series';
    this.showDeleteModal = true; // Show the modal
  }

  // Confirm deletion and call the appropriate delete function
  confirmDelete() {
    if (this.deleteMode === 'single') {
      this.confirmDeleteEvent();
    } else if (this.deleteMode === 'series') {
      this.confirmDeleteEventSerie();
    }
    this.showDeleteModal = false; // Close modal after action
  }

  // Delete single event
  confirmDeleteEvent() {
    const eventId = this.eventClicked._id;

    this.eventService.deleteEvent(eventId).subscribe((res: any) => {
      const index = this.calendarOptions.events.indexOf(this.eventClicked);
      if (index > -1) {
        this.calendarOptions.events.splice(index, 1);
      }
      this.toastService.showSuccess("Bien supprimé!")
      $('#calendar').fullCalendar('removeEvents', eventId);

    });
  }

  // Delete event series
  confirmDeleteEventSerie() {
    const eventIdentifier = this.eventClicked.identifiant;
    this.eventService.deleteEventByidentifiant(eventIdentifier).subscribe((res: any) => {
      this.calendarOptions.events = this.calendarOptions.events.filter(
        (event) => event.identifiant !== eventIdentifier
      );
      this.toastService.showSuccess("Bien supprimé!")
      $('#calendar').fullCalendar('removeEvents');
      $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);


    });
  }

  // Close the modal without deleting
  closeModal() {
    this.showDeleteModal = false;
    this.deleteMode = null; // Reset mode
  }

  modifierEvent(event) {
    if (event.start >= event.end) {
      this.showErrorDate = true;
      return false;
    }
    console.log(event)
    if (event.type == 'Personnel') {
      event.backgroundColor = '#039be5';
    }
    if (event.type == 'Professionnel') {
      event.backgroundColor = '#23b1a5';
    }
    $('#calendar').fullCalendar('removeEvents', event._id);
    $('#calendar').fullCalendar('renderEvent', event, true);
    this.eventService.editEvent(event).subscribe((_event) => {
      this.event = _event;
    });
    //this.closeBtnRdvUpdate.nativeElement.click();
  }

}
