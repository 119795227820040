<div class="running-text-container">
  <p class="running-text font-text" [innerHTML]="runText"></p>
</div>

<div class="container-fluid p-sm-5 bg-light">
  <div class="row cards-row">
    <div class="animated-card">
      <div class="card-content">
        <div class="logo">
          <img src="assets/logo-white.png" alt="Logo" class="logo-img" />
        </div>
        <div class="text text-1">
          <h3 class="text-stl">{{ text1 }}</h3>
        </div>
        <div class="text text-2">
          <h3 class="text-stl">{{ text2 }}</h3>
        </div>
      </div>
    </div>

    <div class="card text-center date-card">
      <div class="card-header month">
        {{ currentMonth }}
      </div>
      <div class="card-body">
        <div class="card-content">
          <div class="card-cont-left">
            <h5 class="card-title day-of-week">{{ currentDayOfWeek }}</h5>
            <h1 class="day">{{ currentDay }}</h1>
            <h5 class="card-title year">{{ currentYear }}</h5>
          </div>
          <div class="vertical-splitter"></div>
          <div class="card-cont-right">
            <h1 class="time">{{ currentTime }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-lg-5 col-md-6 col-sm-12">
      <h4>Liste des rendez-vous du jour</h4>
    </div>
    <div class="col-lg-7 d-flex justify-content-end">
      <button type="button" class="btn btn-custom" (click)="fullscreenService.toggleFullscreen()" title="Plein écran">
        <i class="fa-solid fa-expand"></i>
      </button>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="ligne-paire text-center">
              <th scope="col" style="color: #589590"></th>
              <!-- <th scope="col" style="color: #589590">Id de Patient</th> -->
              <th scope="col" style="color: #589590">Nom & prénom</th>
              <th scope="col" style="color: #589590">Heure du rendez-vous</th>
              <th scope="col" style="color: #589590">Temps restant</th>
              <!-- <th scope="col" style="color: #589590">Patients en attente</th> -->
              <!-- <th scope="col" style="color: #589590">Statut</th> -->
            </tr>
          </thead>
          <tbody *ngFor="
              let i of rdvs
                | orderBy : 'start'
                | paginate
                  : {
                      itemsPerPage: tableSize,
                      currentPage: page,
                      totalItems: count
                    };
              let k = index
            ">
            <tr [class.critical-time]="isCriticalTime(i.resTime)" [class.done-time]="isDoneTime(i.resTime)" [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
              <td>
                <div class="rounded-circle overflow-hidden mx-auto" style="width: 30px; height: 30px">
                  <img src="assets/user.png" alt="Image" class="img-fluid" />
                </div>
              </td>
              <!-- <td>{{ i.idPatient }}</td> -->
              <td>{{ i.patient.prenom }} {{ i.patient.nom }}</td>
              <td><i class="icon fa-solid fa-clock mr-2"></i>{{ i.start }}</td>
              <td>
                <i class="icon fa-solid fa-clock mr-2"></i>{{ i.resTime }}
              </td>
              <!-- <td>{{ i.patLine }}</td> -->
              <!-- <td>
                <span class="badge badge-primary" *ngIf="i.status === 'Disponible'">{{ i.status }}</span>
                <span class="badge badge-danger" *ngIf="i.status === 'Annulé'">{{ i.status }}</span>
                <span class="badge badge-waiting-conf" *ngIf="i.status === 'En attente de confirmation'">{{ i.status }}</span>
                <span class="badge badge-custom-success" *ngIf="i.status === 'Confirmé'">{{ i.status }}</span>
                <span class="badge badge-warning" *ngIf="i.status === 'Reporté'">{{ i.status }}</span>
              </td> -->
            </tr>
          </tbody>
        </table>
        <div *ngIf="rdvs && rdvs.length > 10" class="border-0 mt-4">
          <div class="d-flex justify-content-center">
            <pagination-controls class="my-pagination" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>
        <div *ngIf="rdvs && rdvs.length == 0" class="table-responsive" style="text-align: center">
          <p style="padding-bottom: 9rem; padding-top: 9rem">
            Aucun rendez-vous n'est trouvé
          </p>
        </div>
      </div>
    </div>
  </div>
</div>