import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '../../../../services/event/event.service';
import { AuthService } from '../../../../services/auth/auth.service';
import * as $ from 'jquery';
declare var jQuery: any;
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../../../config/API_CONFIG';
import { _getURL } from '../../../../../../config/API_CONFIG';
import { Router } from '@angular/router';
import { RdvService } from '../../../../services/rdv/rdv.service';
import { CalendarComponent } from 'ng-fullcalendar';
import { times } from '../../../pays/pays';
import { ExamenService } from '../../../../services/examen/examen.service';
import { PatientService } from 'src/app/services/profils/patient/patient.service';
import * as moment from 'moment'
import { Ng2IzitoastService } from 'ng2-izitoast';
import { MotifService } from 'src/app/services/motif/motif.service';
import { ConsultationService } from 'src/app/services/consultation/consultation.service';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { ValidationService } from 'src/app/services/validate/validateError.service';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css',]
})
export class CalendarComponentt implements OnInit {

  medecin: any;
  secretaire: any;
  titre: any;
  form: any;
  start: any;
  allExamnes: any[] = [];
  event: any;
  public search: string = '';
  interval: any;
  messages: any[] = [];
  rdvsToday: any[];
  day: any;
  month: any;
  year: any;
  showErrorDate = false;
  medecinId: any;
  eventClicked: any;
  hours = [];
  optionsTimeFermeture = [];
  allData = []
  showTitle = true;
  showErrorExistingEvents = false
  p: any;
  listpreviousconsultations: any[] = []
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  changeCalendarView(view) {
    this.calendar.fullCalendar('changeView', view);
  }
  motifList = []
  showAndHideDelete = false
  @ViewChild('closeBtnRdvAdd') closeBtnRdvAdd: ElementRef;
  @ViewChild('closeBtnRdvUpdate') closeBtnRdvUpdate: ElementRef;
  @ViewChild('closeBtnRdvDemande') closeBtnRdvDemande: ElementRef;
  patients: any[] = []
  calendarOptions: any = {
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    },
    minTime: '08:00',
    maxTime: '22:00',
    locale: 'fr',
    lang: 'fr',
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'Mois',
      week: 'Semaine',
      day: 'Jour'
    },
    allDayDefault: false,
    selectable: true,
    selectHelper: true,
    editable: true,
    height: 1200,
    timeFormat: 'H:mm',
    eventLimit: true, // allow "more" link when too many events
    slotDuration: '00:15:00',
    slotLabelInterval: 15,
    slotLabelFormat: 'HH:mm',
    allDaySlot: false
  };
  selectedMotifs: any[] = [];
  selectedMotif: any;
  newEventToaddList: any[] = []
  copyOfAllPatients: any
  typePatient: boolean
  selectedPatient: any
  showMotif: boolean = false
  patientsArray: FormArray;
  age: any
  SearchCountryField = SearchCountryField;
  today = new Date()
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  createdPatient: any
  submitted: boolean = false
  addedPatient: boolean = false
  constructor(public iziToast: Ng2IzitoastService, private patientService: PatientService, private examenService: ExamenService, private rdvService: RdvService, private router: Router, private formBuilder: FormBuilder, private authService: AuthService,
    private eventService: EventService, private http: HttpClient, private motifService: MotifService, private consultationService: ConsultationService) {
    this.hours = times
    window.scrollTo(0, 0);
    if (localStorage.getItem('medecin')) {
      var medecinString = localStorage.getItem('medecin');
      this.medecinId = JSON.parse(medecinString).id;
      this.authService.getProfileMedecin().subscribe((profile: any) => {
        this.medecin = profile.medecin;
        this.getExemens()
        if (!this.medecin.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
        this.patientService.getPatientsByDoctor(this.medecin._id)
          .subscribe((patients: any[]) => {
            this.patients = patients;
            this.patients = this.patients.map(patient => {
              return {
                ...patient,
                dateAnniversaire: new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0], // Keep the date formatted as 'en-GB'
                fullDetails: `${patient.nom} ${patient.prenom} (${new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0]})` // Combine nom, prenom, and dateAnniversaire
              };
            })
          },
            err => {
              return false;
            });
      },
        err => {
          return false;
        });
      this.motifService.getMotifByDoctor()
        .subscribe((motifs: any[]) => {
          this.motifList = motifs;
        });
    }
    if (localStorage.getItem('secretaire')) {
      var secretaireString = localStorage.getItem('secretaire');
      this.medecinId = JSON.parse(secretaireString).medecinId;

      this.authService.getProfileSecretaire().subscribe((profile: any) => {
        this.secretaire = profile.secretaire;
        if (!this.secretaire.roles.gestionAgenda) {
          this.router.navigate(['/notFound']);
          return false;
        }
      },
        err => {
          return false;
        });
    }

    this.form = this.formBuilder.group({
      motif: ['', [Validators.minLength(3)]],
      patient: ['', [Validators.required]],
      patientsArray: this.formBuilder.array([]),  // Initialize FormArray for patients
    });
    setTimeout(() => {
      this.initiliaseCalendar()
    }, 500);

  }

  ngOnInit(): void {
  }
  getExemens() {
    this.examenService.getAllExamnByDoctor().subscribe((data: any) => {
      if (data && data.results) {
        this.allExamnes = data.results;
        for (var j = 0; j < this.allExamnes.length; j++) {
          this.allExamnes[j].backgroundColor = "#008000"
          this.allExamnes[j].borderColor = "#008000"
          this.allExamnes[j].textColor = "#FFFFFF"
          if (this.allExamnes[j].tags && this.allExamnes[j].tags.length > 0 && this.allExamnes[j].tags.indexOf('Prescrire une chirurgie') > -1) {
            this.allExamnes[j].backgroundColor = "#FF0000"
            this.allExamnes[j].borderColor = "#FF0000"
          }
          if (this.allExamnes[j].nextAppointment && this.allExamnes[j].nextAppointment.split("T")[0] > new Date().toISOString().split("T")[0]
          ) {
            var newEventToadd = {
              "backgroundColor": "#008000",
              "borderColor": "#008000",
              "textColor": "#FFFFFF",
              "id": this.allExamnes[j].id,
              "start": this.allExamnes[j].nextAppointment,
              "nextAppointmentDuration": this.allExamnes[j].nextAppointment,
              "nextMotif": this.allExamnes[j].nextMotif,
              "patient": this.allExamnes[j].patient,
              "type": "nextAppointment",
              "title": "Prochaine RDV"
            }
            this.newEventToaddList.push(newEventToadd)
          }
          if (this.allExamnes[j].patient)
            this.allExamnes[j].title = this.allExamnes[j].patient.nom + " " + this.allExamnes[j].patient.prenom
          this.allExamnes[j].start = new Date(this.allExamnes[j].start).toISOString()

        }

        this.calendarOptions.events = this.allExamnes
        this.calendarOptions.events.push(...this.newEventToaddList)
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);
        $('#calendar').fullCalendar('removeEvents');
        $('#calendar').fullCalendar('renderEvents', this.calendarOptions.events, true);


      }
    },
      err => {
        return false;
      });
  }
  // Method to create a new patient form group
  createPatientGroup(): FormGroup {
    return this.formBuilder.group({
      nom: ['', [Validators.minLength(3), Validators.required]],
      prenom: ['', [Validators.minLength(3), Validators.required]],
      dateAnniversaire: ['', [Validators.required, ValidationService.dateValidator]],
      numFiche: ['', [Validators.minLength(1), Validators.required]],
      tel: ['', Validators.required],
      sexe: ['', Validators.required],
      identifiantMedivisto: [''],
      profession: [''],
      autreProfession: [''],
      statusFamilial: [''],
      groupeSanguin: [''],
      email: [''],
      apci: new FormArray([]),
      orgSante: this.formBuilder.group({
        typeOrg: [''],
        nomEtablissement: ['',],
        email: [''],
        tel: ['+216'],
        adresse: this.formBuilder.group({
          ville: [''],
          codePostal: [''],
          adresse: [''],
        }),
      }),
      adresse: this.formBuilder.group({
        adresse: [''],
        ville: [''],
        pays: ['Tunisie'],
        codePostal: [''],
      })
    });
  }
  // Handle submission for "Nouveau Patient"
  submitNewPatientForm(): void {
    const patientsArray = this.form.get('patientsArray') as FormArray;
    const patientData = patientsArray.value[0];  // Only one patient

    patientData.createdByDoctor = true
    patientData.listMedecinsId = this.medecinId;
    var today = new Date();
    var birthDate = new Date(patientData.dateAnniversaire);
    this.age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      this.age--;
    }
    patientData.age = this.age;
    if (patientData.tel) {
      patientData.tel = patientData.tel.internationalNumber;
    }
    patientData.expiredAt = null
    if (this.form.get('patientsArray').valid) {
      this.consultationService.registerPatient(patientData)
        .subscribe((patient: any) => {
          if (patient && patient._id) {
            this.createdPatient = patient
            this.showMotif = true
            this.iziToast.show({
              message: 'Bien ajouté',
              messageColor: '#386641',
              progressBarColor: '#6a994e',
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#dde5b6',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: false,
            });

            this.patientService.getPatientsByDoctor(this.medecin._id)
              .subscribe((patients: any[]) => {
                this.patients = patients;
                this.patients = this.patients.map(patient => {
                  return {
                    ...patient,
                    dateAnniversaire: new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0], // Keep the date formatted as 'en-GB'
                    fullDetails: `${patient.nom} ${patient.prenom} (${new Date(patient.dateAnniversaire).toLocaleDateString('en-GB').split(",")[0]})` // Combine nom, prenom, and dateAnniversaire
                  };
                })
              },
                err => {
                  return false;
                });
            this.submitted = true
            this.addedPatient = true
            return true;
          }
          if ((!patient) || (patient && !patient._id)) {

            this.iziToast.show({
              message: 'Une erreur est survenue',
              messageColor: '#800f2f',
              titleColor: '#800f2f',
              progressBarColor: '#c9184a',
              position: 'topRight',
              timeout: 5000,
              backgroundColor: '#ff8fa3',
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              overlay: false,
            });
            this.form.get('patientsArray').reset()
            return true;
          }
        });
    }
  }

  initiliaseCalendar() {
    $('#calendar').fullCalendar('changeView', 'agendaWeek');
  }
  eventClick(calEvent) {
    if (this.medecin) {
      this.showAndHideDelete = false;
      this.showErrorDate = false;
      this.eventClicked = {
        "id": calEvent.id,
        "title": calEvent.title,
        "motif": calEvent.motif,
        "duration": calEvent.duration,
        "start": calEvent.start,
        "end": calEvent.end,
        "patient": calEvent.patient,
        "type": calEvent.type,
        "tags": calEvent.tags,
        "nextAppointmentDuration": calEvent.nextAppointment,
        "nextMotif": calEvent.nextMotif,
      }
      jQuery('#detailsEventModal').modal('show');
    }

  }
  dayClick(details) {
    if (this.medecin) {
      var todayData = new Date().toISOString().split('T')[0]
      this.start = details.date.format();
      this.showTitle = true
      if (todayData > this.start) {
        return false;
      }
      jQuery('#createEventModal').modal('show');
      this.resetForm()
    }
  }

  saveEvent() {
    if (!this.medecin) {
      return false;
    }
    this.showErrorExistingEvents = false
    this.eventService.getAllEventsByMedecinByDateBetweenTwoDates(this.medecin._id, this.start + 'T' + this.form.value.timeStart, this.start + 'T' + this.form.value.timeEnd).subscribe((data: any) => {
      if (data && data.existevents) {
        this.showErrorExistingEvents = true
        return false;
      } else {
        //this.poursuivrecreationEvents()
        return true;
      }
    },
      err => {
        return false;
      });

  }
  controlerTimeFermeture() {
    this.optionsTimeFermeture = []
    var numberDuration = Number(this.form.value.timing)
    for (var i = 1; i < this.hours.length; i++) {
      var date = moment(this.form.value.timeStart, 'HH:mm')
        .add(numberDuration * i, 'minutes')
        .format('HH:mm');
      if (this.hours.indexOf(date) >= this.hours.indexOf("23:45")) {
        return false;
      }
      if (this.optionsTimeFermeture.indexOf(date) === -1) {
        this.optionsTimeFermeture.push(date);
      }
    }
  }
  showAndHideTitle() {
    if (this.form.value.type == "Personnel") {
      this.showTitle = true;
      return true;
    }
    if (this.form.value.type == "Professionnel") {
      this.showTitle = false;
      return false;
    }
  }

  hideDelete() {
    this.showAndHideDelete = true;
  }
  geToUpdateConsultation(consultation) {
    window.open(`medecin/ajout/interrogatoire/${consultation.patient._id}/${consultation.id}`, '_blank')

  }
  createConsultation() {
    if (!this.medecin) {
      return false;
    }
    let consultationBody

    if (this.typePatient === false) {
      // nouveau patient 
      if (!this.createdPatient || (this.selectedMotifs && this.selectedMotifs.length === 0)) {
        this.iziToast.show({
          message: 'Tous les champs sont obligatoires',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      consultationBody = {
        patientId: this.createdPatient._id,
        createdByDoctor: this.medecin._id,
        motif: this.selectedMotifs,
        start: this.start
      };
      this.examenService
        .ajoutExamen(consultationBody)
        .subscribe((response: any) => {
          this.getExemens()
          // window.open(`medecin/ajout/interrogatoire/${this.createdPatient._id}/${response._id}`, '_blank')
        });
      this.submitted = false
      this.addedPatient = false
    } else {
      // ancien patient
      if (!this.form.value.patient || (this.selectedMotifs && this.selectedMotifs.length === 0)) {
        this.iziToast.show({
          message: 'Tous les champs sont obligatoires',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      let listpreviousconsultationsLength = this.listpreviousconsultations && (this.listpreviousconsultations.length > 0 && new Date(this.listpreviousconsultations[0].start.split('T')[0]).getTime() === new Date(this.start.split('T')[0]).getTime())

      switch (listpreviousconsultationsLength) {
        case true:
          // window.open(`medecin/ajout/interrogatoire/${this.form.value.patient._id}/${this.listpreviousconsultations[0].id}`, '_blank')
          break;
        case false:
          consultationBody = {
            patientId: this.form.value.patient._id,
            createdByDoctor: this.medecin._id,
            motif: this.selectedMotifs,
            start: this.start
          };
          this.examenService
            .ajoutExamen(consultationBody)
            .subscribe((response: any) => {
              this.getExemens()
              // window.open(`medecin/ajout/interrogatoire/${this.form.value.patient._id}/${response._id}`, '_blank')
            });
          break;

        default:
          break;
      }
    }

    // close modal after selecting motif
    jQuery('#createEventModal').modal('hide');
  }


  addInputMotif($event) {
    if (this.motifList.indexOf($event) < 0 && $event && $event.trim().length >= 3 && $event.trim().length < 100) {
      this.motifList.push($event)
      this.motifService
        .ajoutMotif({ nom: $event })
        .subscribe((response: any) => {
        });
    }
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.trim().length >= 3 && $event.trim().length < 100 && this.selectedMotifs.length < 20) {
      this.selectedMotifs.push($event);
      this.selectedMotif = ""
    }
    if (this.createdPatient) {
      this.form.value.patient = this.createdPatient
    }


    return true
  }

  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    return true;
  }

  togglePatientType(type: boolean): void {
    this.typePatient = type;
    this.form.reset()
    this.showMotif = false
    this.selectedMotifs = []
    this.submitted = false
    this.addedPatient = false
    // Clear the form and add the new patient form when "Nouveau Patient" is selected
    if (type == false) {
      const patientsArray = this.form.get('patientsArray') as FormArray;

      if (patientsArray.length === 0) {
        const newPatientGroup = this.createPatientGroup();
        patientsArray.push(newPatientGroup);

      }
    }
  }

  customSearchFn(term: string, item: any): boolean {
    term = term.toLowerCase();

    // Combine both nom and dateAnniversaire for search
    const fullName = `${item.nom} ${item.prenom}`.toLowerCase();
    const fullName2 = `${item.prenom} ${item.nom}`.toLowerCase()
    const dateAnniversaire = item.dateAnniversaire ? item.dateAnniversaire : '';

    // Check if the search term matches either the full name or dateAnniversaire
    return fullName.includes(term) || fullName2.includes(term) || dateAnniversaire.includes(term);
  }

  onPatientSelect(patient: any) {
    if (patient) {
      this.selectedPatient = patient; // Handle selected patient
      this.listpreviousconsultations = []
      while (this.selectedMotifs.length > 0) {
        this.selectedMotifs.pop();
      }
      this.examenService.getPreviousConsultation(this.selectedPatient._id).subscribe((data: any) => {
        if (data && data.needToUpdate && data.result && data.result.results && data.result.results.length > 0) {
          this.listpreviousconsultations = data.result.results
        }
        if (data && !data.needToUpdate && data.result && data.result.results && data.result.results.length > 0 && data.result.results[0].nextMotif) {
          this.selectedMotifs.push(...data.result.results[0].nextMotif)
        }
      },
        err => {
          return false;
        });
      this.showMotif = true
      this.addedPatient = true
    } else {
      this.selectedPatient = null
      this.showMotif = false
      this.addedPatient = false
    }
  }
  resetForm() {
    this.form.reset()
    this.selectedMotifs = []
    this.showMotif = false
    this.typePatient = null
    this.submitted = false
    this.addedPatient = false
    jQuery('#createEventModal').modal('hide');
  }

}