<div id="divToExport" *ngIf="myExamen && myExamen.foundImpression && myExamen.foundImpression.titrePrecrire.indexOf( 'Prescrire des examens complémentaires') > -1 && medcin">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet">
    <style>
        .bold-line {
            border-top: 3px solid;
        }

        .letter-spacing {
            letter-spacing: 3px;
        }

        ul {
            list-style-type: none;
        }

        .main-contenu {
            padding-left: 3.2rem !important;
            height: 800px;
        }

        .contenu {
            /* font-size: 1rem; */
            font-weight: 600;
        }

        #footer-cont {
            position: fixed;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;
            height: 150px;
        }

        .header {
            height: 200px;
        }

        .flex-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100vh;
        }

        .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
    </style>
    <div class="flex-wrapper">
        <!-- En-tête -->
        <app-header-template></app-header-template>
        <!-- </header> -->

        <!-- Content-->
        <div class=" container mt-5 ml-7 main-contenu" id="main-cont">
            <h2 class="text-center" [style.color]="medcin.colorTemplates"><strong>Examen complémentaire </strong></h2>
            <p class="contenu mt-3" *ngIf="myExamen && myExamen.patient">Madame/Monsieur
                {{myExamen.patient.nom}} {{myExamen.patient.prenom}}</p>
            <strong>
                <p class="mt-2"> Le docteur &nbsp;
                    <span *ngIf="myExamen.foundImpression.operateur">{{myExamen.foundImpression.operateur}}</span>
                    <span *ngIf="!myExamen.foundImpression.operateur">..................................................................................</span>
                    &nbsp;effectuera un examen complémentaire sur &nbsp;
                    <span *ngIf="myExamen.foundImpression.oeil">l'{{myExamen.foundImpression.oeil}}</span>
                    <span *ngIf="!myExamen.foundImpression.oeil">l'oeil..................................................</span>
                    &nbsp;dans la clinique&nbsp;
                    <span *ngIf="myExamen.foundImpression.clinique">{{myExamen.foundImpression.clinique}}</span>
                    <span *ngIf="!myExamen.foundImpression.clinique">........................................................................................</span>
                    &nbsp;le &nbsp;
                    <span *ngIf="myExamen.foundImpression.dateOperation">{{myExamen.foundImpression.dateOperation| date:'dd /MM /yyyy à HH:mm'}} heure</span>
                    <span *ngIf="!myExamen.foundImpression.dateOperation">............................................</span> .
                </p>
                <div *ngIf="myExamen.foundImpression.types.length>0">
                    <p style="margin-bottom:0" [style.color]="medcin.colorTemplates">Types d'examen : </p>
                    <ul *ngFor="let type of myExamen.foundImpression.types">
                        <li>{{type}}</li>
                    </ul>
                </div>
                <div *ngIf="myExamen.foundImpression.types.length==0">
                    <p style="margin-bottom:0" [style.color]="medcin.colorTemplates">Types d'examen :</p>
                    <ul>
                        <li>..............................................................................................................................................................................</li>
                        <li>..............................................................................................................................................................................</li>
                    </ul>
                </div>
                <div *ngIf="myExamen.foundImpression.forfait">
                    <p [style.color]="medcin.colorTemplates">Forfait : <span style="color: black;">{{myExamen.foundImpression.forfait}}</span></p>

                </div>
                <div *ngIf="!myExamen.foundImpression.forfait">
                    <p [style.color]="medcin.colorTemplates">Forfait : <span style="color: black;">..............................................................................................</span></p>

                </div>
                <div *ngIf="myExamen.foundImpression.precaution">
                    <p [style.color]="medcin.colorTemplates">Précaution : <span style="color: black;">{{myExamen.foundImpression.precaution}}</span></p>
                </div>

                <div *ngIf="!myExamen.foundImpression.precaution">
                    <p [style.color]="medcin.colorTemplates">Précaution : <span style="color: black;">....................................................................................................</span> </p>

                </div>
                <div *ngIf="myExamen.foundImpression.informationAdditionnel">
                    <p [style.color]="medcin.colorTemplates"> Information additionnel :<span style="color: black;">{{myExamen.foundImpression.informationAdditionnel}}</span> </p>
                </div>
                <div *ngIf="!myExamen.foundImpression.informationAdditionnel">
                    <p [style.color]="medcin.colorTemplates"> Information additionnel :<span style="color: black;">............................................................................................................</span> </p>
                </div>
            </strong>

        </div>
        <!-- End Content-->

        <!-- Pied de page  -->
        <app-footer-template></app-footer-template>
        <!-- </footer> -->

        <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.5.4/dist/umd/popper.min.js"></script>
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>

    </div>
</div>