import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
import { Observable } from 'rxjs';

@Injectable()
export class MotifService {


    constructor(private http: HttpClient) { }

    ajoutMotif(motif) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        motif.specialite = "ophtalmologiste";
        return this.http.post(_getURL(API_CONFIG.motif), motif, { headers: headers })
    }
    getMotifByDoctor() {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(_getURL(API_CONFIG.motif) + "/get-motifs-by-doctor/ophtalmologiste", { headers: headers });
    }
    deleteMotif(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete<any>(_getURL(API_CONFIG.motif) + "/delete-motif/" + id, { headers: headers });
    }
}