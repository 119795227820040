<div class="container header py-3" id="header-cont" *ngIf="medcin">
    <div class="row">
        <div class="col-lg-4">
            <div class="row">
                <div>
                    <ul>
                        <li><strong>Dr.{{ medcin?.prenom }} {{ medcin?.nom }}</strong></li>
                        <hr class=" my-1 bold-line" [style.color]="medcin?.colorTemplates">
                        <li><strong class="letter-spacing">{{
                                medcin?.specialite
                                }}</strong></li>
                        <li *ngIf="unSpec">Chirurgie de la cataracte</li>
                        <li *ngIf="unSpec">Traitement aux Lasers,LASIK</li>
                        <li *ngIf="unSpec">Prise en charge du kératocône</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-lg-4 text-center logo-container">
            <img src="{{pathImageLogo}}" *ngIf="pathImageLogo" style="max-width: 140px; max-height: 80px;" alt="Logo" />
            <p class="pt-3">
                <strong *ngIf="medcin?.codeCNAM"> Code CNAM:{{ medcin?.codeCNAM }}</strong>
                <strong *ngIf="!medcin?.codeCNAM"> Code CNAM : .......................................</strong>
            </p>
        </div>
        <div class="col-lg-4">
            <div class="row">
                <div class="col-12 text-right">
                    <ul>
                        <li><strong>الدكتور {{ medcin?.nomAr }} {{ medcin?.prenomAr }}</strong></li>
                        <hr class="my-1 bold-line" [style.color]="medcin?.colorTemplates">
                        <li><strong>{{ medcin?.specialiteAr }}</strong></li>
                        <li *ngIf="unSpec">جراحة العدسة</li>
                        <li *ngIf="unSpec">تصحيح النظر بالليزر</li>
                        <li *ngIf="unSpec">علاج القرنية المخروطية</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>