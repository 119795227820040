import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamenService } from 'src/app/services/examen/examen.service';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-impression-cat',
  templateUrl: './impression-cat.component.html',
  styleUrls: ['./impression-cat.component.css']
})
export class ImpressionCatComponent implements OnInit {
  myExamen: any;
  today = new Date().toISOString().slice(0, 10)
  constructor(private examenService: ExamenService, private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.today = new Date().toISOString().slice(0, 10)
    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    const idcat = this.route.snapshot.paramMap.get('idCat');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForCatImpression(idExamen, idcat).subscribe((data: any) => {
        this.myExamen = data
        if (!this.myExamen || (this.myExamen && !this.myExamen.foundImpression) || (this.myExamen && this.myExamen.foundImpression && !this.myExamen.foundImpression._id)) {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        }
        if (this.myExamen.foundImpression && !this.myExamen.foundImpression.titrePrecrire) {
          this.myExamen.foundImpression.titrePrecrire = "ordonnance"
        }
      },
        err => {
          this.router.navigate(['/medecin/patients-liste']);
          return false;
        });
    }
  }


  imprimer() {
    const headerElement = document.getElementById('header-cont');
    const mainElement = document.getElementById('main-cont');
    const footerElement = document.getElementById('footer-cont');

    const extractInputValues = (element) => {
      const inputs = element.querySelectorAll('input, textarea, select');
      inputs.forEach(input => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          if (input.checked) {
            input.setAttribute('checked', 'checked');
          } else {
            input.removeAttribute('checked');
          }
        } else {
          input.setAttribute('value', input.value);
        }
      });
    };

    const waitForImagesToLoad = (element: HTMLElement): Promise<void[]> => {
      const images = element.querySelectorAll('img');
      const promises = Array.from(images).map(img => {
        const imageElement = img as HTMLImageElement; // Assurer que TypeScript comprend que c'est une image
        if (imageElement.complete) {
          return Promise.resolve();
        }
        return new Promise<void>(resolve => {
          imageElement.onload = () => resolve();
          imageElement.onerror = () => resolve(); // Résoudre même en cas d'échec de chargement
        });
      });
      return Promise.all(promises);
    };

    const convertToAbsoluteURLs = (element) => {
      const images = element.querySelectorAll('img');
      images.forEach(img => {
        if (!img.src.startsWith('http') && img.src) {
          const link = document.createElement('a');
          link.href = img.src;
          img.src = link.href;
        }
      });
    };

    extractInputValues(mainElement);
    convertToAbsoluteURLs(headerElement);
    waitForImagesToLoad(headerElement);

    const headerContent = headerElement.innerHTML;
    const mainContent = mainElement.innerHTML;
    const footerContent = footerElement.innerHTML;

    const styledHeaderContent = `<div style="margin-top:10px;margin-right: 30px; height: 100px;font-size: 10px">${headerContent}</div>`;
    const styledFooterContent = `<div style="height: 80px;font-size: 11px">${footerContent}</div>`;
    const combinedContent = `${styledHeaderContent}<div style="height: 600px; padding-left: 2rem;padding-right: 2rem;font-size: 12px!important">${mainContent}</div>${styledFooterContent}`;

    let nameFileSaved = "your_file_name.pdf";
    if (this.myExamen?.foundImpression?.titrePrecrire && this.myExamen.patient?.nom && this.myExamen.patient?.prenom) {
      nameFileSaved =
        `${this.myExamen.foundImpression.titrePrecrire.replace(/\s/g, "-")}-${this.myExamen.patient.nom.replace(/\s/g, "-")}-${this.myExamen.patient.prenom.replace(/\s/g, "-")}-${new Date().toISOString().slice(0, 10)}.pdf`;
    }

    const options = {
      filename: nameFileSaved,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true, scale: 4, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a5', orientation: 'portrait' },
    };

    html2pdf().from(combinedContent).set(options).save();
  }

}
