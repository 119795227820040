import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { data } from 'jquery';
import { EventService } from 'src/app/services/event/event.service';
import { RdvService } from 'src/app/services/rdv/rdv.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-update-rdv',
  templateUrl: './update-rdv.component.html',
  styleUrls: ['./update-rdv.component.css', '../rdvs.component.css']
})
export class UpdateRdvComponent implements OnInit {
  @Output() rdvUpdated = new EventEmitter<void>();
  @Input() rdvId: string;

  medecinId: string | null;
  idPatient: string = '';
  numFiche: string = '';
  selectedMotif: string[] = [];

  currentYear: number;
  currentMonth: number;
  currentWeekIndex: number = 0;

  selectedDay: any;
  selectedTimeSlot: any;
  selectedTimes: { [date: string]: string } = {};

  listMotifs: any;
  calendarDays: any[] = [];
  timeSlots: any[] = [];
  monthNames = [
    "Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"
  ];

  allFreeEvents: any[] = [];
  events: any[] = [];

  rdv: any;

  currentlySelectedDay: any;

  constructor(
    public modal: NgbActiveModal,
    private rdvService: RdvService,
    private toastService: ToastService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.currentMonth = today.getMonth();

    this.getMedecinId();
    this.fetchAllFreeEvents().then(() => {
      this.loadRdvDetails();
      this.fetchRdvAndUpdateTimeSlots(this.rdvId);
      this.initializeMotifs();
    });
  }

  getMedecinId() {
    if (localStorage.getItem("medecin")) {
      var medecinString = localStorage.getItem('medecin');
      var medecinId = JSON.parse(medecinString).id;
      this.medecinId = medecinId
    } else {
      return
    }
  }

  fetchAllFreeEvents() {
    return this.eventService.getAllEventsLibresByMedecin(this.medecinId).toPromise().then(
      (data: any[]) => {
        this.allFreeEvents = data;
        this.initializeCalendarDays();
      },
      error => {
        console.error('Error fetching free events', error);
      }
    );
  }

  loadRdvDetails() {
    this.rdvService.getRdvById(this.rdvId).subscribe(
      (rdv) => {
        this.idPatient = rdv.idPatient.replace('Mediv-', '');
        this.numFiche = rdv.patientId.numFiche;
        this.selectedMotif = rdv.motif;

        const date = new Date(rdv.start);
        const { date: rdvDate, timeSlot } = this.splitDateTime(date.toISOString());

        this.selectedTimes[rdvDate] = timeSlot;

        // Navigate to the correct week and select the day
        this.navigateToDate(date);

        // Fetch events and update time slots
        this.fetchRdvAndUpdateTimeSlots(this.rdvId);

        // Select the specific time slot
        this.selectTimeSlotByTime(timeSlot);
      },
      (error) => {
        console.error('Error fetching RDV details:', error);
        this.toastService.showError("Erreur lors de la récupération des détails du rendez-vous");
      }
    );
  }


  fetchRdvAndUpdateTimeSlots(rdvId: string) {
    this.rdvService.getRdvById(rdvId).subscribe({
      next: (rdv) => {
        this.rdv = rdv;
        const [date, time] = rdv.start.split('T');
        this.rdv.date = date;
        this.rdv.startTime = time ? time.split(':').slice(0, 2).join(':') : '';

        // Update time slots for the selected day
        this.updateTimeSlotsForSelectedDay(this.selectedDay);

        // Ensure that the time slot matching rdv.startTime is selected
        this.selectTimeSlotByTime(this.rdv.startTime);
      },
      error: (err) => console.error('Failed to fetch RDV', err)
    });
  }

  selectTimeSlotByTime(time: string) {
    // Find the slot that matches the provided time
    const slot = this.timeSlots.find(s => {
      const { timeSlot } = this.splitDateTime(s.dateTime.toISOString());
      return timeSlot === time;
    });

    if (slot) {
      this.selectTimeSlot(slot);
    }
  }

  navigateToDate(date: Date) {
    const today = new Date();
    const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
    const weekDifference = Math.floor((date.getTime() - startOfWeek.getTime()) / (7 * 24 * 60 * 60 * 1000));

    this.currentWeekIndex = weekDifference;
    this.updateCalendarDays();

    // Select the specific day after updating the calendar
    const dayToSelect = this.calendarDays.find(day => day.date.toDateString() === date.toDateString());

    if (dayToSelect) {
      this.selectDay(dayToSelect);
    }
  }

  updateRdv() {
    if (!this.idPatient || !this.numFiche || !this.selectedDay || !this.selectedTimeSlot || !this.selectedMotif.length) {
      this.toastService.showError("Merci de remplir tous les champs");
      return;
    }

    const fullIdPatient = `Mediv-${this.idPatient}`;
    const date = this.selectedDay.date;
    const time = this.selectedTimeSlot.time;

    const start = `${date.getFullYear()}-${this.padZero(date.getMonth() + 1)}-${this.padZero(date.getDate())}T${time}:00`;

    const rdvData = {
      idPatient: fullIdPatient,
      numFiche: this.numFiche,
      start,
      motif: this.selectedMotif.join(', '),
      medecinId: this.medecinId
    };

    // Ensure this.rdvId is set and valid
    this.rdvService.updateRdv(this.rdvId, rdvData).subscribe(
      (response) => {
        this.toastService.showSuccess("Rendez-vous modifié avec succès");
        this.rdvUpdated.emit();
        this.modal.close();
      },
      (error) => {
        console.error('Error updating RDV:', error);
        this.toastService.showError("Erreur lors de la modification du rendez-vous");
      }
    );
  }

  initializeMotifs() {
    this.listMotifs = [
      "Visite médicale régulière pour un bilan de santé général.",
      "Consultation pour des symptômes tels que fièvre, toux, maux de gorge et fatigue.",
      "Évaluation des douleurs ou inconforts abdominaux persistants.",
      "Surveillance et gestion de l'hypertension (pression artérielle élevée).",
      "Consultation pour des difficultés respiratoires, comme l'asthme ou les allergies."
    ];
  }

  initializeCalendarDays() {
    const today = new Date();
    const startOfWeek = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - today.getUTCDay() + 1));
    const days = [];

    const startDate = new Date(startOfWeek.toISOString().substring(0, 10));

    for (let i = 0; i < 7; i++) {
      const date = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() + i));
      const dayName = date.toLocaleString('fr-FR', { weekday: 'short' }).replace('.', '');
      const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
      const dayNumber = date.getUTCDate();
      const monthIndex = date.getUTCMonth();
      const monthName = this.monthNames[monthIndex];

      if (!monthName) {
        console.error(`Month name not found for month index: ${monthIndex}`);
      }

      days.push({
        date,
        dayName: capitalizedDayName,
        dayNumber,
        monthName,
        active: false,
        isAvailable: this.allFreeEvents.some(event => event.start.substring(0, 10) === date.toISOString().substring(0, 10)),
        isPast: date < new Date(new Date().setUTCHours(0, 0, 0, 0)) // Disable if it's a past date
      });
    }

    this.calendarDays = days;
  }

  updateCalendarDays() {
    const today = new Date();
    const startOfWeek = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - today.getUTCDay() + 1 + this.currentWeekIndex * 7));
    const startDate = new Date(startOfWeek.toISOString().substring(0, 10));

    const days = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() + i));
      const { date: dateString } = this.splitDateTime(date.toISOString());
      const dayName = date.toLocaleString('fr-FR', { weekday: 'short' }).replace('.', '');
      const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
      const dayNumber = date.getUTCDate();
      const monthName = this.monthNames[date.getUTCMonth()]; // Ensure monthNames is properly defined

      const isAvailable = this.allFreeEvents.some(event => {
        const { date: eventDate } = this.splitDateTime(event.start);
        return eventDate === dateString;
      });
      const isPast = date < new Date(new Date().setUTCHours(0, 0, 0, 0)); // Disable if it's a past date

      days.push({ date, dayName: capitalizedDayName, dayNumber, monthName, active: false, isAvailable, isPast });
    }

    this.calendarDays = days;
  }


  nextWeek() {
    const currentStartDate = new Date(this.calendarDays[0].date);
    const nextWeekStartDate = new Date(currentStartDate);
    nextWeekStartDate.setUTCDate(nextWeekStartDate.getUTCDate() + 7);

    if (nextWeekStartDate.getUTCFullYear() !== this.currentYear) {
      this.currentYear = nextWeekStartDate.getUTCFullYear();
    }

    this.currentWeekIndex++;
    this.updateCalendarDays();

    // Clear selected time slot when navigating to a new week
    this.selectedTimeSlot = null;
    this.selectedTimes = {};

    // Ensure the selected day is retained if it exists in the new week
    const dayToSelect = this.calendarDays.find(day => day.date.toDateString() === this.currentlySelectedDay?.date.toDateString());
    if (dayToSelect) {
      this.selectDay(dayToSelect);
    }
  }

  previousWeek() {
    if (this.currentWeekIndex > 0) {
      const currentStartDate = new Date(this.calendarDays[0].date);
      const previousWeekStartDate = new Date(currentStartDate);
      previousWeekStartDate.setUTCDate(previousWeekStartDate.getUTCDate() - 7);

      if (previousWeekStartDate.getUTCFullYear() !== this.currentYear) {
        this.currentYear = previousWeekStartDate.getUTCFullYear();
      }

      this.currentWeekIndex--;
      this.updateCalendarDays();

      // Clear selected time slot when navigating to a new week
      this.selectedTimeSlot = null;
      this.selectedTimes = {};

      // Ensure the selected day is retained if it exists in the new week
      const dayToSelect = this.calendarDays.find(day => day.date.toDateString() === this.currentlySelectedDay?.date.toDateString());
      if (dayToSelect) {
        this.selectDay(dayToSelect);
      }
    }
  }

  selectDay(day: any) {
    if (!day.isAvailable || day.isPast) {
      return;
    }

    // Clear previous selections and set the new selected day
    this.calendarDays.forEach(d => d.active = false);
    day.active = true;
    this.currentlySelectedDay = { ...day, yearNumber: day.date.getUTCFullYear() };

    // Update time slots for the selected day
    this.updateTimeSlotsForSelectedDay(day);

    // Clear previously selected time slot
    this.selectedTimeSlot = null;
    this.timeSlots.forEach(slot => slot.active = false);
  }


  updateTimeSlotsForSelectedDay(selectedDay) {
    if (!selectedDay.isAvailable || selectedDay.isPast) {
      this.timeSlots = []; // Clear time slots if the day is not available or is past
      return;
    }

    this.selectedDay = { ...selectedDay, yearNumber: selectedDay.date.getUTCFullYear() };

    // Filter events for the selected day
    const filteredEvents = this.allFreeEvents.filter(event => {
      const { date } = this.splitDateTime(event.start);
      return date === this.selectedDay.date.toISOString().split('T')[0];
    });

    // Update time slots based on the filtered events
    this.timeSlots = filteredEvents.map(event => {
      const { timeSlot } = this.splitDateTime(event.start);
      return { time: timeSlot, active: false, dateTime: new Date(`${event.start}Z`), eventId: event._id };
    });

    // Sort time slots by dateTime
    this.timeSlots.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());

    // Set the selected time slot if it exists
    if (this.rdv) {
      const initialTimeSlot = this.rdv.startTime;
      this.selectTimeSlotByTime(initialTimeSlot);
    }
  }

  selectTimeSlot(selectedSlot) {
    if (!selectedSlot) return;

    // Clear all previous selections
    this.clearAllTimeSlotSelections();

    selectedSlot.active = true;
    this.selectedTimeSlot = { ...selectedSlot };

    // Store the selected time for the current day
    if (this.selectedDay) {
      const dateKey = this.selectedDay.date.toDateString();
      this.selectedTimes[dateKey] = selectedSlot.time;
    }
  }



  // Add this new method to clear all time slot selections
  clearAllTimeSlotSelections() {
    this.timeSlots.forEach(slot => slot.active = false);
    this.selectedTimeSlot = null;
    // Clear only the time slots of the currently selected day
    if (this.selectedDay) {
      const dateKey = this.selectedDay.date.toDateString();
      this.selectedTimes[dateKey] = '';
    }
  }


  splitDateTime(dateTime: string) {
    const [date, timeSlot] = dateTime.split('T');
    return { date, timeSlot: timeSlot.substring(0, 5) };
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  onReset() {
    this.modal.dismiss();
  }

}
